import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router, @Inject(CONFIG_TOKEN) private config: SharedModuleConfig) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => isAuthenticated ? true : this.router.createUrlTree(this.config.login.commands, this.config.login.navigationExtras))
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => isAuthenticated ? true : this.router.createUrlTree(this.config.login.commands, this.config.login.navigationExtras))
    );
  }

}
