import { InjectionToken } from "@angular/core";
import { NavigationExtras } from "@angular/router";

export interface StripConfig {
    /**
     * Stripe Key to Strapi API.
     */
    publicKey: string;
    version: string;
}

export interface SharedModuleConfig {
    /**
     * URL to Strapi API.
     */
    apiUrl: string;

    /**
     * URL to Goolge Map API.
     */
     googleMapsApiKey: string;
     
    /**
     * Stripe API config.
     */
    stripe: StripConfig;

    /**
     * Local storage key for API JWT.
     */
    jwtLocalStorageKey: string;

    /**
     * Login route settings, used in the route guard to redirect to login form.
     */
    login: {
        commands: any[],
        navigationExtras?: NavigationExtras;
    }
}
export const CONFIG_TOKEN = new InjectionToken<SharedModuleConfig>('SharedConfig');

// Placeholder to inject path
export interface SharedPathConfig {
    path: string
}
export const CONFIG_PATH = new InjectionToken<SharedPathConfig>('SharedPathConfig');