import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-user-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public userIcon = faUser;
  public username$: Observable<string>;

  constructor(private authService: AuthService) {
    this.username$ = authService.user$.pipe(
      map(user => user.username)
    );
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }
}
