import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Category, CategoryDetails } from '../models/category';
import { CategoryQuery } from '../queries/category-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends StrapiQueryableService<Category, CategoryDetails, CategoryQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'categories' }, httpClient, config);
  }

  protected buildQuery(query?: CategoryQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.name) {
        queryObj._where.push({ name: query.name });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
      if (query.mobileActive != undefined) {
        queryObj._where.push({ mobileActive: query.mobileActive });
      }
      if(query.name_ne) {
        queryObj._where.push({name_ne: query.name_ne});
      }
      if(query.name_nin) {
        queryObj._where.push({name_nin: query.name_nin})
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: CategoryQuery): PagedResult<CategoryDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<CategoryDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: CategoryQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

}
