import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

import { User, UserDetails } from '../models/user';
import { UserQuery } from '../queries/user-query';
import { PagedResult } from '../queries/paged-result';
import { StrapiQueryableService } from './strapi-queryable.service';
import { Role } from '../models/role';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class UserService extends StrapiQueryableService<User, UserDetails, UserQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'users' }, httpClient, config);
  }

  protected buildQuery(query?: UserQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            firstName_contains: query.search
          }, {
            lastName_contains: query.search
          }, {
            email_contains: query.search
          }]
        });
      }

      if (query.roles && query.roles.length > 0) {
        queryObj._where.push({
          _or: query.roles.map(role => {
            return { "role.name": role }
          })
        });
      }

      if(query.email) {
        queryObj._where.push({
          email: query.email
        });
      }

      if(query.id_in) {
        queryObj._where.push({
          id_in: query.id_in
        })
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: UserQuery): PagedResult<UserDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<UserDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: UserQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public generateAndSendTempPasswordEmail(userId: number): Observable<void> {
    return this.httpClient.put<void>(`${this.config.apiUrl}/${this.pathConfig.path}/${userId}/generate-random-password`, {})
      .pipe(
        shareReplay(1)
      );
  }

  public deleteAccount(accountDeletionReason?: string): Observable<void> {
    return this.httpClient.put<void>(`${this.config.apiUrl}/${this.pathConfig.path}/delete-account`, {accountDeletionReason});
  }
}
