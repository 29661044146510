import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { GiftCertificateService } from 'projects/shared/src/lib/services/gift-certificate.service';

@Component({
  selector: 'gcl-user-add-gift-certificate-modal',
  templateUrl: './add-gift-certificate-modal.component.html',
  styleUrls: ['./add-gift-certificate-modal.component.scss']
})
export class AddGiftCertificateModalComponent implements OnInit {

  @Output()
  submit: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  cancel: EventEmitter<void> = new EventEmitter<void>();

  get cardnumber() { return this.giftCardForm.get('cardnumber') as FormControl; }

  giftCardForm = this.fb.group({
    cardnumber: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder, private toastrService: ToastrService) { }

  ngOnInit(): void {
  }

  public onSubmit(): void {
    if (this.giftCardForm.valid) {
      this.submit.emit(this.cardnumber.value);
    } else {
      this.toastrService.error(`Gift Card number is requried.`);
    }
  }

  public onCancel(): void {
    this.giftCardForm.reset();
    this.cancel.emit();
  }
}
