import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, forwardRef, Output, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gcl-lib-text-toggle-switch',
  templateUrl: './text-toggle-switch.component.html',
  styleUrls: ['./text-toggle-switch.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextToggleSwitchComponent),
    multi: true
  }],
  animations: [
    trigger('openClose', [
      state('open', style({
        right: '4px'
      })),
      state('closed', style({
        left: '4px'
      })),
      transition('open => closed', [
        animate('0.4s ease-in', 
        style({transform: 'translateX(-100%)'}))
      ]),
      transition('closed => open', [
        animate('0.4s ease-in', 
        style({transform: 'translateX(100%)'}))
      ]),
    ]),
  ],
})
export class TextToggleSwitchComponent implements ControlValueAccessor {

  private propagateChange = (_: any) => { };

  @Input()
  leftText: string = "";
  @Input()
  rightText: string = "";

  @Output()
  toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  public _value: boolean = true;

  constructor() { }

  public clicked() {
    this._value = !this._value;

    // Progates the new value to Reactive Angular Form.
    this.propagateChange(this._value);
    this.toggle.emit(this._value);
  }

  // Sets the value of this input initially.
  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // Don't care about these.
  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void { }
}
