import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gcl-lib-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CounterComponent),
    multi: true
  }],
})
export class CounterComponent implements OnInit, ControlValueAccessor {
  public faPlus = faPlus;
  public faMinus = faMinus;

  public _value: number = 0;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  @Input() 
  min?: number | null = null;
  @Input() 
  max?: number | null = null;
  @Input() 
  bordered: boolean = true;
  @Input() 
  value?: number | null = null;

  @Input() decrementCallback?: Function | null = null;
  @Input() inputCallback?: Function | null = null;
  @Input() incrementCallback?: Function | null = null;

  @Output()
  changed: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    if (this.value) {
      this._value = this.value;
    }
  }

  public decrement(): void {
    if (this.decrementCallback != null) {
      this.decrementCallback();
    } else {
      if (this.min == null || (this._value > this.min)) {
        this._value -= 1;
        this.propagateChange(this._value);
        this.changed.emit(this._value);
      }
    }
  }

  public change(event: Event): void {
    if (this.inputCallback != null) {
      this.inputCallback();
    } else {
      const value = event.target as HTMLInputElement;
      this._value = parseInt(value.value);
  
      const minValid = (this.min == null || (this._value > this.min));
      const maxValid = (!this.max || (this._value < this.max));
      if (!minValid) {
        this._value = this.min || 0;
      }
      if (!maxValid) {
        this._value = this.max || 0;
      }
  
      this.propagateChange(this._value);
      this.changed.emit(this._value);
    }
  }

  public increment(): void {
    if (this.incrementCallback != null) {
      this.incrementCallback();
    } else {
      if (!this.max || (this._value < this.max)) {
        this._value += 1;
        this.propagateChange(this._value);
        this.changed.emit(this._value);
      }
    }
  }

  writeValue(val: number): void {
    this._value = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
