import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { Components } from './components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginModalComponent } from './components/login-modal/login-modal.component';

@NgModule({
  declarations: [Components, LoginModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    FontAwesomeModule
  ],
  exports: [Components]
})
export class UserSharedModule { }
