<div class="container-fluid">
    <div class="row vh-100 justify-content-center align-items-center">
        <img src="/assets/logo.svg" class="img-fluid float-left mw-50 h-25 my-4">
        <div class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <!-- <h1 class="text-center my-4 "><img src="/assets/logo.svg" class="img-fluid .mw-auto .h-100"></h1> -->
            <div class="border rounded bg-light p-3 mb-3">
                <gcl-lib-login (onLogin)="goHome()"></gcl-lib-login>
            </div>
            <div class="d-flex justify-content-between">
                <a [routerLink]="['/forgot-password']">Forgot your password?</a>
                <a [routerLink]="['/registeration']">Create your account</a>
            </div>
        </div>
    </div>
</div>