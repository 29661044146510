<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h3 class="text-center text-primary pb-5">Select a Course</h3>
            <gcl-lib-pagination-content
                [totalItems]="total$ | async"
                [page]="pagination$.value.page"
                [itemsPerPage]="pagination$.value.itemsPerPage"
                [sortBy]="pagination$.value.sortBy"
                [position]="'center'"
                (onPage)="getPagedResult()">
                <ng-container  *ngFor="let course of records$| async">
                    <div class="row d-flex justify-content-between align-items-center">
                            <img class="img-fluid img-thumbnail img-sm col-12 col-lg-2 text-center text-lg-left py-4 py-lg-0" [src]="apiUrl + course?.logo?.url" />
                        <div class="col-12 col-lg-10">
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <h5>{{course.full_name}}</h5>
                                    <p class="font-weight-light">{{course.address}}</p>
                                    <p>{{course.description}}</p>
                                </div> 
                                <div class="d-lg-block d-none ml-auto">
                                    <button class="btn btn-primary rounded px-4 rounded-pill" type="button" (click)="onSelectCourse(course)">Select Course</button>
                                </div>
                                <div class="d-lg-none d-block mx-auto">
                                    <button class="btn btn-primary rounded px-4 rounded-pill" type="button" (click)="onSelectCourse(course)">Select Course</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </ng-container>
            </gcl-lib-pagination-content>
        </div>
    </div>
</div>