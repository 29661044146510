import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gcl-user-row-selection-option',
  templateUrl: './row-selection-option.component.html',
  styleUrls: ['./row-selection-option.component.scss']
})
export class RowSelectionOptionComponent implements OnInit {
  public faChevronRight = faChevronRight;

  @Input()
  label!: string;
  @Input()
  image?: string;
  @Input()
  faIcon?: IconDefinition | [IconPrefix, IconName];
  @Input()
  isClicked: boolean = false;

  @Output()
  click: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public clicked(): void {
    this.isClicked = true;
    this.click.emit();
  }

}
