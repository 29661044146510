declare global {
    interface Date {
        isLeapYear(year: number): boolean;
        addYears(years: number): Date;
        addMonths(months: number): Date;
        addDays(days: number): Date;
        addHours(hours: number): Date;
        addMinutes(minutes: number): Date;
        addSeconds(seconds: number): Date;
        compareDate(date: Date): number;
        addBusinessDays(days: number): Date;
        isBusinessDay(): boolean;
        getDaysInMonth(): number;
        toISODate(): string;
        toISOTime(): string;
        getTimeInMs(): number;

        getTimeZone(): string;
        getTimeString(includeMeridiem?: boolean): string;
        compareTime(timeOne: string, timeTwo: string): number;
    }
}

Date.prototype.isLeapYear = function (year: number): boolean {
    return new Date(year, 1, 29).getDate() === 29;
}

Date.prototype.addYears = function (years: number): Date {
    if (!years) { return this; }

    let date = new Date(this.getTime());

    date.setFullYear(date.getFullYear() + years)

    return date;
}

Date.prototype.addMonths = function (months: number): Date {
    if (!months) { return this; }

    let date = new Date(this.getTime());

    date.setMonth(date.getMonth() + months)

    return date;
}

Date.prototype.addDays = function (days: number): Date {
    if (!days) { return this; }

    let date = new Date(this.getTime());

    date.setDate(date.getDate() + days)

    return date;
}

Date.prototype.addHours = function (hours: number): Date {
    if (!hours) { return this; }

    let date = this;
    return new Date(date.getTime() + (hours * 1000 * 60 * 60));
}

Date.prototype.addMinutes = function (minutes: number): Date {
    if (!minutes) { return this; }

    let date = this;
    return new Date(date.getTime() + (minutes * 1000 * 60));
}

Date.prototype.addSeconds = function (seconds: number): Date {
    if (!seconds) { return this; }

    let date = this;
    return new Date(date.getTime() + (seconds * 1000));
}

Date.prototype.compareDate = function (date2: Date): number {
    if (!date2) { return -1; }

    // Check if the dates are equal
    let same = this.getTime() === date2.getTime();
    if (same) {
        return 0;
    } else if (this > date2) {
        // Check if the first is greater than second
        return 1;
    } else {
        // Check if the first is less than second
        return -1;
    }
}

Date.prototype.addBusinessDays = function (days: number): Date {
    if (!days) return this;

    let daysToAdd = (days > 0 ? 1 : -1);
    let date = this;
    while (days != 0) {
        date = date.addDays(daysToAdd);
        if (date.isBusinessDay()) {
            days = days - daysToAdd;
        }
    }
    return date;
}

Date.prototype.getDaysInMonth = function (): number {
    return new Date(this.getUTCFullYear(), this.getUTCMonth() + 1, 0).getDate();
}

Date.prototype.isBusinessDay = function (): boolean {
    return (this.getDay() == 6 || this.getDay() == 0) ? false : true;
}

Date.prototype.getTimeZone = function (): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

Date.prototype.getTimeString = function (includeMeridiem: boolean = true): string {
    let hours = this.getHours();
    let minutes = this.getMinutes();
    let ampm = includeMeridiem ? hours > 12 ? "PM" : "AM" : "";

    let hoursStr = ConvertNumberToTwoDigitString(hours);
    let minutesStr = ConvertNumberToTwoDigitString(minutes);
    return (hoursStr + ":" + minutesStr + " " + ampm).trim();
}

Date.prototype.getTimeInMs = function (): number {
    return this.getTime() - new Date(this.toDateString()).getTime();
}

Date.prototype.toISODate = function (): string {
    const date = new Date(this);
    date.setHours(12, 0, 0);  // sets the date to 12PM UTC, JS converts dates to localtime.
    return date.toISOString().substring(0, 10);
}

Date.prototype.toISOTime = function (): string {
    const isoDate = this.toISOString();
    return isoDate.substring(10, isoDate.length);
}

function ConvertNumberToTwoDigitString(num: number) {
    return (num > 9) ? ("" + num) : ("0" + num);
}

export { };
