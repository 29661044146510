import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { environment } from '../../environments/environment';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from 'projects/shared/src/public-api';
import { TeeTimeRoutingModule } from './tee-time-routing.module';
import { UserSharedModule } from '../shared/user-shared.module';
import { LayoutComponent } from './layout/layout.component';
import { AddTeeTimeComponent } from './components/add-tee-time/add-tee-time.component';
import { SelectTeeTimeComponent } from './components/select-tee-time/select-tee-time.component';
import { SelectByCourseComponent } from './components/select-by-course/select-by-course.component';
import { SelectByTimeComponent } from './components/select-by-time/select-by-time.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { AddProductComponent } from './components/add-product/add-product.component';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { TeeTimeBreadcrumbComponent } from './tee-time-breadcrumb/tee-time-breadcrumb.component';
import { CourseDetailsComponent } from './components/course-details/course-details.component';

@NgModule({
  declarations: [
    AddTeeTimeComponent, 
    LayoutComponent, 
    SelectTeeTimeComponent, 
    SelectByCourseComponent, 
    SelectByTimeComponent, 
    CheckoutComponent, 
    AddProductComponent, 
    AddToCartComponent, 
    OrderConfirmationComponent, 
    TeeTimeBreadcrumbComponent, 
    CourseDetailsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    ToastrModule.forRoot(),
    FontAwesomeModule,
    PaginationModule.forRoot(),
    NgxStripeModule.forRoot(),
    ModalModule.forRoot(),
    
    UserSharedModule,
    SharedModule,
    
    TeeTimeRoutingModule
  ]
})
export class TeeTimeModule { }
