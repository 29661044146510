import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';
import { BannerQuery } from '../queries/banner-query';
import { Banner, BannerDetails } from '../models/banner';
import { PagedResult } from '../queries/paged-result';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class BannerService extends StrapiQueryableService<Banner, BannerDetails, BannerQuery>{

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'banners' }, httpClient, config);
  }

  protected buildQuery(query?: BannerQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.name) {
        queryObj._where.push({ name: query.name });
      }
      if (query.sequence) {
        queryObj._where.push({ sequence: query.sequence });
      }
      if (query.courseId) {
        queryObj._where.push({ courseId: query.courseId });
      }
      
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: BannerQuery): PagedResult<BannerDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<BannerDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public queryRecords(query?: BannerQuery): Observable<BannerDetails[]> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<BannerDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`);
  }

  public count(query?: BannerQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public createWithImage(record: Partial<Banner>, image: File) {
    const formData = new FormData();
    formData.append('data', JSON.stringify(record));
    formData.append('files.image', image, image.name );

    return this.httpClient.post<BannerDetails>(`${this.config.apiUrl}/${this.pathConfig.path}`, formData);
  }

  public updateWithImage(id: number, record: Partial<Banner>, image: File) {
    const formData = new FormData();
    
    formData.append('data', JSON.stringify(record));

    if (image instanceof File)
    {
      formData.append('files.image', image, image.name );
    }

    return this.httpClient.put<BannerDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/${id}`, formData);
  }

}
