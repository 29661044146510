<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{creatingAccount ? 'Create Account' : 'Login'}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
            <span class="modal-close" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="creatingAccount">You must be logged in to book a round. Please create an account below or click <a class="create-account" (click)="showLogin()">here</a> to login.</p>
        <p *ngIf="!creatingAccount">You must be logged in to book a round. Please login below or click <a class="create-account" (click)="createAccount()">here</a> to create an account.</p>
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input class="form-control" type="text" formControlName="username" name="username" id="username" [class.is-invalid]="username.touched && username.invalid"  />
                    <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                        <div *ngIf="username?.errors?.required">
                            Please enter a username.
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-12 col-lg-6" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input class="form-control" type="text" formControlName="email" name="email" id="email" [class.is-invalid]="email.touched && email.invalid"  />
                    <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                        <div *ngIf="email?.errors?.required">
                            Please enter an email.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="firstName">First Name</label>
                    <input class="form-control" type="text" formControlName="firstName" name="firstName" id="firstName" [class.is-invalid]="firstName.touched && firstName.invalid" />
                    <div *ngIf="firstName?.invalid && firstName?.touched" class="invalid-feedback">
                        <div *ngIf="firstName?.errors?.required">
                            Please enter a first name.
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-12 col-lg-6" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="lastName">Last Name</label>
                    <input class="form-control" type="text" formControlName="lastName" name="lastName" id="lastName" [class.is-invalid]="lastName.touched && lastName.invalid"  />
                    <div *ngIf="lastName?.invalid && lastName?.touched" class="invalid-feedback">
                        <div *ngIf="lastName?.errors?.required">
                            Please enter a last name.
                        </div>
                    </div>
                </div> 
            </div>
            <div class="col-12 col-lg-6">
                <div class="form-group">
                    <label for="password">Password</label>
                    <input class="form-control" type="password" formControlName="password" name="password" id="password" [class.is-invalid]="password.touched && password.invalid" />
                    <div *ngIf="password?.invalid && password?.touched" class="invalid-feedback">
                        <div *ngIf="password?.errors?.required">
                            Please enter a password.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="confirmPassword">Confirm Password</label>
                    <input class="form-control" type="password" formControlName="confirmPassword" name="confirmPassword" id="confirmPassword" [class.is-invalid]="confirmPassword.touched && confirmPassword.invalid" />
                    <div *ngIf="confirmPassword?.invalid && confirmPassword?.touched" class="invalid-feedback">
                        <div *ngIf="confirmPassword?.errors?.required">
                            Please enter a confirm password.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="cellphone">Cell Phone</label>
                    <input class="form-control" formControlName="cellphone" name="cellphone" id="cellphone" [class.is-invalid]="cellphone.touched && cellphone.invalid"/>
                    <div *ngIf="cellphone?.invalid && cellphone?.touched" class="invalid-feedback">
                        <div *ngIf="cellphone?.errors?.required">
                            Please enter a cell phone.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="creatingAccount">
                <div class="form-group">
                    <label for="dateOfBirth">Date of Birth</label>
                    <input class="form-control" type="date" formControlName="dateOfBirth" name="dateOfBirth" [class.is-invalid]="dateOfBirth.touched && dateOfBirth.invalid"/>                    
                    <div *ngIf="dateOfBirth?.invalid && dateOfBirth?.touched" class="invalid-feedback">
                        <div *ngIf="dateOfBirth?.errors?.required">
                            Please enter a date of birth.
                        </div>
                    </div>
                </div>
            </div>
            <small class="text-danger d-block ml-3 mt-2" *ngIf="form.errors?.invalidPassword">
                Invalid password. Please try again.
            </small>
            <small class="text-danger d-block ml-3 mt-2" *ngIf="(password?.touched && confirmPassword?.touched) && form.errors?.invalidPasswordMatch">
                Passwords do not match. Please try again.
            </small>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <button type="submit" class="btn btn-primary ml-2" [disabled]="!form.valid">Submit</button>
    </div>
</form>