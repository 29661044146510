import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { RegisterationFormComponent } from '../registeration-form/registeration-form.component';

@Component({
  selector: 'gcl-lib-registeration',
  templateUrl: './registeration.component.html',
  styleUrls: ['./registeration.component.css']
})
export class RegisterationComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(RegisterationFormComponent) registerationFormComponent!: RegisterationFormComponent;

  @Output()
  onRegister = new EventEmitter();

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public disableSubmit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.registerationFormComponent.registrationForm.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.disableSubmit$.next(this.registerationFormComponent.registrationForm.invalid));
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  submit() {
    this.disableSubmit$.next(true);
    
    if (!this.registerationFormComponent.registrationForm.invalid) {
      this.authService.register({
        username: this.registerationFormComponent?.username?.value,
        email: this.registerationFormComponent?.email?.value,
        provider: "email",
        role: "Player",
        password: this.registerationFormComponent?.password?.value,
        cellphone: this.registerationFormComponent?.cellphone?.value,
        firstName: this.registerationFormComponent?.firstName?.value,
        lastName: this.registerationFormComponent?.lastName?.value,
        dateOfBirth: this.registerationFormComponent?.dateOfBirth?.value,
      })
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(result => {
          this.registerationFormComponent.registrationForm.setErrors({});
          this.onRegister.emit();
        }, err => {
          this.registerationFormComponent.registrationForm.setErrors({
            invalidPassword: true
          });
        });
    }
  }
}
