<div class="container py-4" *ngIf="order$ | async as order">
    <div class="row">
        <div class="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto">

            <h2 class="text-primary text-center">Order Submitted</h2>
            <h6 class="text-center" *ngIf="authService.user$ | async as user">You are confirmed. An email has been sent to '{{user.email}}.'</h6>
            <div>
                <h5 class="text-center">Summary</h5>
        
                <h5 *ngIf="order.round">{{getDateTime(order.round?.datestr, order.round?.starttod) | date:"fullDate"}} {{getDateTime(order.round?.datestr, order.round?.starttod) | date:"shortTime"}}</h5>
                <h5>{{order.course?.full_name}}</h5>
                <p class="font-weight-light">{{order.course?.address}}</p>

                <h6>Particulars:</h6>
                <ng-container *ngIf="state.addTeeTime">
                    <p>People in Party: {{state.addTeeTime.numOfGolfers}}</p>
                    <p>Course: {{state.addTeeTime.numOfHoles}} Holes <span *ngIf="state.getGolfProduct()">| {{state.getGolfProduct()?.price | currency}}</span></p>
                    <p>Include Cart: {{state.addTeeTime.addCart ? 'Yes' : 'No'}}</p>
                </ng-container>

                <div class="py-2">
                    <h5 class="text-center text-primary">Golf Orders</h5>
                    <ul class="pb-2 list-group rounded-0" *ngIf="state.golfproduct$ | async as golfproduct">
                        <ng-container *ngIf="state.order$ | async as order">
                            <li class="list-group-item" *ngFor="let item of order.golforders; let index = index">
                                <div class="d-flex justify-content-between">
                                    <div class="flex-grow-1 align-self-center px-2">
                                        <span *ngIf="index == 0">Reserving Player - </span>
                                        <span *ngIf="index != 0">Guest Player {{index}} - </span>
                                        {{golfproduct.name}}
                                    </div>
                                    <div class="px-2 align-self-center">{{item?.extprice | currency}}</div>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                
                <h5 class="text-center text-primary">Item List</h5>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let item of productOrders$ | async">
                        <h6>{{item?.product?.name}} ({{item?.quantity}}) <span class="float-right">{{item?.extprice | currency}}</span></h6>
                    </li>
                    <li class="list-group-item" *ngIf="((productOrders$ | async)?.length || 0) == 0">No items in cart.</li>
                </ul>
        
                <button class="btn btn-primary btn-block my-4" type="button" (click)="continue()">Continue</button>
            </div>
        </div>
    </div>
</div>
