<div class="row border-bottom py-3 cursor" [ngClass]="{'bg-light': isClicked}" (click)="clicked()">
    <div class="col-12 py-2">
        <h6 class="m-0">
            <img *ngIf="image" alt="{{label}}" class="img-fluid pr-4" [src]="image" /> 
            <fa-icon *ngIf="faIcon" [icon]="faIcon" class="selection-icon rounded-circle"></fa-icon>
            {{label}}
            <span class="float-right" [style.padding-top]="'12px'">
                <fa-icon size="lg" [icon]="faChevronRight"></fa-icon>
            </span>
        </h6>
    </div>
</div>