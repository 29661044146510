import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  StripeInstance,
} from "ngx-stripe";
import { PaymentMethod } from '@stripe/stripe-js';
import { BsModalService } from 'ngx-bootstrap/modal';

import { environment } from '../../../../environments/environment';
import { NewOrderStorage, OrderDetails } from 'projects/shared/src/lib/models/order';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StrapiPaymentIntent } from 'projects/shared/src/lib/models/strapi-payment-intent';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { StripeCreateCardComponent } from 'projects/shared/src/lib/stripe/stripe-create-card/stripe-create-card.component';
import { RegisterationFormComponent } from 'projects/shared/src/lib/components/registeration-form/registeration-form.component';

import { GolfPassManager, GolfPassStates } from '../../golf-pass.manager';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';

@Component({
  selector: 'gcl-user-golf-pass-form',
  templateUrl: './golf-pass-form.component.html',
  styleUrls: ['./golf-pass-form.component.scss']
})
export class GolfPassFormComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public getDateTime = getDateTime;

  public stripe!: StripeInstance;
  public selectedPaymentMethodId?: string;
  public isSubmitted: boolean = false;

  private clientSecret$!: Observable<StrapiPaymentIntent>;
  private newPaymentToken?: string;

  @ViewChild(RegisterationFormComponent) registerationFormComponent!: RegisterationFormComponent;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    private orderService: OrderService,
    private stripeService: StripeService,
    public authService: AuthService,
    public golfpassService: GolfPassService,
    public golfpassManager: GolfPassManager,
  ) { }

  ngOnInit(): void {
    this.stripe = this.stripeService.createInstance();

    const golfpassId$ = this.route.params.pipe(
      map(params => params.id),
    );

    const golfpass$ = golfpassId$
      .pipe(
        switchMap((id) => this.golfpassService.get(id)),
        shareReplay(1),
        takeUntil(this.destroy$),
      );

    combineLatest([golfpass$, this.authService.user$])
      .pipe(
        switchMap(([golfpass, user]) =>{
          this.stripeService.reloadPaymentList$.next(user.id);
          this.golfpassManager.golfpass$.next(golfpass);
          return (this.golfpassManager.orderId$.value) ? this.updateOrder(this.golfpassManager.orderId$.value) : this.createOrder({
            users_permissions_user: user.id,
            course: golfpass.course.id,
            golfpassorders: [{
              golfpass: golfpass.id,
              quantity: 1
            }]
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((order) => {
        this.clientSecret$ = this.orderService.recalculate(order.id)
          .pipe(
            switchMap((order: OrderDetails) => this.createPaymentIntent(order)),
            takeUntil(this.destroy$)
          );
        this.golfpassManager.orderId$.next(order.id);
      });
  }

  private updateOrder(orderId: number): Observable<OrderDetails> {
    return this.orderService.get(orderId)
      .pipe(
        switchMap((order: OrderDetails) => {
          return this.orderService.update(order.id, order);
        }),
        shareReplay(1),
        takeUntil(this.destroy$)
      )
  }

  private createOrder(order: Partial<NewOrderStorage>): Observable<OrderDetails> {
    return this.golfpassManager.submitOrder(order)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );
  }

  private createPaymentIntent(order: OrderDetails): Observable<StrapiPaymentIntent> {
    return this.stripeService.createPaymentIntent(order.users_permissions_user?.id as number, order.id, order.finaltotal)
      .pipe(
        takeUntil(this.destroy$)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public onShowNewPayment(): void {
    if (this.golfpassManager.orderId$.value != null) {
      this.golfpassManager.orderId$
        .pipe(
          switchMap(orderId => this.orderService.get(orderId as number)),
          takeUntil(this.destroy$)
        )
        .subscribe((order) => {
          const paymentModalRef = this.modalService.show(StripeCreateCardComponent, {
            ignoreBackdropClick: true,
            class: 'modal-lg modal-dialog-centered',
            initialState: {
              stripe: this.stripe,
              userId: order.users_permissions_user?.id
            }
          });

          paymentModalRef.content?.cancel
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe(() => paymentModalRef.hide());

          paymentModalRef.content?.submit
            .pipe(
              takeUntil(this.destroy$)
            )
            .subscribe((payment: StripePaymentToken) => {
              if (!payment.error) {
                if (payment.save) {
                  this.toastrService.success("Payment Added");
                  this.selectedPaymentMethodId = payment.paymentMethodId;
                  this.stripeService.reloadPaymentList$.next(order.users_permissions_user?.id as number);
                } else {
                  this.newPaymentToken = payment.token;
                  this.submitPayment();
                }

                paymentModalRef.hide();
              } else {
                this.displayError(payment.error);
              }
            });
        });
    }
  }

  public isPaymentSelectedOrCreated(): boolean {
    return (this.newPaymentToken != undefined) || (this.selectedPaymentMethodId != undefined);
  }

  public onSelectPaymentMethod(method: PaymentMethod): void {
    this.selectedPaymentMethodId = method.id;
  }

  public cancelOrder(): void {
    if (this.golfpassManager.orderId$.value) {
      this.golfpassManager.cancelOrder(this.golfpassManager.orderId$.value);
    }
    this.golfpassManager.state$.next(GolfPassStates.Payment);
  }

  public submitPayment(): void {
    if (this.isPaymentSelectedOrCreated()) {
      this.isSubmitted = true;

      this.clientSecret$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(
          (paymentIntent) => {
            const payment = this.newPaymentToken ? { payment_method: { card: { token: this.newPaymentToken as string } } } : { payment_method: this.selectedPaymentMethodId as string };
            this.golfpassManager.setPayment(this.stripe, paymentIntent.clientSecret, payment);
            this.golfpassManager.state$.next(GolfPassStates.Summary);
          },
          (error) => {
            this.displayError("Error processing payment.");
          }
        );
    } else {
      this.displayError("A payment is required.");
    }
  }

  private displayError(message: string) {
    this.isSubmitted = false;
    this.toastrService.error(message);
  }

}
