import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { PasswordValidators } from '../../validators/password-validators';

@Component({
  selector: 'gcl-lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  get password() { return this.resetForm.get('password') as FormControl; }
  get confirmPassword() { return this.resetForm.get('confirmPassword') as FormControl; }

  resetForm = this.fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  }, { validators: [PasswordValidators.MatchPassword] });

  private code$!: Observable<string>;

  @Output()
  onSubmit = new EventEmitter();

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.code$ = this.route.queryParams.pipe(
      map(params => params.code)
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  submit() {
    this.code$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((code: string) => {
        this.authService.resetPassword(code, this.password?.value, this?.confirmPassword?.value)
          .subscribe(result => {
            this.resetForm.setErrors({});
            this.onSubmit.emit();
          }, err => {
            this.resetForm.setErrors({
              invalidEmail: true
            })
          });
      });
  }
}
