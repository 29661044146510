<div class="py-4">
    <gcl-user-user-breadcrumb class="py-4">
        <span class="text-primary">Account</span>
    </gcl-user-user-breadcrumb>

    <gcl-lib-two-pane-horizontal-resizable [width]="'50%'" class="d-none d-lg-block">
        <div slot="left" class="h-100 d-flex flex-column">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div slot="right">
            <ng-container *ngTemplateOutlet="right"></ng-container>
        </div>
    </gcl-lib-two-pane-horizontal-resizable>
    
    <!-- Mobile -->
    <div class="row d-block d-lg-none">
        <div  class="col-12">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div class="col-12 py-4">
            <ng-container *ngTemplateOutlet="right"></ng-container>
        </div>
    </div>
    
    <!-- Templates -->
    <ng-template #left>
        <h3 class="card-title text-center text-primary">Account</h3>
        <ng-container>
            <div class="row" *ngIf="user$ | async as user">
                <div class="col-12 col-md-6 col-lg-8">
                    <h5>Personal Info</h5>
                    <div>
                        <p>Particulars:</p>
                        <p>Username: {{user.username}}</p>
                        <p>Email: {{user.email}}</p>
                        <p>Name: {{user.firstName}} {{user.lastName}}</p>
                        <p>Date of Birth: {{user.dateOfBirth | date: 'mediumDate'}}</p>
                        <p>Golf Pass: {{user?.purchasedGolfPass ? "Active" : ""}}</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <gcl-lib-user-credit-balance 
                        [userId]="user.id"
                        [reload$]="reload$"></gcl-lib-user-credit-balance>
                </div>
            </div>

            <gcl-user-row-selection-option
                [label] = "'Personal Info'"
                [faIcon]= "faUser"
                [isClicked] = "selection$.value == AccountSelections.EditUserForm"
                (click)="ShowEditUserForm()">
            </gcl-user-row-selection-option>
            <gcl-user-row-selection-option
                [label] = "'Password'"
                [faIcon]= "faLock"
                [isClicked] = "selection$.value == AccountSelections.ChangePasswordForm"
                (click)="ShowPasswordForm()">
            </gcl-user-row-selection-option>
            <gcl-user-row-selection-option
                [label] = "'Payment Methods'"
                [faIcon]= "faCreditCard"
                [isClicked] = "selection$.value == AccountSelections.PaymentMethods"
                (click)="ShowPaymentForm()">
            </gcl-user-row-selection-option>
            <gcl-user-row-selection-option
                [label] = "'Delete Account'"
                [faIcon]= "faTrash"
                [isClicked] = "selection$.value == AccountSelections.DeleteAccount"
                (click)="ShowDeleteAccountForm()">
            </gcl-user-row-selection-option>
                
        </ng-container>
    </ng-template>
    
    <ng-template #right>
        <div *ngIf="selection$ | async as state">
            <gcl-user-account-edit-user *ngIf="state == AccountSelections.EditUserForm"></gcl-user-account-edit-user>
            <gcl-user-account-change-password *ngIf="state == AccountSelections.ChangePasswordForm"></gcl-user-account-change-password>
            <gcl-user-user-payment-methods *ngIf="state == AccountSelections.PaymentMethods"></gcl-user-user-payment-methods>
            <gcl-user-delete-account *ngIf="state == AccountSelections.DeleteAccount"></gcl-user-delete-account>
        </div>
    </ng-template>

</div>