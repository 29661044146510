import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { StripeInstance } from "ngx-stripe";
import { PaymentMethod } from '@stripe/stripe-js';
import { BsModalService } from 'ngx-bootstrap/modal';

import { environment } from '../../../../environments/environment';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StrapiPaymentIntent } from 'projects/shared/src/lib/models/strapi-payment-intent';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { StripeCreateCardComponent } from 'projects/shared/src/lib/stripe/stripe-create-card/stripe-create-card.component';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { ProductOrder, UserDetails } from 'projects/shared/src/public-api';
import { PagedResult } from 'projects/shared/src/lib/queries/paged-result';
import { CreditBalanceTransactionService } from 'projects/shared/src/lib/services/credit-balance-transaction.service';

@Component({
  selector: 'gcl-user-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public getDateTime = getDateTime;

  public stripe!: StripeInstance;
  public selectedPaymentMethodId?: string;
  public isSubmitted: boolean = false;

  public userId!: number;
  public creditBalance$!: Observable<number>;
  public products!: Array<ProductOrder>;

  private clientSecret$!: Observable<StrapiPaymentIntent>;
  private newPaymentToken?: string;


  get applyCreditBalance() { return this.form.get("applyCreditBalance") as FormControl }
  public form = this.fb.group({
    applyCreditBalance: [false, [Validators.required]],
  });

  private destory$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    public state: TeeTimeManager,
    private orderService: OrderService,
    private creditBalanceService: CreditBalanceTransactionService,
    private stripeService: StripeService,
    private confirmationModalService: ConfirmationModalService,
    private toastrService: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.state.getCurrentUser$()
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(user => {
        this.userId = user.id;
        this.creditBalance$ = this.creditBalanceService.getBalance(user.id);
        this.stripeService.reloadPaymentList$.next(this.userId);
      });

    this.form.patchValue({
      applyCreditBalance: this.state.applyCreditBalance || false
    });

    this.stripe = this.stripeService.createInstance();
    this.clientSecret$ = this.orderService.getCurrentOrder()
      .pipe(
        switchMap(order => this.createPaymentIntent(order)),
        takeUntil(this.destory$)
      );

    this.state.getOrderProducts$()
      .pipe(
        takeUntil(this.destory$)
      ).subscribe(products => this.products = products);

    this.applyCreditBalance.valueChanges
      .pipe(
        switchMap(applyCredit => {
          if (applyCredit) {
            return this.state.applyCredit();
          }
          return this.state.removeCredit();
        }),
        switchMap(order => this.state.getOrder()),
        takeUntil(this.destory$)
      ).subscribe(order => {
        this.state.applyCreditBalance = order.useCreditBalance;
      });
  }

  private createPaymentIntent(order: OrderDetails): Observable<StrapiPaymentIntent> {
    const finalTotal = order.finaltotal;  // Stripe requires this.
    const user = order.users_permissions_user as UserDetails;

    return this.stripeService.createPaymentIntent(user.id, order.id, finalTotal)
      .pipe(
        takeUntil(this.destory$)
      );
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }

  public onShowNewPayment(): void {
    const paymentModalRef = this.modalService.show(StripeCreateCardComponent, {
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered',
      initialState: {
        stripe: this.stripe,
        userId: this.userId
      }
    });

    paymentModalRef?.content?.cancel
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => paymentModalRef.hide());

    paymentModalRef?.content?.submit
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe((payment: StripePaymentToken) => {
        if (!payment.error) {
          if (payment.save) {
            this.toastrService.success("Payment Added");
            this.selectedPaymentMethodId = payment.paymentMethodId;
            this.stripeService.reloadPaymentList$.next(this.userId);
          } else {
            this.newPaymentToken = payment.token;
            this.confirmSubmission();
          }

          paymentModalRef.hide();
        } else {
          this.toastrService.error(payment.error);
        }
      });
  }

  public isPaymentSelectedOrCreated(): boolean {
    return (this.newPaymentToken != undefined) || (this.selectedPaymentMethodId != undefined);
  }

  public onSelectPaymentMethod(method: PaymentMethod): void {
    this.selectedPaymentMethodId = method.id;
  }

  public cancelOrder(): void {
    this.state.cancelOrder()
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => this.state.step$.next(AddTeeTimeState.AddTeeTime));
  }

  public confirmSubmission(): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Submission',
      message: 'You are about to submit your order? This action cannot be undone.'
    })
      .pipe(
        switchMap(accept => combineLatest([of(accept), this.state.order$])),
        takeUntil(this.destory$)
      )
      .subscribe(
        ([accept, order]) => {
          if (accept) {
            this.submit(order);
          }
        },
        (error) => {
          this.displayError("An issued occurred when submitting your order. Please try again. If you continue to run into issues, please contact us.");
        })
  }

  private submit(order: OrderDetails): void {
    // In the event that the applied credit balance or promo paids for the entire order.
    if (order.finaltotal == 0) {
      this.orderService.completeCurrentOrder()
        .pipe(
          takeUntil(this.destory$)
        )
        .subscribe(() => this.state.step$.next(AddTeeTimeState.OrderConfirmation));
    }
    else if (this.isPaymentSelectedOrCreated()) {
      this.isSubmitted = true;

      this.clientSecret$
        .pipe(
          switchMap((paymentIntent: StrapiPaymentIntent) => {
            const payment = this.newPaymentToken ? { payment_method: { card: { token: this.newPaymentToken as string } } } : { payment_method: this.selectedPaymentMethodId as string };
            return combineLatest([this.stripe.confirmCardPayment(paymentIntent.clientSecret, payment), this.state.order$]);
          }),
          takeUntil(this.destory$)
        )
        .subscribe(
          (payment) => {
            const intent = payment[0];

            if (!intent.error) {
              this.orderService.completeCurrentOrder()
                .pipe(
                  takeUntil(this.destory$)
                )
                .subscribe(() => this.state.step$.next(AddTeeTimeState.OrderConfirmation));
            } else {
              this.displayError("An error occurred processing your payment. Please try again.");
            }
          },
          (error) => {
            this.displayError("An error occurred processing your payment. Please try again.");
          }
        );
    } else {
      this.displayError("Payment is required.");
    }
  }

  private displayError(message: string) {
    this.isSubmitted = false;
    this.toastrService.error(message);
  }
}
