import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gcl-lib-two-pane-horizontal-resizable',
  templateUrl: './two-pane-horizontal-resizable.component.html',
  styleUrls: ['./two-pane-horizontal-resizable.component.css']
})
export class TwoPaneHorizontalResizableComponent implements OnInit {
  @Input()
  minWidth: string = "200px";

  @Input()
  maxWidth: string = "600px";

  @Input()
  width: string = "385px";

  @Input()
  containerClass: string = "container-fluid h-100";
  @Input()
  leftColumnClass: string = "px-2 h-100";
  @Input()
  rightColumnClass: string = "col h-100";

  @Input()
  border: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
