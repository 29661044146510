import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';

import { SharedModule } from 'projects/shared/src/lib/shared.module';
import { environment } from '../../environments/environment';

import { GolfPassRoutingModule } from './golf-pass-routing.module';
import { GolfPassCheckoutComponent } from './golf-pass-checkout/golf-pass-checkout.component';
import { GolfPassFormComponent } from './components/golf-pass-form/golf-pass-form.component';
import { GolfPassOrderSummaryComponent } from './components/golf-pass-order-summary/golf-pass-order-summary.component';

@NgModule({
  declarations: [ 
    GolfPassCheckoutComponent,
    GolfPassFormComponent,
    GolfPassOrderSummaryComponent
  ],
  imports: [
    CommonModule,
    
    FontAwesomeModule,
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot(),
    
    SharedModule,
    
    GolfPassRoutingModule
  ]
})
export class GolfPassModule { }
