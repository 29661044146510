import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { PasswordValidators } from 'projects/shared/src/lib/validators/password-validators';
import { take } from 'rxjs/operators';

@Component({
  selector: 'gcl-user-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  form = this.fb.group({
    username: this.fb.control('', [Validators.required]),
    email: this.fb.control('', []),
    password: this.fb.control('', [Validators.required]),
    confirmPassword: this.fb.control('', []),
    cellphone: this.fb.control('', []),
    firstName: this.fb.control('', []),
    lastName: this.fb.control('', []),
    dateOfBirth: this.fb.control('', [])
  });

  get username() { return this.form.get('username') as FormControl; }
  get email() { return this.form.get('email') as FormControl; }
  get password() { return this.form.get('password') as FormControl; }
  get confirmPassword() { return this.form.get('confirmPassword') as FormControl; }
  get cellphone() { return this.form.get('cellphone') as FormControl; }
  get firstName() { return this.form.get('firstName') as FormControl; }
  get lastName() { return this.form.get('lastName') as FormControl; }
  get dateOfBirth() { return this.form.get('dateOfBirth') as FormControl; }

  public creatingAccount: boolean = false;

  constructor(private fb: FormBuilder, public bsModalRef: BsModalRef, private authService: AuthService) { }

  ngOnInit(): void {
  }

  async submit() {
    if(this.creatingAccount) {
      let resp = await this.authService.register({
        ...this.form.value,
        provider: "email",
        role: "Player",
      }).pipe(take(1)).toPromise();
    }
    else {
      let resp = await this.authService.login(this.form.value.username, this.form.value.password).pipe(take(1)).toPromise();
    }

    this.authService.triggerExternalLogin();
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

  createAccount() {
    this.creatingAccount = true;

    this.form.clearValidators();
    this.form.setValidators([PasswordValidators.MatchPassword]);
    this.form.updateValueAndValidity();

    this.email.clearValidators();
    this.email.setValidators([Validators.required, Validators.email]);
    this.email.updateValueAndValidity();

    this.firstName.clearValidators();
    this.firstName.setValidators([Validators.required]);
    this.firstName.updateValueAndValidity();

    this.lastName.clearValidators();
    this.lastName.setValidators([Validators.required]);
    this.lastName.updateValueAndValidity();

    this.confirmPassword.clearValidators();
    this.confirmPassword.setValidators([Validators.required]);
    this.confirmPassword.updateValueAndValidity();

    this.cellphone.clearValidators();
    this.cellphone.setValidators([Validators.required]);
    this.cellphone.updateValueAndValidity();

    this.dateOfBirth.clearValidators();
    this.dateOfBirth.setValidators([Validators.required]);
    this.dateOfBirth.updateValueAndValidity();
  }

  showLogin() {
    this.creatingAccount = false;

    this.form.clearValidators();
    this.form.updateValueAndValidity();

    this.email.clearValidators();
    this.email.updateValueAndValidity();

    this.firstName.clearValidators();
    this.firstName.updateValueAndValidity();

    this.lastName.clearValidators();
    this.lastName.updateValueAndValidity();

    this.confirmPassword.clearValidators();
    this.confirmPassword.updateValueAndValidity();

    this.cellphone.clearValidators();
    this.cellphone.updateValueAndValidity();

    this.dateOfBirth.clearValidators();
    this.dateOfBirth.updateValueAndValidity();
  }
}
