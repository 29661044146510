import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddTeeTimeState, TeeTimeManager } from '../tee-time.manager';

@Component({
  selector: 'gcl-user-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [TeeTimeManager]
})
export class LayoutComponent implements OnInit {
  public AddTeeTimeState = AddTeeTimeState;

  public step$!: Observable<AddTeeTimeState>;

  constructor(private state: TeeTimeManager) { }

  ngOnInit(): void {
    this.step$ = this.state.step$;

    this.step$
      .pipe(

      )
      .subscribe(step => {
        // Prevents getting the next unsubmitted order for the player if they completed their order.
        if (step != AddTeeTimeState.OrderConfirmation) {
          this.state.order$ = this.state.getOrder();
        }
      });
  }

}
