import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AccountRoutingModule } from './account-routing.module';
import { AccountViewComponent } from './account-view/account-view.component';

import { SharedModule } from 'projects/shared/src/public-api';
import { AccountChangePasswordComponent } from './account-view/components/account-change-password/account-change-password.component';
import { AccountEditUserComponent } from './account-view/components/account-edit-user/account-edit-user.component';
import { UserBreadcrumbComponent } from './user-breadcrumb/user-breadcrumb.component';
import { AccountOrderSummaryComponent } from './account-order-summary/account-order-summary.component';
import { AccountOldOrdersComponent } from './account-old-orders/account-old-orders.component';
import { UserPaymentMethodsComponent } from './account-view/components/user-payment-methods/user-payment-methods.component';
import { UserSharedModule } from '../shared/user-shared.module';
import { DeleteAccountComponent } from './account-view/components/delete-account/delete-account.component';

@NgModule({
  declarations: [
    AccountViewComponent, 
    AccountChangePasswordComponent, 
    AccountEditUserComponent, 
    UserBreadcrumbComponent, 
    AccountOrderSummaryComponent, 
    AccountOldOrdersComponent, 
    UserPaymentMethodsComponent,
    DeleteAccountComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    
    FontAwesomeModule,
    AccordionModule.forRoot(),
    ModalModule.forRoot(),

    UserSharedModule,
    SharedModule,
    
    AccountRoutingModule
  ]
})
export class AccountModule { }
