import { KeyValue } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { PopularTeeTime } from '../../models/popular-tee-time';
import { ReportService } from '../../services/report.service';

interface TeeTimeBar {
  [hour: string]: string
}

@Component({
  selector: 'gcl-lib-tee-times-bar-chart',
  templateUrl: './tee-times-bar-chart.component.html',
  styleUrls: ['./tee-times-bar-chart.component.css']
})
export class TeeTimesBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  viewMode: string = "day";
  @Input()
  courseId!: number;
  @Input()
  startHour!: number;
  @Input()
  endHour!: number;

  public teetimes: TeeTimeBar = {};

  private max: number = 100;
  private destroy$: Subject<boolean> = new Subject();

  constructor(private reportService: ReportService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["viewMode"] || changes["courseId"] || changes["startHour"] || changes["endHour"]) {
      this.getTeeTimes();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getTeeTimes(): void {
    const view = this.viewMode == "day" ? "lastday" : "lastweek";
    this.reportService.getPopularTeeTimes(this.courseId, view)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe((records) => {
        const total = records.reduce((total, r) => total + parseInt(r.total.toString()), 0);
        this.iterateTime((hour: number) => {
          const time = records.find(r => r.hour == hour);
          const percentage = (time ? (time?.total / total) : 0) * 100;
          this.teetimes[hour] = (this.max - percentage) + 'px';
        });
      })
  }

  public getTime(hourStr: string): string {
    const hour = parseInt(hourStr);
    const key = hour < 12 ? hour + " AM" : (hour > 12 ? hour - 12 : 12) + " PM";
    return key;
  }

  private iterateTime(callback: Function) {
    for (let h = this.startHour; h < this.endHour; h++) {
      callback(h);
    }
  }

  public keyNumOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    const aKey = parseInt(a.key);
    const bKey = parseInt(b.key);
    return aKey > bKey ? 1 : (bKey > aKey ? -11 : 0);
  }
}
