<nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/']">
            <img src="/assets/logo.svg" class="img-fluid">
        </a>
        
        <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle class="btn btn-link shadow-none p-0 dropdown-toggle" aria-controls="dropdown-basic">
                <fa-icon class="text-primary" [icon]="userIcon" size="lg"></fa-icon>
                <span  class="p-3" *ngIf="username$ | async as username">{{username}}</span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="button-basic">
                <li role="menuitem"><a class="dropdown-item" [routerLink]="['/account']">View</a></li>
                <!-- <li role="menuitem"><a class="dropdown-item" [routerLink]="['/golf-pass/1']">Golf Passes</a></li> -->
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                    <button type="button" class="btn btn-link dropdown-item" (click)="logout()">Logout</button>
                </li>
            </ul>
        </div>
    </div>
  </nav>