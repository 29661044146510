import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, @Inject(CONFIG_TOKEN) private config: SharedModuleConfig) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.url.includes(this.config.apiUrl)) {
        let headers: { [name: string]: string } = {};
        let accessToken = this.authService.getAccessToken();
    
        if(accessToken) {
            headers.Authorization = `Bearer ${accessToken}`
        }    

        request = request.clone({
            setHeaders: headers
        });
    }

    return next.handle(request);
  }
}
