import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GoogleMapsGeocodingResult, GoogleMapsGeocodingResultRow, GoogleMapsGeocodingResultRowAddressComponent } from '../models/google-maps-geocoding-result';
import { NormalizedAddress } from '../models/normalized-address';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(@Inject(CONFIG_TOKEN) private config: SharedModuleConfig, private httpClient: HttpClient, private cacheService: CacheService) { }

  public normalizeAddress(address: string): Observable<NormalizedAddress> {
    if (this.cacheService.has(address)) {
      return this.cacheService.get(address)
        .pipe(
          map((value: string) => JSON.parse(value) as NormalizedAddress)
        );
    }
    return this.getNormailizeAddress(address);
  }

  private getNormailizeAddress(address: string): Observable<NormalizedAddress> {
    return this.geocodeAddress(address).pipe(
      map((result: GoogleMapsGeocodingResult) => {
        if (result.status == "ZERO_RESULTS" || result.results.length == 0) {
          throw new Error(`No results found`);
        }

        if (result.status != 'OK') {
          throw new Error(`Invalid response status ${result.status}`);
        }

        const addressResult = result.results[0];

        const latitude = parseFloat(addressResult.geometry.location.lat);
        const longitude = parseFloat(addressResult.geometry.location.lng);
        const streeNumber = this.getComponent(addressResult, 'street_number')?.long_name;
        const route = this.getComponent(addressResult, 'route')?.long_name;
        const city = this.getComponent(addressResult, 'locality')?.long_name || "";
        const state = this.getComponent(addressResult, 'administrative_area_level_1')?.long_name || "";
        const country = this.getComponent(addressResult, 'country')?.short_name || "";
        const postalCode = this.getComponent(addressResult, 'postal_code')?.long_name || "";

        const normalizeAddress: NormalizedAddress = {
          formattedAddress: addressResult.formatted_address,
          latitude: latitude,
          longitude: longitude,
          addressLine1: `${streeNumber} ${route}`,
          city: city,
          state: state,
          country: country,
          postalCode: postalCode,
        };

        this.cacheService.set(address, JSON.stringify(normalizeAddress));

        return normalizeAddress;
      })
    );
  }

  private getComponent(result: GoogleMapsGeocodingResultRow, type: string): GoogleMapsGeocodingResultRowAddressComponent | undefined {
    return result.address_components.find(component => component.types.includes(type));
  }

  private geocodeAddress(address: string): Observable<GoogleMapsGeocodingResult> {
    let params = new HttpParams();

    params = params.append('address', address);
    params = params.append('key', this.config.googleMapsApiKey);

    return this.httpClient.get<GoogleMapsGeocodingResult>('https://maps.googleapis.com/maps/api/geocode/json', {
      params
    });
  }
}
