<button type="button" class="btn btn-sm btn-default btn-block payment-option text-uppercase h-100 w-100" 
    [ngClass]="{'btn-light-green': selected }"
    (click)="onClick()">
        <span class="float-left">
            <fa-icon size="4x" [icon]="getBrandIcon(paymentMethod?.card?.brand)"></fa-icon>
        </span>
        <span class="float-right text-right">
            <small>{{truncateName(paymentMethod.billing_details.name)}}</small> <br/>
            <small>{{paymentMethod?.card?.brand}} ****{{paymentMethod?.card?.last4}}</small> <br/>
            <small>Exp. Date: {{paymentMethod?.card?.exp_month}}/{{paymentMethod?.card?.exp_year}}</small>
        </span>
</button>