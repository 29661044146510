export enum Roles {
    Player = "3",
    Manager = "4",
    Administrator = "5",
}

export function GetRolesDisplayName(status: Roles): string {
    switch (status) {
        case Roles.Manager:
            return "Manager";
        case Roles.Administrator:
            return "Adminstrator";
        default:
            return "Player";
    }
}