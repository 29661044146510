import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { Course } from 'projects/shared/src/lib/models/course';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { OnPageEvent, Order, OrderDetails, RoundDetails, UserDetails } from 'projects/shared/src/public-api';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { ToastrService } from 'ngx-toastr';
import { RoundService } from 'projects/shared/src/lib/services/round.service';
import { BannerDetails } from 'projects/shared/src/lib/models/banner';
import { BannerService } from 'projects/shared/src/lib/services/banner.service';
import { environment } from '../../environments/environment';
import { sequence } from '@angular/animations';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';

@Component({
  selector: 'gcl-user-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public apiUrl: string = environment.apiUrl;
  public getDateTime = getDateTime;

  public user$!: Observable<UserDetails>;

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<RoundDetails[]> = new ReplaySubject(1);
  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 5,
    page: 1,
    sortBy: 'round.starttod',
    sortDescending: false
  });

  public courses$!: Observable<Course[]>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public banners$: Observable<BannerDetails[]>;
  public apiBaseUrl;

  constructor(private orderService: OrderService, private roundService: RoundService, private confirmationModalService: ConfirmationModalService, private authService: AuthService, private toastrService: ToastrService, private bannerService: BannerService) {
    this.apiBaseUrl = environment.apiUrl;
    this.banners$ = this.bannerService.query({sortColumns: [{
      column: 'sequence',
      direction: SortDirections.Ascending
    }]
    }).records$;
  }

  ngOnInit(): void {
    this.courses$ = this.authService.userCourses$.pipe(
      shareReplay(1)
    );

    this.getPagedResult(1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getPagedResult(page: number) {
    const currentDate = new Date();
    currentDate.setHours(4, 0, 0);
    this.authService.user$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      )
      .subscribe(u => {
        const pagedResult = this.roundService.getUserRounds().pipe(
          map(rs => rs.filter(rr => {
          const rstime = new Date(rr.datestr + " " + rr.starttod);
          const today = new Date();
          return (rstime.compareDate(today) == 1) && (rr.order != undefined);
        })));

        pagedResult.subscribe((total) => this.total$.next(total.length));
        pagedResult.subscribe((records) => this.records$.next(records));
      });
  }

  public confirmcancelOrder(round: RoundDetails): void {
    this.confirmationModalService.showConfirmationModal({
      title: 'Confirm Cancellation',
      message: 'You are about to cancel this reservation? This action cannot be undone.',
      cancelBtnClass: "btn-primary",
      acceptBtnClass: "btn-danger"
    }).subscribe(accept => {
      if (accept) {
        this.cancelOrder(round);
      }
    });
  }

  private cancelOrder(round: RoundDetails): void {
    this.orderService.cancel(round?.order?.id as number).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (order) => {
        this.toastrService.success("Order was cancelled.");
        this.getPagedResult(1);
      },
      (error) => this.toastrService.error("An error occurred when cancelling your order.")
    );
  }

  reroute(redirectUrl: any) {
    window.open(redirectUrl)
  }
}
