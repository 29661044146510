import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';

import { CONFIG_TOKEN, SharedModuleConfig } from './shared.config';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxStripeModule } from 'ngx-stripe';

import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoadingScreenInterceptor } from './interceptors/loading-screen.interceptor';

import { Components } from './components';
import { Services } from './services';
import { StripeComponents } from './stripe';
import { Pipes } from './pipes';
import { Directives } from './directives';
import { MarkdownModule } from 'ngx-markdown';
import { SearchGolfPassesComponent } from './components/search-golf-passes/search-golf-passes.component';
import { UserCreditBalanceComponent } from './components/user-credit-balance/user-credit-balance.component';


@NgModule({
  declarations: [Components, Pipes, Directives, StripeComponents, SearchGolfPassesComponent, UserCreditBalanceComponent],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,

    FontAwesomeModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    ProgressbarModule.forRoot(),
    MarkdownModule.forRoot(),

    GoogleMapsModule,
    NgxStripeModule.forRoot(),
  ],
  exports: [Components, Pipes, Directives, StripeComponents]
})
export class SharedModule {
  static forRoot(config: SharedModuleConfig): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: CONFIG_TOKEN, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        Services,
        { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true }
      ]
    };
  }

}
