<div class="modal-body">
    <div class="d-flex justify-content-between">
        <div class="text-danger font-weight-bold">REFUND</div>
        <div class="text-danger font-weight-bold font-size-large">({{refundTotal | currency}})</div>
    </div>
    <div class="row mx-0 mt-2">
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>Order Number</label>
                <p>{{order?.id}}</p>
            </div>
        </div>
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>Item Count</label>
                <p>{{itemsCount}}</p>
            </div>
        </div>
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>Timestamp</label>
                <p>{{order?.updated_at | date: 'medium'}}</p>
            </div>
        </div>
    </div>
    <div class="row mx-0 mt-2">
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>First Name</label>
                <p>{{order?.users_permissions_user?.firstName ? order?.users_permissions_user?.firstName : 'N/A'}}</p>
            </div>
        </div>
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>Last Name</label>
                <p>{{order?.users_permissions_user?.lastName ? order?.users_permissions_user?.lastName : 'N/A'}}</p>
            </div>
        </div>
        <div class="col-6 col-md-4">
            <div class="form-group">
                <label>Email</label>
                <p>{{order?.users_permissions_user?.email ? order?.users_permissions_user?.email : 'N/A'}}</p>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-light-green " (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-light-green btn-wide" (click)="refund()">Confirm</button>
</div>