<div class="py-4">
    <gcl-user-user-breadcrumb class="py-4">
        <a class="btn btn-link text-dark pr-1 pl-1" routerLinkActive="active-link" [routerLink]="['/account']">Account</a>
        <span> / </span>
        <span class="text-primary">Old Orders</span>
    </gcl-user-user-breadcrumb>

    <div class="container py-4">
        <div class="row">
            <div class="col-12 col-md-10 col-lg-8 mx-auto">
                <div class="card my-2">
                    <div class="card-body">       
                        <h3 class="card-title text-center text-primary">My Orders</h3>
                        <gcl-lib-pagination-content
                            [totalItems]="total$ | async"
                            [page]="pagination$.value.page"
                            [itemsPerPage]="pagination$.value.itemsPerPage"
                            [sortBy]="pagination$.value.sortBy"
                            [position]="'center'"
                            (onPage)="getPagedResult($event)">
                            <ng-container *ngFor="let order of orders$ | async">
                                <div class="bg-light p-3 my-2">
                                    <div class="d-flex justify-content-between">
                                        <div class="">
                                            <p>{{order?.course?.full_name}}</p>
                                            <p class="font-weight-light">{{order?.course?.address}}</p>
                                            <p class="font-weight-light">Reservation Date: {{getDateTime(order?.round?.datestr, order?.round?.starttod) | date: "short"}}</p>
                                        </div>
                                        <div class="">
                                            <a class="" [routerLink]="['/account', order.id, 'summary']">Summary</a>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="(total$ | async) == 0">
                                <p>There are no available orders.</p>
                            </div>
                        </gcl-lib-pagination-content>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>