<form [formGroup]="form">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <span [style.display]="showPageOptions ? 'block' : 'none'"><select formControlName="itemsPerPage"><option *ngFor="let option of itemsPerPageOptions" [ngValue]="option">{{option}}</option></select> items/page</span>
        <div class="d-flex justify-content-center align-self-start ml-auto">
            <div class="inner-addon left-addon" *ngIf="showSearch" >
                <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
                <input class="form-control" type="search" placeholder="Search" formControlName="search" >
            </div>
            <button *ngIf="enableCreate" class="btn btn-outline-light-green btn-sm ml-2" type="button" (click)="create()">
                <ng-container *ngIf="faCreateIcon && !createIcon">
                    <fa-icon [icon]="faCreateIcon"></fa-icon> {{createLabel ? createLabel : 'create'}}
                </ng-container>
                <ng-container *ngIf="createIcon">
                    <img class="img-fluid" [src]="createIcon" style="height: 28px"/> create
                </ng-container>
            </button>
        </div>
    </div>
    <div class="table-responsive border mb-4">
        <table [class]="tableClass">
            <ng-content select="thead"></ng-content>
            <ng-content select="tbody"></ng-content>
        </table>
    </div>
    <div class="d-flex justify-content-between align-items-center" *ngIf="totalItems" [style.display]="showPagination ? 'flex' : 'none !important'">
        <div>
            Showing {{recordStart}} to {{recordEnd}} of {{totalItems}} entries
        </div>
        <pagination formControlName="page" [totalItems]="totalItems" [itemsPerPage]="form.value!.itemsPerPage" [maxSize]="5" [directionLinks]="itemsPerPage >= totalItems ? false: true"></pagination>
    </div>
</form>