<div class="container">
    <ng-container *ngFor="let category of categories$ | async">
        <ng-container *ngIf="category.productTotal$ | async; else noProductsFound">
            <h3 class="py-4 text-center text-primary">{{category.categoryName}}</h3>
            <gcl-lib-pagination-content
                [totalItems]="category.productTotal$ |async"
                [page]="category.page"
                [itemsPerPage]="itemsPerPage"
                [position]="'center'"
                (onPage)="getPagedResult($event, category)">
    
                <div class="" *ngFor="let product of category.displayedProducts$ | async">
                    <div class="row g-0">
                        <div class="col-12 col-sm-4 col-lg-2">
                            <img [src]="apiUrl + product?.image?.url" [alt]="product?.image?.alternativeText" class="img-thumbnail img-sm"/>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-10">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <h5 class="d-inline">{{product?.name}}</h5>
                                    <p>{{product?.price | currency}}/each</p>
                                </div>
                                <div class="col-12 col-md-8">
                                    <gcl-lib-counter-button
                                        [ltext]="'Add '"
                                        [vtext]="'$value'"
                                        [rtext]="' to Order'"
                                        [bordered]="false"
                                        [value]="1"
                                        [min]="0"
                                        [max]="99"
                                        (clicked)="addProductToCart(product, $event)"></gcl-lib-counter-button>
                                </div>
                            </div>
                            <p class="pt-4">{{product?.description}}</p>
                        </div>
                    </div>
                    <hr>
                </div>
            </gcl-lib-pagination-content>
            <ng-container *ngIf="!(category.displayedProducts$ | async) || (category.displayedProducts$ | async)?.length === 0">
                <ng-container *ngTemplateOutlet="noProductsFound"></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!(categories$ | async) || (categories$ | async)?.length === 0">
        <ng-container *ngTemplateOutlet="noProductsFound"></ng-container>
    </ng-container>
</div>
<ng-template #noProductsFound>
    No products were found. Click <a class="cursor-pointer" (click)="goBack()">here</a> to go back.
</ng-template>


