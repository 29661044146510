import { Component, Input, OnInit } from '@angular/core';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { environment } from 'projects/user/src/environments/environment';

@Component({
  selector: 'gcl-user-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  public apiUrl = environment.apiUrl;

  @Input()
  course?: CourseDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
