<ng-container *ngIf="apiLoaded$ | async">
    <ng-container *ngIf="optionLoaded$ | async">
        <google-map height="100%" width="100%"
            [options]="mapOptions">

            <map-marker *ngIf="centerCoordinate"
                [position]="centerCoordinate.coordinate"
                [options]="centerCoordinate.options || {}">
            </map-marker>

            <ng-container  *ngFor="let markerPosition of markerPositions; let i = index">
                <map-marker #marker="mapMarker"
                    [position]="markerPosition.coordinate"
                    [options]="markerPosition.options || {}"
                    (mapClick)="openInfoWindow(marker, i)">
                    <map-info-window *ngIf="markerPosition.tooltipOptions">
                        <p>Name: {{markerPosition.tooltipOptions.cartName}}</p>
                        <a [routerLink]="markerPosition.tooltipOptions.url">Details</a>
                    </map-info-window>
                </map-marker>
            </ng-container>
        </google-map>
    </ng-container>
</ng-container>