import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { UserService } from 'projects/shared/src/lib/services/user.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'gcl-user-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  form = this.fb.group({
    accountDeletionReason: this.fb.control('', [])
  })

  constructor(
    private fb: FormBuilder, 
    private confirmationModalService: ConfirmationModalService,
    private userService: UserService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  async submit() {
    let confirmed = await this.confirmationModalService.showConfirmationModal({
      title: 'Delete Account', 
      acceptBtnClass: 'btn-danger', 
      message: 'Are you sure you want to delete your Kartygo Account?'
    }).pipe(take(1)).toPromise();

    if(confirmed) {
      try {
        await this.userService.deleteAccount(this.form.value.accountDeletionReason).pipe(take(1)).toPromise(); 
        this.toastrService.success('Successfully submitted account deletion request.')
      }
      catch(err) {
        this.toastrService.error('An error occurred while deleting your Kartygo account.')
      }      
    }
  }
}
