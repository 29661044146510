import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

interface OrderWebhookReceivedEvent {
  orderId: number;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private onWebhookReceived$ = new Subject<OrderWebhookReceivedEvent>();
  webhookReceived$ = this.onWebhookReceived$.asObservable();

  socket: Socket;

  constructor(@Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    this.socket = io(config.apiUrl, {
      transports: ["websocket", "polling"]
    });

    this.socket.on('order:stripe:webhook:received', (orderId) => this.onWebhookReceived$.next({orderId}));

    this.socket.connect();
  }
}
