import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

interface ModalOptions {
  title: string;
  message: string;
  isHTML?: boolean;
  class?: string;
  cancelBtnClass?: string;
  acceptBtnClass?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmationModalService {

  constructor(private modalService: BsModalService) { }

  showConfirmationModal(options: ModalOptions) {

    const modalRef = this.modalService.show(ConfirmationModalComponent, {
      initialState: options,
      ignoreBackdropClick: true,
      focus: true
    });

    if (!modalRef.content) {
      return of(false);
    }

    return modalRef.content.accept$.pipe(
      take(1)
    );
  }
}
