<div class="container" *ngIf="order$ | async as order">
    <div class="row">
        <div class="col-12 col-md-10 mx-auto">
            <h3 class="card-title text-center text-primary">Order Summary</h3>

            <h5 *ngIf="order.round">{{getDateTime(order.round?.datestr, order.round?.starttod) | date:"fullDate"}} {{getDateTime(order.round?.datestr, order.round?.starttod) | date:"shortTime"}}</h5>
            <div class="row">
                <div class="col-12 col-md-3 text-center text-md-left pb-2 pb-md-0">
                    <img class="img-fluid img-thumbnail img-sm" [src]="apiUrl + order.course?.logo?.url" />
                </div>
                <div class="col-12 col-md-9">
                    <h5>{{order.course?.full_name}}</h5>
                    <p class="font-weight-light">{{order.course?.address}}</p>
                    <p>{{order.course?.description}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6>Particulars:</h6>
                    <ng-container *ngIf="golforder$ | async as golforder">
                        <p>People in Party: {{golforder.quantity}}</p>
                        <p>Course: {{golforder.golfproduct.holes}} Holes <span *ngIf="golforder.golfproduct">| {{golforder.golfproduct?.price | currency}}</span></p>
                        <p>Include Cart: {{golforder.golfproduct.cart ? 'Yes' : 'No'}}</p>
                    </ng-container>
                </div>
            </div>
            
            <div class="row">
                <div class="col-12">
                    <h5 class="card-title text-center text-primary">Item List</h5>
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let item of productorders$ | async">
                            <h6>{{item?.product?.name}} ({{item?.quantity}}) <span class="float-right">{{item?.extprice | currency}}</span></h6>
                        </li>
                        <li class="list-group-item" *ngIf="order.productorders.length == 0">No items in cart.</li>
                    </ul>
                </div>
            </div>

            <div class="py-2 text-primary">
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-8 mx-auto">
                        <h5>Round Total: <span class="float-right" *ngIf="golforder$ | async as golforder">{{golforder?.extprice | currency}}</span></h5>
                        <h5>Product Total: <span class="float-right" *ngIf="(productTotal$ | async) != undefined">{{(productTotal$ | async) | currency}}</span></h5>
                        <h5>Tax Total: <span class="float-right">{{order.tax | currency}}</span></h5>
                        <h5>Order Total: <span class="float-right">{{order.finaltotal | currency}}</span></h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>