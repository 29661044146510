import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { InputModalComponent } from '../components/input-modal/input-modal.component';

interface ModalOptions {
  title: string;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class InputModalService {

  constructor(private modalService: BsModalService) { }

  showConfirmationModal(options: ModalOptions, classOptions?: string) {

    const modalRef = this.modalService.show(InputModalComponent, {
      class: classOptions,
      initialState: options,
      ignoreBackdropClick: true,
      focus: true
    });

    if (!modalRef.content) {
      return of(0);
    }

    return modalRef.content.submit$.pipe(
      take(1)
    );
  }
}
