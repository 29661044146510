<form [formGroup]="editForm" (ngSubmit)="submit()">
    <small class="text-danger d-block mb-2" *ngIf="editForm.errors?.invalidPassword">
        Invalid password. Please try again.
    </small>
    <div class="form-row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="username">User Name</label>
                <input id="username" type="text" formControlName="username" placeholder="User Name" class="form-control"
                    [class.is-invalid]="username.touched && username.invalid" />
                <div *ngIf="username?.invalid && username?.touched" class="invalid-feedback">
                    <div *ngIf="username?.errors?.required">
                        Please enter a user name.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="email">Email</label>
                <input id="email" type="email" formControlName="email" placeholder="email" class="form-control"
                    [class.is-invalid]="email.touched && email.invalid" />
                <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
                    <div *ngIf="email?.errors?.required">
                        Please enter a email.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="firstName">First Name</label>
                <input id="firstName" type="text" formControlName="firstName" placeholder="First Name" class="form-control"
                    [class.is-invalid]="firstName.touched && firstName.invalid" />
                <div *ngIf="firstName?.invalid && firstName?.touched" class="invalid-feedback">
                    <div *ngIf="firstName?.errors?.required">
                        Please enter a first name.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="lastName">Last Name</label>
                <input id="lastName" type="text" formControlName="lastName" placeholder="Last Name" class="form-control"
                    [class.is-invalid]="lastName.touched && lastName.invalid" />
                <div *ngIf="lastName?.invalid && lastName?.touched" class="invalid-feedback">
                    <div *ngIf="lastName?.errors?.required">
                        Please enter a last name.
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="form-group">
        <label for="dateOfBirth">Date of Birth</label>
        <input id="dateOfBirth" type="date" formControlName="dateOfBirth" placeholder="Date Of Birth" class="form-control"
            [class.is-invalid]="dateOfBirth.touched && dateOfBirth.invalid" />
        <div *ngIf="dateOfBirth?.invalid && dateOfBirth?.touched" class="invalid-feedback">
            <div *ngIf="dateOfBirth?.errors?.required">
                Please enter a date of birth.
            </div>
        </div>
    </div>

    <br />
    <button type="submit" class="btn btn-primary btn-block" [disabled]="editForm.invalid">Confirm Changes</button>
</form>