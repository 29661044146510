import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Course, CourseDetails } from '../models/course';
import { TeetimeDetails } from '../models/teetime';
import { TeetimeRow } from '../models/teetime-row';
import { CourseQuery } from '../queries/course-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class CourseService extends StrapiQueryableService<Course, CourseDetails, CourseQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'courses' }, httpClient, config);
  }

  protected buildQuery(query?: CourseQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }, {
            full_name_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.name) {
        queryObj._where.push({ name: query.name });
      }
      if (query.full_name) {
        queryObj._where.push({ full_name: query.full_name });
      }
      if (query.holes) {
        queryObj._where.push({ holes: query.holes });
      }
      if (query.par) {
        queryObj._where.push({ par: query.par });
      }
      if (query.courselength) {
        queryObj._where.push({ courselength: query.courselength });
      }
      if (query.slope) {
        queryObj._where.push({ slope: query.slope });
      }
      if (query.rating) {
        queryObj._where.push({ rating: query.rating });
      }
      if (query.customer) {
        queryObj._where.push({ customer: query.customer });
      }
      if (query.mobileActive) {
        queryObj._where.push({ mobileActive: query.mobileActive });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: CourseQuery): PagedResult<CourseDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<CourseDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: CourseQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public getTeeTimes(courseId: number, date: string): Observable<Array<TeetimeRow>> {
    return this.httpClient.get<Array<TeetimeRow>>(`${this.config.apiUrl}/${this.pathConfig.path}/${courseId}/tee-times/${date}`)
      .pipe(
        shareReplay(1)
      );
  }
}
