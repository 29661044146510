import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ISODateStringToDate } from '../../utili/iso-date-string-to-date';

@Component({
  selector: 'gcl-lib-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }],
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  private propagateChange = (_: any) => { };

  @Input()
  time: string = "12:00 AM";
  @Input()
  minTime?: Date;
  @Input()
  maxTime?: Date;
  @Input()
  bordered: boolean = true;

  @Output()
  selectTime: EventEmitter<string> = new EventEmitter<string>();

  public dateTime!: Date;

  constructor() { }

  ngOnInit() {
    const date = new Date();
    this.dateTime = ISODateStringToDate(date.toISODate(), this.time);
  }

  public onSelectTime(time: Date): void {
    if (time) {
      this.time = time.toISOTime();
      
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");

      this.propagateChange(`${hours}:${minutes}`);
      this.selectTime.emit(`${hours}:${minutes}`);
    }
  }

  writeValue(val: string): void {
    this.time = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { }
}
