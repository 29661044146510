import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { UserDetails } from 'projects/shared/src/public-api';
import { faChevronRight, faCreditCard, faLock, faTrash, faUser } from '@fortawesome/free-solid-svg-icons';
import { AccountManager } from '../account.manager';

enum AccountSelections {
  InitView = 1,
  EditUserForm = 2,
  ChangePasswordForm = 3,
  PaymentMethods = 4,
  DeleteAccount = 5,
}

@Component({
  selector: 'gcl-user-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.scss'],
  providers: [AccountManager]
})
export class AccountViewComponent implements OnInit, OnDestroy {
  public AccountSelections = AccountSelections;
  public getDateTime = getDateTime;

  public faChevronRight = faChevronRight;
  public faUser = faUser;
  public faLock = faLock;
  public faCreditCard = faCreditCard;
  public faTrash = faTrash

  public user$!: Observable<UserDetails>;
  public reload$!: Observable<void>;
  public selection$: BehaviorSubject<AccountSelections> = new BehaviorSubject<AccountSelections>(AccountSelections.InitView);

  public courses$!: Observable<CourseDetails[]>;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private accountManager: AccountManager,
    private courseService: CourseService,
  ) { }

  ngOnInit(): void {
    this.user$ = this.accountManager.user$;
    this.accountManager.getUser();

    this.reload$ = this.accountManager.reloadCreditBalance$.asObservable();
    this.accountManager.reloadCreditBalance();
    
    this.courses$ = this.courseService.getAll()
      .pipe(
        shareReplay(1)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ShowEditUserForm(): void {
    this.selection$.next(AccountSelections.EditUserForm);
  }
  public ShowPasswordForm(): void {
    this.selection$.next(AccountSelections.ChangePasswordForm);
  }
  public ShowPaymentForm(): void {
    this.selection$.next(AccountSelections.PaymentMethods);
  }
  public ShowDeleteAccountForm(): void {
    this.selection$.next(AccountSelections.DeleteAccount);
  }
}
