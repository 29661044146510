<div [class]="containerClass">
    <div class="d-flex h-100 flex-wrap">
        <div class="px-2 h-100 left-panel" [style.width]="width">
            <ng-content select="[slot=left]"></ng-content>
        </div>
        <div class="ml-3 border d-none d-md-block"></div>
        <div [class]="rightColumnClass" class=" right-panel">
            <ng-content select="[slot=right]"></ng-content>
        </div>
    </div>
</div>