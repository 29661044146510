import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { CartStatuses, GetCartStatusesDisplayName } from '../../enumerations/cart-status';
import { EventSpaceType } from '../../enumerations/event-space-type';
import { EventType } from '../../enumerations/event-type';
import { GetNineTypesDisplayName, NineTypes } from '../../enumerations/nine-type';
import { GetOrderStatusesDisplayName, OrderStatuses } from '../../enumerations/order-status';
import { GetRolesDisplayName, Roles } from '../../enumerations/roles';
import { GetRoundStatusesDisplayName, RoundStatuses } from '../../enumerations/round-status';
import { GetScheduleTypeDisplayName, ScheduleTypes } from '../../enumerations/schedule-types';

@Component({
  selector: '[enum]',
  templateUrl: './enum-select.component.html',
  styleUrls: ['./enum-select.component.css'],
})
export class EnumSelectComponent implements OnInit {

  @Input() hasNullOption: boolean = false;
  @Input() enum!: string;
  @Input() value!: any;
  public options: { value?: any, text: string }[] = [];

  private enumMap: { [key: string]: any } = {
    "CartStatuses": CartStatuses,
    "OrderStatuses": OrderStatuses,
    "RoundStatuses": RoundStatuses,
    "Roles": Roles,
    "NineTypes": NineTypes,
    "ScheduleTypes": ScheduleTypes,
    "EventType": EventType,
    "EventSpaceType": EventSpaceType,
  }

  ngOnInit(): void {
    const value = this.enumMap[this.enum];

    if (!this.enum) { throw `missing [enum] input in <im-enum-select> componenet` }
    if (!value) { throw `missing ${this.enum} in <im-enum-select> componenet's enumMap` }

    Object.keys(value)
      .map((o: string) => { return { value: value[o], text: this.getEnumDisplayName(this.enum, value[o]) } })
      .forEach(option => this.options.push(option));

    this.options = this.options.sort((a: any, b: any) => a.text.localeCompare(b.text));

    if (this.hasNullOption) {
      // Set the value to an empty string instead of null because Angular will return a value of "null" if value is set to null, which is not null.
      this.options.unshift({ value: "", text: "Select..." });
    }
  }

  private getEnumDisplayName(enumType: any, value: any): string {
    switch (enumType) {
      case "CartStatuses":
        return GetCartStatusesDisplayName(value);
      case "OrderStatuses":
        return GetOrderStatusesDisplayName(value);
      case "RoundStatuses":
        return GetRoundStatusesDisplayName(value);
      case "Roles":
        return GetRolesDisplayName(value);
      case "NineTypes":
        return GetNineTypesDisplayName(value);
      case "ScheduleTypes":
        return GetScheduleTypeDisplayName(value);
      default:
        return this.enumMap[enumType][value];
    }
  }
}
