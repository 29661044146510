import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { OrderStatuses } from '../enumerations/order-status';
import { BlockTeeTime, UnblockTeeTime } from '../models/block-tee-time';
import { GolfOrderDetails } from '../models/golforder';

import { NewAdminOrder, NewOrder, Order, OrderDetails, UpdateAdminOrder } from '../models/order';
import { OrderPaymentDetails } from '../models/order-payment';
import { ProductDetails } from '../models/product';
import { OrderQuery } from '../queries/order-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';
import { ProductOrderDetails } from '../models/productorder';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class OrderService extends StrapiQueryableService<Order, OrderDetails, OrderQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'orders' }, httpClient, config);
  }

  protected buildQuery(query?: OrderQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }]
        });
      }

      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.status) {
        queryObj._where.push({ status: query.status });
      }
      if (query.course) {
        queryObj._where.push({ course: query.course });
      }
      if (query.users_permissions_user) {
        queryObj._where.push({ users_permissions_user: query.users_permissions_user });
      }
      if (query.round) {
        queryObj._where.push({ round: query.round });
      }
      if (query.golforders) {
        queryObj._where.push({ golforders: query.golforders });
      }
      if (query.productorders) {
        queryObj._where.push({ productorders: query.productorders });
      }
      if (query.startDate) {
        queryObj._where.push({ 'round.datestr_gte': query.startDate });
      }
      if (query.endDate) {
        queryObj._where.push({ 'round.datestr_lte': query.endDate });
      }
      if (query.statuses) {
        const key = "status_" + (query.statuses.isEqual ? "in" : "nin");

        // var filters: Array<object> = [];
        // query.statuses.statuses.forEach((status: OrderStatuses) => {
        //   var ff: any = {};
        //   ff[key] = status;
        //   filters.push(ff);
        // });

        queryObj._where.push({
          [key]: query.statuses.statuses
        });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: OrderQuery): PagedResult<OrderDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<OrderDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: OrderQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public submit(newOrder: Partial<NewOrder>): Observable<OrderDetails> {
    return this.httpClient.post<OrderDetails>(`${this.config.apiUrl}/orders/submit`, newOrder);
  }

  public cancel(orderId: number): Observable<Order> {
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/cancel/${orderId}`, {});
  }

  public refund(order: Order, amount: number): Observable<OrderPaymentDetails> {
    return this.httpClient.post<OrderPaymentDetails>(`${this.config.apiUrl}/orders/${order.id}/refund`, { amount: amount });
  }

  public refundMultiple(order: Order, golfOrders: Array<number>, productOrders: Array<number>): Observable<OrderDetails> {
    return this.httpClient.post<OrderDetails>(`${this.config.apiUrl}/orders/${order.id}/refund-multiple`, {golfOrders, productOrders});
  }

  public refundRainCheck(order: Order, golfOrders: Array<number>): Observable<OrderDetails> {
    return this.httpClient.post<OrderDetails>(`${this.config.apiUrl}/orders/${order.id}/refund-rain-check`, {golfOrders});
  }

  public recalculate(orderId: number): Observable<OrderDetails> {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/orders/${orderId}/recalculate`, {});
  }

  public adminSubmit(newOrder: Partial<NewAdminOrder>): Observable<Order> {
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/submit-admin`, newOrder);
  }

  public adminUpdate(orderId: number, order: Partial<UpdateAdminOrder>): Observable<Order> {
    return this.httpClient.put<Order>(`${this.config.apiUrl}/orders/${orderId}/update-admin`, order);
  }

  public adminBlock(blockTime: BlockTeeTime): Observable<void> {
    return this.httpClient.post<void>(`${this.config.apiUrl}/orders/block-admin`, blockTime);
  }

  public adminUnblock(unblockTime: UnblockTeeTime): Observable<void> {
    return this.httpClient.post<void>(`${this.config.apiUrl}/orders/unblock-admin`, unblockTime);
  }

  public addProduct(orderId: number, productId: number): Observable<Order> {
    let body: any = {};

    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/${orderId}/product/${productId}/add`, body);
  }

  public addPhysicalGiftCertificateProduct(orderId: number, productId: number, amount: number, code: string): Observable<Order> {
    let body = {
      code,
      amount
    };
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/${orderId}/product/${productId}/add`, body);
  }


  public addDigitalGiftCertificateProduct(orderId: number, productId: number, amount: number, email: string): Observable<Order> {
    let body = {
      email,
      amount
    };
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/${orderId}/product/${productId}/add`, body);
  }  

  public setProductQuantity(orderId: number, productId: number, quantity: number): Observable<Order> {
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/${orderId}/product/${productId}/set-quantity`, { quantity: quantity });
  }

  public removeProduct(orderId: number, productId: number, productOrderId: number): Observable<Order> {
    return this.httpClient.delete<Order>(`${this.config.apiUrl}/orders/${orderId}/product-order/${productOrderId}/product/${productId}`,);
  }

  public addGolfPass(orderId: number, golfpass: number): Observable<Order> {
    return this.httpClient.post<Order>(`${this.config.apiUrl}/orders/${orderId}/golf-pass/${golfpass}/add`, {});
  }

  public removeGolfPass(orderId: number, golfpass: number): Observable<Order> {
    return this.httpClient.delete<Order>(`${this.config.apiUrl}/orders/${orderId}/golf-pass/${golfpass}`);
  }

  public sendReceipt(orderId: number, email: string = '') {
    return this.httpClient.post<void>(`${this.config.apiUrl}/orders/${orderId}/send-receipt`, { email: email })
  }

  public completeOrder(orderId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.config.apiUrl}/orders/${orderId}/complete-order`, {});
  }


  public completeCurrentOrder(): Observable<OrderDetails> {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/complete`, {});
  }

  public getCurrentOrder(): Observable<OrderDetails> {
    return this.httpClient.get<OrderDetails>(`${this.config.apiUrl}/current-order`, {});
  }

  public cancelCurrentOrder(): Observable<OrderDetails> {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/cancel`, {});
  }

  public getPlayersOnCurrentOrder() {
    return this.httpClient.get<GolfOrderDetails[]>(`${this.config.apiUrl}/current-order/players`);
  }

  public addPlayerToCurrentOrder(email?: string): Observable<OrderDetails> {
    return this.httpClient.post<OrderDetails>(`${this.config.apiUrl}/current-order/players`, { email });
  }

  public updatePlayerOnCurrentOrder(golfOrderId: number, email: string | null) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/players/${golfOrderId}`, { email });
  }

  public removePlayerFromCurrentOrder(golfOrderId: number): Observable<OrderDetails> {
    return this.httpClient.delete<OrderDetails>(`${this.config.apiUrl}/current-order/players/${golfOrderId}`);
  }

  public addProductToCurrentOrder(productId: number): Observable<OrderDetails> {
    return this.httpClient.post<OrderDetails>(`${this.config.apiUrl}/current-order/products/${productId}`, {});
  }

  public setProductQuantityOnCurrentOrder(productId: number, quantity: number): Observable<OrderDetails> {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/products/${productId}`, { quantity: quantity });
  }

  public getProductsOnCurrentOrder() {
    return this.httpClient.get<ProductDetails[]>(`${this.config.apiUrl}/current-order/products`);
  }

  public removeProductFromCurrentOrder(productId: number): Observable<OrderDetails> {
    return this.httpClient.delete<OrderDetails>(`${this.config.apiUrl}/current-order/products/${productId}`);
  }

  public applyPromoCodeToCurrentOrder(promoCode: string): Observable<OrderDetails> {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/promo-code`, { promoCode });
  }

  public removePromoCodeFromCurrentOrder(): Observable<OrderDetails> {
    return this.httpClient.delete<OrderDetails>(`${this.config.apiUrl}/current-order/promo-code`);
  }

  public setCartOnCurrentOrder(cart: boolean) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/cart`, { cart: cart });
  }

  public setHolesOnCurrentOrder(holes: number) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/holes`, { holes: holes });
  }

  public setUseCreditBalanceOnCurrentOrder(useCreditBalance: boolean) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/current-order/use-credit-balance`, { useCreditBalance });
  }

  public adminToggleCreditBalance(orderId: number, useCreditBalance: boolean) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/order/${orderId}/use-credit-balance`, { useCreditBalance });
  }

  public setTipAmount(orderId: number, amount: number, percentageBasedTip: boolean) {
    return this.httpClient.put<OrderDetails>(`${this.config.apiUrl}/order/${orderId}/set-tip-amount`, { amount, percentageBasedTip });
  }

  public getProductOrdersOnCurrentOrder() {
    return this.httpClient.get<Array<ProductOrderDetails>>(`${this.config.apiUrl}/current-order/products`);
  }
}