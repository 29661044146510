import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CONFIG_PATH, CONFIG_TOKEN, SharedModuleConfig, SharedPathConfig } from '../shared.config';
import { Base } from '../models/base';
import { BaseQuery } from '../queries/base-query';
import { PagedResult } from '../queries/paged-result';
import { QueryObj } from '../queries/query';
import { StrapiService } from './strapi.service';
import { SortDirections } from '../enumerations/sort-directions';

@Injectable({
  providedIn: 'root'
})
export abstract class StrapiQueryableService<TBase extends Base, TDetails extends Base, TQuery extends BaseQuery> extends StrapiService<TBase, TDetails>  {

  constructor(@Inject(CONFIG_PATH) protected pathConfig: SharedPathConfig, protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super(pathConfig, httpClient, config);
  }

  public abstract count(query: TQuery): Observable<number>;
  public abstract query(query: TQuery): PagedResult<TDetails>;
  protected abstract buildQuery(query: TQuery): string;

  protected buildBaseQueryObj(query?: TQuery): QueryObj {
    const queryObj: QueryObj = {
      _where: [],
      _sort: "id:ASC",
      _start: 0,
      _limit: 10000
    };

    if (query) {
      if (query.take) {
        queryObj["_limit"] = query.take;
      } else {
        queryObj["_limit"] = 10000; // Default limit of Strapi API.
      }

      if (query.skip) {
        queryObj["_start"] = query.skip;
      } else {
        queryObj["_start"] = 0;
      }

      if (query.sortColumns && query.sortColumns.length > 0) {
        queryObj["_sort"] = query.sortColumns.map(sort => {
          const direction = sort.direction == SortDirections.Descending ? "DESC" : "ASC";
          return `${sort.column}:${direction}`;
        }).join(',');
      }
    }

    return queryObj;
  }
}
