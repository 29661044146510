
<div class="card border-0">
    <div class="card-body">
        <h6 class="card-title text-center text-primary">Payment Methods</h6>
        <ng-container *ngIf="authService.user$ | async as user">
            <gcl-lib-stripe-payment-methods
                [selectedPaymentMethodId]="selectedPaymentMethodId"
                [userId]="user.id"
                (select)="onSelectPaymentMethod($event)"
                (newPayment)="onShowNewPayment()"></gcl-lib-stripe-payment-methods>
        </ng-container>
        
        <button type="button" class="btn btn-primary btn-block" (click)="submitPayment()">Continue</button>
    </div>
</div>