import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySort'
})
export class ArraySortPipe implements PipeTransform {

  transform<T>(value: T[], ...args: string[]): T[] {

    if(typeof args[0] != 'string') {
      return value;
    }

    let propertyName = args[0];

    return value.sort((a: any, b: any) => a[propertyName] > b[propertyName] ? 1 : a[propertyName] < b[propertyName] ? -1 : 0);
    
  }

}
