<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="!isHTML">{{message}}</div>
<div class="modal-body" *ngIf="isHTML" [innerHTML]="message"></div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-light-green " [class]="cancelBtnClass" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-light-green" [class]="acceptBtnClass" (click)="accept()">Yes</button>
</div>