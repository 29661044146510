import { Injectable } from '@angular/core';
import { OrderDetails, OrderPaymentDetails, ProductDetails } from 'projects/shared/src/public-api';
import { PrinterDetails } from 'projects/shared/src/lib/models/printer';
import { User } from 'projects/shared/src/lib/models/user';
import { GolfProductDetails } from 'projects/shared/src/lib/models/golfproduct';
import { ProductService } from './product.service';
import { GolfProductService } from './golf-product.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/admin/src/environments/environment';

declare var epson: any; // quiet down the editor, library is referenced in index.html

@Injectable({
  providedIn: 'root'
})
export class EpsonPrintService {
  activePos: any;

  constructor(
    private productService: ProductService,
    private golfProductService: GolfProductService,
    private toastr: ToastrService,
    private httpClient: HttpClient) {

    }

  // Number of Lines we want to skip per section
  readonly sectionSpaces = 2;

  // According to the tested printer we can print 42 chars on a single line at default font size
  // This number would assumedly halve for double width chars (untested)
  // Also assuming this is tied to your specific printer type
  private getCharLineCount(): number {
    const width = 42;
    return width;
  }

  getUrl = (printerDetails: PrinterDetails): string => {
    return 'https://' + printerDetails.ipAddress.replace(/(^\w+:|^)\/\//, '') + '/cgi-bin/epos/service.cgi?devid=local_printer' + '&timeout=60000';
  }

  printCommand = (url: string, isCanvas?: boolean) => {
    // create print object
    const epos = isCanvas ? new epson.CanvasPrint(url) : new epson.ePOSPrint(url);
    this.activePos = epos;
    // register callback function
    epos.onreceive = this.onReceive.bind(this);

    // register callback function
    epos.onerror = this.onError.bind(this);

    return epos;
  }

  private onReceive(res: any): void {
    this.toastr.success('Printed Receipt');
  }

  private onError(err: any): void {
    this.toastr.error('Error Printing Receipt');
  }

  // -- Formating -- //

  // Changes Text Size
  private toggleTextSize(builder: any, enable: boolean): void {
    const reverse = undefined;
    const ul = undefined;
    const em = true;
    const color = undefined;
    if (enable) {
      builder.addTextStyle(reverse,  ul, em, color);
      // builder.addTextSize(2, 2);
    } else {
      builder.addTextStyle(undefined,  undefined, false, undefined);
      // builder.addTextSize(1, 1);
    }
  }

  // Creates a string given a left/right aligned string with spaces between the two...
  // in order to create a string that spans the entire row.
  // This is needed as we are not allowed to right align after the row has partially been written to.
  private createLineString(leftAlignedString: string, rightAlignedString: string): string {
    const width = this.getCharLineCount();

    // Check to see if the left/right aligned strings are going to go over the char limit for the row
    if (leftAlignedString.length + rightAlignedString.length + 1 > width) {
      const newLength = width - (rightAlignedString.length + 1);
      leftAlignedString = `${leftAlignedString.substring(0, newLength - 3)}...`;
    }

    const blankLength = width - leftAlignedString.length - rightAlignedString.length;

    let blankStr = '';
    for (let i = 0; i < blankLength; i++) {
      blankStr += ' ';
    }

    return `${leftAlignedString}${blankStr}${rightAlignedString}`;
  }

  // Currency Format Method
  private getCurrencyString(value: number): string {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol'}).format(value);
  }

  // Attempts to add the insertString into the center of the rowString and returns the new string
  private addStringToCenter(rowString: string, insertString: string): string {
    const lineStringArr = [...rowString];

    // Alter the middle chars to add the quantity
    // Create a cursor at the middle of the string
    const startIndex = Math.trunc(lineStringArr.length * .75);

    for (let i = 0; i < lineStringArr.length / 4; i++) {
      let canWrite = true;

      // Try going to the right
      for (let j = 0; j < insertString.length; j++) {
        // Check to see if the 3 chars starting at startIndex -> are all ' '
        // Also make sure that the character before the initial placement is ' '
        if (lineStringArr[startIndex + i + j] !== ' ' ||
            lineStringArr[startIndex + i + j - 1] !== ' ') {
          canWrite = false;
        }
      }

      if (canWrite) {
        for (let j = 0; j < insertString.length; j++) {
          lineStringArr[startIndex + i + j] = insertString[j];
        }
      } else {
        // Try going to the left
        for (let j = insertString.length - 1; j >= 0; j--) {
          // Check to see if the 3 chars starting at startIndex <- are all ' '
        // Also make sure that the character before the initial placement is ' '
          if (lineStringArr[startIndex - i - j] !== ' ' ||
              lineStringArr[startIndex - i - j - 1] !== ' ') {
            canWrite = false;
          }
        }

        if (canWrite) {
          for (let j = insertString.length - 1; j >= 0; j--) {
            lineStringArr[startIndex - i - j] = insertString[j];
          }
        }
      }

      // If we wrote then we are done
      if (canWrite) {
        return lineStringArr.join('');
      }
    }

    // We didn't insert return what we had
    return rowString;
  }

  // -- Row Writes -- //

  // Adds a new row for {Header}
  private addHeaderLine(builder: any, header: string, bold: boolean = true): void {
    this.toggleTextSize(builder, bold);
    this.addText(builder, header);
    this.toggleTextSize(builder, false);
    this.addFeedLine(builder);
  }

  // Adds a new row for {Header} {Value}
  private addParameterAndValue(builder: any, header: string, value: string): void {
    this.addText(builder, this.createLineString(header, value));
    this.addFeedLine(builder);
  }

  // Adds a new row for {Header} x{Quantity} ${value}
  private addQuantityProduct(builder: any,  header: string, quantity: number, value: number): void {
    const rowString = this.addStringToCenter(
      this.createLineString(header, this.getCurrencyString(value)),
      `x${quantity}`);

    this.addText(builder, rowString);
    this.addFeedLine(builder);
  }

  // Creates Blank Row(s)
  private addFeedLine(builder: any, numberOfLines: number = 1): void {
    builder.addFeedLine(numberOfLines);
  }

  // Command to write any text (not always a row)
  private addText(builder: any, text: string): void {
    builder.addText(text);
  }

  /// -- Section Builders -- //

  private buildHeader(builder: any, orderDetails: OrderDetails): void {
    // Add Payment Order Details
    this.addHeaderLine(builder, `Payment Receipt for Order #${ orderDetails.id }`, false);
    // Add Space Between Sections
    this.addFeedLine(builder, this.sectionSpaces);
  }

  private buildGolfProducts(builder: any, orderDetails: OrderDetails, golfProductDetails: GolfProductDetails[]): void {
    // Add 'Golf Products' Section
    if (orderDetails.golforders.length > 0) {
      // Write Header
      this.addHeaderLine(builder, 'Golf Products');
      for (const golfOrder of orderDetails.golforders) {
        // Get the Golf Product
        const productDetail = golfProductDetails.find(f => f.id === golfOrder.golfproduct);
        if (productDetail) {
          this.addQuantityProduct(builder, productDetail.name, golfOrder.quantity, golfOrder.extprice);
        } else {
          console.log(`Product not found for ${ golfOrder }`);
        }
      }
      // Add Space Between Sections
      this.addFeedLine(builder, this.sectionSpaces);
    }
  }

  private buildAddOnProducts(builder: any,  orderDetails: OrderDetails, productDetails: ProductDetails[]): void {
    // Add 'Add-On Products' Section
    if (orderDetails.productorders.length > 0) {
      // Write Header
      this.addHeaderLine(builder, 'Add-On Products');
      for (const productOrder of orderDetails.productorders) {
        // Get the Product
        const productDetail = productDetails.find(f => f.id === productOrder.product);
        if (productDetail) {
          this.addQuantityProduct(builder, productDetail.name, productOrder.quantity, productOrder.extprice);
        } else {
          console.log(`Product not found for ${ productOrder }`);
        }
      }
      // Add Space Between Sections
      this.addFeedLine(builder, this.sectionSpaces);
    }
  }

  private buildOrderDetails(builder: any, user: User|undefined, orderDetails: OrderDetails): void {
    // Add 'Order Details' Section
    this.addHeaderLine(builder, 'Order Details');
    this.addParameterAndValue(builder, 'User Name:', (user?.firstName && user?.lastName) ? `${user?.firstName} ${user?.lastName}` : 'N/A');
    this.addParameterAndValue(builder, 'Course:', orderDetails.course?.name ?? 'N/A');
    this.addParameterAndValue(builder, 'Subtotal:', this.getCurrencyString(orderDetails.subtotal));
    this.addParameterAndValue(builder, 'Tax:', this.getCurrencyString(orderDetails.tax));
    this.addParameterAndValue(builder, 'Final Total:', this.getCurrencyString(orderDetails.finaltotal));
    // Add Space Between Sections
    this.addFeedLine(builder, this.sectionSpaces);
  }

  // -- Builder Format Setup Methods -- //

  private setupBuilder(builder: any): void {
    // paper layout
    if (true) { // layout
      builder.addLayout(builder.LAYOUT_RECEIPT);
      // builder.addLayout(builder.LAYOUT_LABEL, 580, 0, 15, -15, 25, 0);
    }

    // initialize (alphanumeic mode, smoothing)
    builder.addTextLang('en');
    builder.addTextSmooth(1);

    // paper control
    builder.addFeedPosition(builder.FEED_CURRENT_TOF);

    // paper control for first print
    if (true) { // layout
      builder.addFeedPosition(builder.FEED_NEXT_TOF);
    }

    // // start page mode
    // builder.addPageBegin();

    // // format
    // builder.addPageArea(0, 0, 384, 160);

    return builder;
  }

  private finishBuilder(builder: any, printerDetails: PrinterDetails): void {
    // paper control
    builder.addFeedPosition(builder.FEED_PEELING);

    // add cut to the receipt
    builder.addCut();

    // create print object
    const epos = this.printCommand(this.getUrl(printerDetails));

    // send
    epos.send(builder.toString());
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode( bytes[i] );
    }
    return window.btoa( binary );
  }

  private buildWithLogo(builder: any,
                        logoUrl: string,
                        printerDetails: PrinterDetails,
                        orderDetails: OrderDetails,
                        golfProductDetails: GolfProductDetails[],
                        productDetails: ProductDetails[]): void {
    this.httpClient.get(logoUrl, {
      responseType: 'arraybuffer'
    }).subscribe(response => {
      const width = 200;
      const height = 200;

      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      canvas.width = width;
      canvas.height = height;
      document.body.appendChild(canvas);
      const context = canvas.getContext('2d');
      const image = new Image();
      image.src = 'data:image/bmp;base64,' + this.arrayBufferToBase64(response);
      image.onload = () => {
        context?.drawImage(image, 0, 0, width, height);
        builder.addTextAlign(builder.ALIGN_CENTER);
        builder.addImage(context, 0, 0, canvas.width, canvas.height, builder.COLOR_1); // , builder.MODE_GRAY16);
        builder.addTextAlign(builder.ALIGN_LEFT);
        this.addFeedLine(builder, this.sectionSpaces);

        this.build(builder,
              printerDetails,
              orderDetails,
              golfProductDetails,
              productDetails);
      };
    });
  }

  private build(builder: any,
                printerDetails: PrinterDetails,
                orderDetails: OrderDetails,
                golfProductDetails: GolfProductDetails[],
                productDetails: ProductDetails[]): void {
    // 1
    this.buildHeader(builder, orderDetails);
    // 2
    this.buildGolfProducts(builder, orderDetails, golfProductDetails);
    // 3
    this.buildAddOnProducts(builder, orderDetails, productDetails);
    // 4
    this.buildOrderDetails(builder, orderDetails.users_permissions_user, orderDetails);

    // Make final updates to finish and print object
    this.finishBuilder(builder, printerDetails);
  }

  // Main Entry
  public printReceipt(printerDetails: PrinterDetails, orderDetails: OrderDetails): void {
    this.productService.getAll().subscribe((productDetails) => {
      this.golfProductService.getAll().subscribe((golfProductDetails) => {
        // Create Epson Printer Object
        const builder = new epson.ePOSBuilder();

        // Setup
        this.setupBuilder(builder);

        const logoUrl = orderDetails.course?.logo?.url;
        if (logoUrl) {
          this.buildWithLogo( builder,
                              `${environment.apiUrl}${logoUrl}`,
                              printerDetails,
                              orderDetails,
                              golfProductDetails,
                              productDetails);
        } else {
          this.build( builder,
                      printerDetails,
                      orderDetails,
                      golfProductDetails,
                      productDetails);
        }
      });
    });
  }
}
