import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { PaymentMethod } from '@stripe/stripe-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { StripePaymentToken } from 'projects/shared/src/lib/models/stripe-payment-token';
import { StripeCreateCardComponent } from 'projects/shared/src/lib/stripe/stripe-create-card/stripe-create-card.component';
import { StripeEditCardComponent } from 'projects/shared/src/lib/stripe/stripe-edit-card/stripe-edit-card.component';
import { GiftCertificateDetails, UserDetails } from 'projects/shared/src/public-api';
import { AccountManager } from '../../../account.manager';
import { GiftCertificateService } from 'projects/shared/src/lib/services/gift-certificate.service';
import { AddGiftCertificateModalComponent } from 'projects/user/src/app/shared/components/add-gift-certificate-modal/add-gift-certificate-modal.component';

@Component({
  selector: 'gcl-user-user-payment-methods',
  templateUrl: './user-payment-methods.component.html',
  styleUrls: ['./user-payment-methods.component.scss']
})
export class UserPaymentMethodsComponent implements OnInit {
  public faPlus = faPlus;

  public user$!: Observable<UserDetails>;
  public paymentModalRef!: BsModalRef;
  public giftCardModalRef!: BsModalRef;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private accountManager: AccountManager,
    private giftCertificateService: GiftCertificateService,
    private stripeService: StripeService,
    private modalService: BsModalService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.user$ = this.accountManager.user$;
  }

  public onSelectPayment(paymentMethod: PaymentMethod): void {
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.paymentModalRef = this.modalService.show(StripeEditCardComponent, {
          ignoreBackdropClick: true,
          class: 'modal-lg',
          initialState: {
            userId: user.id,
            paymentMethod: paymentMethod,
            stripe: this.stripeService.createInstance(),
          }
        });

        this.paymentModalRef.content.cancel
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe(() => this.paymentModalRef.hide());

        this.paymentModalRef.content.submit
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe((payment: StripePaymentToken) => {
            if (!payment.error) {
              const status = payment.deleted ? "Deleted" : "Updated";
              this.toastrService.success(`Payment ${status}`);

              this.stripeService.reloadPaymentList$.next(user.id);
              this.paymentModalRef.hide();
            } else {
              this.toastrService.error(payment.error);
            }
          });
      });
  }

  public onAddPayment(): void {
    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.paymentModalRef = this.modalService.show(StripeCreateCardComponent, {
          ignoreBackdropClick: true,
          class: 'modal-lg',
          initialState: {
            userId: user.id,
            stripe: this.stripeService.createInstance(),
            forceSave: true
          }
        });

        this.paymentModalRef.content.cancel
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe(() => this.paymentModalRef.hide());

        this.paymentModalRef.content.submit
          .pipe(
            takeUntil(this.destroy$)
          )
          .subscribe((payment: StripePaymentToken) => {
            if (!payment.error) {
              this.toastrService.success("Payment Added");

              this.stripeService.reloadPaymentList$.next(user.id);
              this.paymentModalRef.hide();
            } else {
              this.toastrService.error(payment.error);
            }
          });
      });
  }

  public openAddGiftCard(): void {
    this.giftCardModalRef = this.modalService.show(AddGiftCertificateModalComponent, {
      ignoreBackdropClick: true,
    });

    this.giftCardModalRef.content.cancel
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.giftCardModalRef.hide());

    this.giftCardModalRef.content.submit
      .pipe(
        switchMap((cardNumber: string) => this.giftCertificateService.appDebit(cardNumber)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (certificate: GiftCertificateDetails) => {
          this.accountManager.reloadCreditBalance();

          this.toastrService.success(`Gift Certificate Added successfully.`);
          this.giftCardModalRef.hide();
        },
        (error: any) => this.toastrService.error(error));
  }
}
