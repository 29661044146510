<gcl-lib-two-pane-horizontal-resizable [width]="'50%'">
		<div slot="left" class="h-100 d-flex flex-column">
			<ng-container *ngTemplateOutlet="left"></ng-container>
		</div>
		<div slot="right">
			<ng-container *ngTemplateOutlet="right"></ng-container>
		</div>
</gcl-lib-two-pane-horizontal-resizable>

<!-- Templates -->
<ng-template #left>
	<h3 class="card-title text-center text-primary">Add Tee Time</h3>
	<div class="container-fluid">
		<form [formGroup]="form">
			<div class="form-group">
				<gcl-user-row-selection-option
					[label] = "'Set Number of Golfers'"
					[image] = "'assets/golf-user-icon.svg'"
					(click)="showGolfPlayerSelection()">
				</gcl-user-row-selection-option>
			</div>
			<div class="form-group">
				<div class="row border-bottom py-3">
					<div class="col-12 col-sm-6 col-md-12 col-lg-6">
						<label for="isEightteenHoles">Select Course Length</label>
					</div>
					<div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-5 ml-auto">
						<gcl-lib-text-toggle-switch formControlName="isEightteenHoles"
							[leftText]="'9 Holes'"
							[rightText]="'18 Holes'"></gcl-lib-text-toggle-switch>
						<div *ngIf="isEightteenHoles?.invalid && addCart?.touched" class="invalid-feedback">
							<div *ngIf="isEightteenHoles?.errors?.required">
								Course Length is required.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label for="addCart">Cart</label>
				<gcl-lib-toggle-switch class="float-right" formControlName="addCart"></gcl-lib-toggle-switch>
				<div *ngIf="addCart?.invalid && addCart?.touched" class="invalid-feedback">
					<div *ngIf="addCart?.errors?.required">
						Cart is required.
					</div>
				</div>
			</div>
		</form>
		<div class="row border-top border-bottom py-3 cursor">
			<div class="col-12">
				<h6 class="m-0" (click)="goToSelectCourse()">
					<img class="img-fluid pr-4" alt="Select a Course" src="assets/golf.svg"/> Select a Course 
					<span class="float-right" [style.padding-top]="'12px'">
						<fa-icon size="lg" [icon]="faChevronRight"></fa-icon>
					</span>
				</h6>
			</div>
		</div>
		<div class="row border-bottom py-3 cursor">
			<div class="col-12">
				<h6 class="m-0" (click)="goToSelectDateTime()">
					<img class="img-fluid pr-4" alt="Select a Time" src="assets/calendar.svg"/> Select a Time 
					<span class="float-right" [style.padding-top]="'12px'"><fa-icon size="lg" [icon]="faChevronRight"></fa-icon></span>
				</h6>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #right>
	<!-- Select Golfers -->
	<div class="golfers container-fluid" *ngIf="showGolfPlayerForm">
		<form [formGroup]="form">
			<div class="row">
				<h3 class="text-center text-primary w-100 pb-4">Select Golfers</h3>
			</div>

			<div class="row pb-4">
				<div class="col-12 col-sm-6 col-md-12 col-lg-6">
					<label for="numOfGolfers">Number Of Golfers</label>
				</div>
				<div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-5 ml-auto border rounded-pill">
					<gcl-lib-counter class="ml-auto" formControlName="numOfGolfers" [bordered]="false"
						[min]="1"
						[max]="4"
						[style.width]="'150px'"
						[decrementCallback]="theOpenRemovePlayerModal">
					</gcl-lib-counter>
					<div *ngIf="numOfGolfers?.invalid && numOfGolfers?.touched" class="invalid-feedback">
						<div *ngIf="numOfGolfers?.errors?.required">
							Number of Golfers required.
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12 pb-2" *ngIf="user$ | async as user">
					<h6>Reserving Player: {{user.email}}</h6>
				</div>
			</div>
			<ng-container formArrayName="players">
				<ng-container *ngFor="let playerEmail of players.controls; let index = index">
	
					<div class="row">
						<div class="col-12">
							<div class="form-group" [formGroupName]="index">
								<div class="input-group">
									<input id="player" name="Player Email"  title="Player Email" type="email" formControlName="email" [placeholder]="'Enter Guest Player ' + (index+1) + ' Email'" class="form-control"  
										[class.is-invalid]="getPlayerEmailFormControl(index)?.invalid && getPlayerEmailFormControl(index)?.touched" />
									<div class="input-group-append cursor" (click)="clearPlayerEmail(index)">
										<span class="input-group-text bg-danger text-white" id="clearPlayerEmail">
											<fa-icon size="lg" [icon]="faTrash"></fa-icon>
										</span>
									</div>
									<div *ngIf="getPlayerEmailFormControl(index)?.invalid && getPlayerEmailFormControl(index)?.touched" class="invalid-feedback">
										<div *ngIf="getPlayerEmailFormControl(index)?.errors?.required">
											Please enter an email.
										</div>
										<div *ngIf="getPlayerEmailFormControl(index)?.errors?.equalTo">
											Email can't be set to {{getPlayerEmailFormControl(index)?.errors?.equalTo}}.
										</div>
										<div *ngIf="getPlayerEmailFormControl(index)?.errors?.equalToFields">
											<p>Email can't be set to:</p>
											<ul>
												<li *ngFor="let index of getPlayerEmailFormControl(index)?.errors?.equalToFields">{{getPlayerEmailFormControl(index)?.value}}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
	
				</ng-container>
			</ng-container>

		</form>
	</div>

	<!-- Summary -->
	<div class="summary" *ngIf="!showGolfPlayerForm">
		<h4 class="fw-bold pt-5" *ngIf="state.round$ | async as round">
			{{getDateTime(round?.datestr, round.starttod) | date:"fullDate"}} {{getDateTime(round.datestr, round.starttod) | date:"shortTime"}}
		</h4>
		<h4 class="fw-light" *ngIf="state.course$ | async as course" class="pb-4">
			<small class="text-muted">{{state.course$?.value?.full_name}}</small>
		</h4>
	
		<h5>Particulars:</h5>
		<ng-container *ngIf="state.addTeeTime">
			<p>People in Party: {{state.addTeeTime.numOfGolfers}}</p>
			<p>Course: {{state.addTeeTime.numOfHoles}} Holes</p>
			<p>Include Cart: {{state.addTeeTime.addCart ? 'Yes' : 'No'}}</p>
		</ng-container>

		<ng-container>
			<p *ngIf="user$ | async as user">Reserving Player: {{user.email}}</p>
			<ul class="list-group">
				<li class="list-group-item" *ngFor="let player of players.controls">{{player.value?.email || "Guest Player"}}</li>
			</ul>
		</ng-container>
	</div>

</ng-template>

<div bsModal #deletePlayerModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h4 id="dialog-child-name" class="modal-title float-left">Remove Player</h4>
				<button type="button" class="close float-right btn-close" aria-label="Close" (click)="hideRemovePlayerModal()">
					<span class="modal-close" aria-hidden="true" class="visually-hidden">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<form [formGroup]="form">
					<div class="container-fluid">
						<ng-container formArrayName="players">
							<ng-container *ngFor="let playerEmail of players.controls; let index = index">
				
								<div class="row">
									<div class="col-12">
										<div class="border p-2">
											<div class="d-flex justify-content-between">
												<p id="player" class="ml-2 mt-2">{{getPlayerEmail(playerEmail)}}</p>
												<div class="input-group-append cursor" (click)="removePlayer(index)">
													<span class="input-group-text bg-danger text-white" id="basic-addon1">
														<fa-icon size="lg" [icon]="faTrash"></fa-icon>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
				
							</ng-container>
						</ng-container>
					</div>
				</form>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary float-right" aria-label="Close" (click)="hideRemovePlayerModal()">
					<span aria-hidden="true" class="visually-hidden">Close</span>
				</button>
			</div>
		</div>
	</div>
</div>
