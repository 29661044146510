import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, concat } from 'rxjs';
import { map, shareReplay, take, takeUntil, toArray } from 'rxjs/operators';

import { TeeTimeManager, AddTeeTimeState } from '../../tee-time.manager';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { RoundService } from 'projects/shared/src/lib/services/round.service';
import { RoundDetails } from 'projects/shared/src/lib/models/round';
import { RoundStatuses } from 'projects/shared/src/lib/enumerations/round-status';
import { environment } from '../../../../environments/environment';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from '../../../shared/components/login-modal/login-modal.component';

@Component({
  selector: 'gcl-user-select-tee-time',
  templateUrl: './select-tee-time.component.html',
  styleUrls: ['./select-tee-time.component.scss']
})
export class SelectTeeTimeComponent implements OnInit, OnDestroy {
  public getDateTime = getDateTime;
  public apiUrl: string = environment.apiUrl;

  public startDate: Date = new Date();
  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  rounds$: ReplaySubject<RoundDetails[]> = new ReplaySubject(1);
  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'starttod',
    sortDescending: false
  });
  onPageReset$: Subject<any> = new Subject<any>();

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public state: TeeTimeManager,
    private roundService: RoundService,
    private toastr: ToastrService,
    public authService: AuthService,
    public bsModalService: BsModalService,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public onSelectDate(date: Date) {
    const currentDateTime = new Date();
    const currentDateOnly = currentDateTime.toDateString();
    this.startDate = date;

    // only take into account the time difference if the same date
    if ((new Date(currentDateOnly)).compareDate(date) == 0) {
      this.startDate.setHours(currentDateTime.getHours());
      this.startDate.setMinutes(currentDateTime.getMinutes());
      this.startDate.setSeconds(currentDateTime.getSeconds());
      this.startDate.setMilliseconds(currentDateTime.getMilliseconds());
    }

    this.getPagedResult();
  }

  public getPagedResult() {
    const courseId = this.state.course$.value?.id;
    const holes = this.state.addTeeTime.numOfHoles;
    const date = this.startDate.toISODate();
    const start = this.startDate.getTimeString(false);
    const cart = this.state.addTeeTime.addCart;

    const pagedResult = this.roundService.getRoundsByDate(date, start, undefined, holes, courseId, cart);
    pagedResult.pipe(takeUntil(this.destroyed$)).subscribe((total) => this.total$.next(total.length));
    pagedResult.pipe(takeUntil(this.destroyed$)).subscribe((records) => this.rounds$.next(records));
  }

  public getRoundPrice(round: RoundDetails): number {
    const golfProduct = round.golfproduct;
    if (golfProduct) {
      return golfProduct.price;
    }
    return 0;
  }

  public async onSelectRound(round: RoundDetails) {
    let user = null;

    try {
      user = await this.authService.user$.pipe(take(1)).toPromise();
    }
    catch(err) {

    }

    if(!user) {
      let loginModalRef = this.bsModalService.show(LoginModalComponent, {
        class: 'modal-lg modal-dialog-centered',
        ignoreBackdropClick: true,         
      });

      await this.authService.onExternalLogin$.pipe(take(1)).toPromise();

      let tasks: Observable<any>[] = [];
      this.state.addTeeTime.golfers.filter(pl => !!pl.email).forEach(player => {
        tasks.push(this.state.addPlayer(player.email));
      });
  
      await concat(...tasks).pipe(toArray()).toPromise();

      await this.state.setCart(this.state.addTeeTime.addCart).pipe(take(1)).toPromise();
      await this.state.setHoles(this.state.addTeeTime.numOfHoles).pipe(take(1)).toPromise();  
    }
    
    this.state.bookTheRound(round.id)
    .pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (_round: RoundDetails) => {
        this.state.step$.next(AddTeeTimeState.AddToCart);
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }
}
