<div class="py-4">
    <gcl-lib-two-pane-horizontal-resizable [width]="'50%'" class="d-none d-lg-block">
        <div slot="left" class="p-3 h-100 d-flex flex-column">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div  class="p-3" slot="right">
            <ng-container *ngTemplateOutlet="right"></ng-container>
        </div>
    </gcl-lib-two-pane-horizontal-resizable>
    
    <!-- Mobile -->
    <div class="container-fluid">
        <div class="row d-lg-none">
            <div  class="col-12 py-3">
                <ng-container *ngTemplateOutlet="left"></ng-container>
            </div>
            <div class="col-12">
                <div class="container">
                    <ng-container *ngTemplateOutlet="right"></ng-container>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Templates -->
    <ng-template #left>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h5 class="card-title text-center text-primary pb-4">Login To Buy A Golf Pass</h5>
                    <div>
                        <gcl-lib-login (onLogin)="goRegisterPage()"></gcl-lib-login>
                        <div class="d-flex justify-content-between">
                            <a class="text-default" [routerLink]="['/forgot-password']">Forgot password?</a>
                            <a class="text-default" [routerLink]="['/registeration']">Create account</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    
    <ng-template #right>
        <ng-container *ngIf="golfpassId$ | async as golfpassId">
            <gcl-user-golf-pass-guidlines [golfpassId]="golfpassId"></gcl-user-golf-pass-guidlines>
        </ng-container>
    </ng-template>
</div>