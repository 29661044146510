import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { UserDetails } from 'projects/shared/src/public-api';
import { AccountManager } from '../../../account.manager';

@Component({
  selector: 'gcl-user-account-edit-user',
  templateUrl: './account-edit-user.component.html',
  styleUrls: ['./account-edit-user.component.scss']
})
export class AccountEditUserComponent implements OnInit {

  constructor(private accountManager: AccountManager, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  goToView(user: UserDetails): void {
    this.toastr.success("Personal Info updated successfully.");
    this.accountManager.user$.next(user);
  }

}
