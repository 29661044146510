<div class="container py-4">
    <div class="row">
        <div class="col-12">
            <div class="card my-2"  *ngIf="user$ | async as user">
                <div class="card-body">
                    <h3 class="card-title text-center text-primary">Payment Methods</h3>
                    <gcl-lib-stripe-payment-methods
                        [userId]="user.id"
                        (newPayment)="onAddPayment()"></gcl-lib-stripe-payment-methods>
                    
                    <div class="text-center">
                        <button title="Add Gift Card" class="btn btn-outline-primary rounded-pill" type="button" 
                            (click)="openAddGiftCard()">
                            <fa-icon [icon]="faPlus"></fa-icon>
                            Add Gift Card(s)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>