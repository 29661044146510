<h6 *ngIf="step$ | async as step" class="pb-4 mx-2">
    <a class="btn btn-link text-dark pr-1 pl-1 " [routerLink]="['/']">Dashboard</a>
    <span>&nbsp;/&nbsp;</span>

    <button class="btn btn-link pr-1 pl-1 " 
        [class.pointer]="state.step$.value != AddTeeTimeState.AddTeeTime" 
        [class.text-info]="state.step$.value == AddTeeTimeState.AddTeeTime"
        [class.text-dark]="state.step$.value != AddTeeTimeState.AddTeeTime"
        (click)="goToStep(AddTeeTimeState.AddTeeTime)">Add a Tee Time</button>
    <span *ngIf="state.step$.value == AddTeeTimeState.SelectByCourse ||  state.step$.value == AddTeeTimeState.SelectByTime || state.step$.value == AddTeeTimeState.SelectTeeTime" >&nbsp;/&nbsp;</span>

    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.SelectByCourse && state.step$.value != AddTeeTimeState.SelectByTime" 
        [class.text-info]="state.step$.value == AddTeeTimeState.SelectByCourse || state.step$.value == AddTeeTimeState.SelectByTime"
        [class.text-dark]="state.step$.value != AddTeeTimeState.SelectByCourse && state.step$.value != AddTeeTimeState.SelectByTime"
        (click)="goToStep(AddTeeTimeState.SelectByCourse)" 
        *ngIf="(step == AddTeeTimeState.SelectByCourse || step == AddTeeTimeState.SelectTeeTime) || (step == AddTeeTimeState.SelectByTime)">Select A Course</button>
    <span *ngIf="(state.step$.value == AddTeeTimeState.SelectTeeTime)">&nbsp;/&nbsp;</span>

    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.SelectTeeTime" 
        [class.text-info]="state.step$.value == AddTeeTimeState.SelectTeeTime"
        [class.text-dark]="state.step$.value != AddTeeTimeState.SelectTeeTime"
        *ngIf="(step == AddTeeTimeState.SelectTeeTime)">{{state.course$?.value?.full_name}}</button>
    <span *ngIf="(state.step$.value >= AddTeeTimeState.AddToCart)">&nbsp;/&nbsp;</span>

    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.AddToCart" 
        [class.text-info]="state.step$.value == AddTeeTimeState.AddToCart"
        [class.text-dark]="state.step$.value != AddTeeTimeState.AddToCart"
        (click)="goToStep(AddTeeTimeState.AddToCart)" *ngIf="step == AddTeeTimeState.AddToCart">Add To Cart</button>
    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.AddProduct" 
        [class.text-info]="state.step$.value == AddTeeTimeState.AddProduct"
        [class.text-dark]="state.step$.value != AddTeeTimeState.AddProduct"
        (click)="goToStep(AddTeeTimeState.AddProduct)" *ngIf="step == AddTeeTimeState.AddProduct">Add Product</button>
    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.Checkout" 
        [class.text-info]="state.step$.value == AddTeeTimeState.Checkout"
        [class.text-dark]="state.step$.value != AddTeeTimeState.Checkout"
        (click)="goToStep(AddTeeTimeState.Checkout)" *ngIf="step == AddTeeTimeState.Checkout">Checkout</button>
    <button class="btn btn-link pr-1 pl-1 "  
        [class.pointer]="state.step$.value != AddTeeTimeState.OrderConfirmation" 
        [class.text-info]="state.step$.value == AddTeeTimeState.OrderConfirmation"
        [class.text-dark]="state.step$.value != AddTeeTimeState.OrderConfirmation"
        (click)="goToStep(AddTeeTimeState.OrderConfirmation)" *ngIf="step == AddTeeTimeState.OrderConfirmation">Order Confirmation</button>
</h6>