<form [formGroup]="loginForm" (ngSubmit)="submit()">
    <small class="text-danger d-block mb-2" *ngIf="loginForm.errors?.invalidPassword">
        Invalid email address or password. Please try again.
    </small>
    <div class="form-group">
        <label for="email">Email</label>
        <input id="email" type="email" formControlName="email" placeholder="Email Address" class="form-control"
            [class.is-invalid]="email.touched && email.invalid" />
        <div *ngIf="email?.invalid && email?.touched" class="invalid-feedback">
            <div *ngIf="email?.errors?.required">
                Please enter an email address to login.
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">Password</label>
        <input id="password" type="password" formControlName="password" placeholder="Password" class="form-control"
            [class.is-invalid]="password.touched && password.invalid" />
        <div *ngIf="password?.invalid && password?.touched" class="invalid-feedback">
            <div *ngIf="password?.errors?.required">
                Please enter a password to login.
            </div>
        </div>
    </div>
    <!-- <div class="form-check">
        <input id="rememberMe" type="checkbox" formControlName="rememberMe" class="form-check-input" />
        <label class="form-check-label" for="rememberMe">
            Remember Me
        </label>
    </div> -->
    <br />
    <button type="submit" class="btn btn-primary btn-block" [disabled]="loginForm.invalid">Login</button>
</form>