import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { UserDetails } from 'projects/shared/src/public-api';

@Component({
  selector: 'gcl-user-user-breadcrumb',
  templateUrl: './user-breadcrumb.component.html',
  styleUrls: ['./user-breadcrumb.component.scss']
})
export class UserBreadcrumbComponent implements OnInit {

  public user$!: Observable<UserDetails>;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.user$ = this.authService.user$
      .pipe(
        shareReplay(1)
      );
  }

}
