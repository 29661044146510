<div class="input-group">
    <div class="input-group-prepend">
        <button class="btn btn-outline-primary" [class.border-0]="!bordered" type="button" (click)="decrement()">
            <fa-icon class="text-info" [icon]="faMinus"></fa-icon>
        </button>
    </div>
    <button class="btn btn-primary rounded-pill mx-2 " type="button" [style.width]="'180px'" (click)="onClick()">
        {{ltext}}{{vtext}}{{rtext}}
    </button>
    <div class="input-group-append">
        <button class="btn btn-outline-primary" [class.border-0]="!bordered" type="button" (click)="increment()">
        <fa-icon class="text-info" [icon]="faPlus"></fa-icon>
        </button>
    </div>
</div>