import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { PrinterDetails } from '../../models/printer';
import { PrinterService } from '../../services/printer.service';
import { CourseService } from '../../services/course.service';

@Component({
  selector: 'gcl-lib-add-printer',
  templateUrl: './add-printer.component.html',
  styleUrls: ['./add-printer.component.css']
})
export class AddPrinterComponent implements OnInit {

  courseId?: number;
  form: FormGroup;

  private destroyed$ = new Subject();

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private toastr: ToastrService, private printerService: PrinterService, private courseService: CourseService) {
    this.form = fb.group({
      printerDomainName: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  save() {
    if (this.courseId) {
      try {
        this.printerService.create({
          ipAddress: this.form.value.printerDomainName,
          name: this.form.value.name,
          course: this.courseId
        })
          .pipe(takeUntil(this.destroyed$))
          .subscribe((printerDetails: PrinterDetails) => {
            this.bsModalRef.hide();
          });
      } catch (err) {
        this.toastr.error('Unable to add printer.');
      }
    }
  }
}
