<div>
    <ng-content></ng-content>

    <div class="d-flex"
        [ngClass]="{'justify-content-left': position == 'left', 'justify-content-center': position == 'center', 'justify-content-right': position == 'right'}">
        <form [formGroup]="form">
            <ng-container *ngIf="totalItems">
                <pagination formControlName="page" [totalItems]="totalItems" [itemsPerPage]="form.value!.itemsPerPage" [maxSize]="5" [directionLinks]="itemsPerPage >= totalItems ? false: true"></pagination>
            </ng-container>
        </form>
    </div>
</div>