<div class="container-fluid">
    <div class="row py-4">
        <div class="col-12 col-lg-7">
            <h3 class="card-title text-center text-primary pt-5">Scheduled Tee Times</h3>
            <div class="mb-5">
                <div class="text-center text-sm-right mb-3 mb-3">
                    <a [routerLink]="['/tee-time']" class="btn btn-primary rounded-pill px-5">Add Tee Time</a>
                </div>
                <div class="text-center text-sm-right mb-3 mb-3">
                    <a [routerLink]="['/golf-passes']" class="btn btn-primary rounded-pill px-5">Golf Passes</a>
                </div>
                <div style="overflow: auto; max-height: 400px;">
                    <div class="list-group pb-4">
                        <div class="list-group-item" *ngFor="let record of records$ | async">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div>{{getDateTime(record?.datestr, record?.starttod) | date:"fullDate"}} {{getDateTime(record?.datestr, record?.starttod) | date:"shortTime"}}</div>
                                    <div class="font-weight-light">{{record.course?.full_name}}</div>
                                </div>
                                <div>
                                    <button class="btn btn-outline-danger" type="button" (click)="confirmcancelOrder(record)">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item" *ngIf="(total$ | async) == 0">
                            <p>No Scheduled Tee Times.</p>
                        </div>
                    </div>
                </div>
    
            </div>
            <!-- hiding my Courses for now
            <h5 class="text-center text-primary">My Courses</h5>
            <div class="d-flex justify-content-between align-items-center">
                <h6>Limit Available Courses</h6>
                <gcl-lib-toggle-switch (toggle)="toggle($event)"></gcl-lib-toggle-switch>
            </div>
            <p class="text-muted">When turned on this will limit available Tee Times to only show your courses.</p>
            <div class="mb-5">
                <accordion [closeOthers]="true">
                    <accordion-group *ngFor="let course of courses$ | async">
                        <div class="bg-light d-flex justify-content-between align-items-center" accordion-heading>
                            <div>
                                <div>{{course.full_name}}</div>
                                <div class="font-weight-light">{{course.address}}</div>
                            </div>
                        </div>
                        <div class="course-content">
                            <p>{{course.description}}</p>
                        </div>
                    </accordion-group>
                </accordion>
            </div> -->
        </div>
        <div class="col-12 col-lg-5 text-center carousel-section pb-5 pb-lg-0">
            <carousel class="carousel-banners">
                <slide *ngFor="let banner of banners$ | async" style="cursor: pointer;">
                    <a (click)="reroute(banner.redirectUrl)">
                        <img class="img-fluid" [src]="apiBaseUrl + banner.image?.url" />
                    </a>
                </slide>
                <!-- <slide>
                    <a [routerLink]="['/golf-pass/1']">
                        <img class="img-fluid" src="/assets/banners/2022GolfPassBanner.jpg"/>
                    </a>
                </slide>
                <slide>
                    <img src="/assets/banners/fowlers.png"/>
                </slide>
                <slide>
                    <img src="/assets/banners/fowlers_2.png"/>
                </slide>
                <slide>
                    <img src="/assets/banners/stdenis.png"/>
                </slide> -->
            </carousel>
        </div>
    </div>
</div>