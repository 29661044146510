import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'projects/admin/src/environments/environment';

import { OrderDetails } from '../../models/order';
import { OrderService } from '../../services/order.service';
import { getDateTime } from '../../utili/get-datetime';
import { ProductOrder, ProductOrderDetails } from '../../models/productorder';
import { GolfOrderService } from '../../services/golf-order.service';
import { ProductOrderService } from '../../services/product-order.service';
import { GolfOrderDetails } from '../../models/golforder';

@Component({
  selector: 'gcl-lib-user-order-summary',
  templateUrl: './user-order-summary.component.html',
  styleUrls: ['./user-order-summary.component.css']
})
export class UserOrderSummaryComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public getDateTime = getDateTime;

  public order$!: Observable<OrderDetails>;
  public golforder$!: Observable<GolfOrderDetails>;
  public productorders$!: Observable<Array<ProductOrderDetails>>;
  public productTotal$!: Observable<number>;

  private destroyed$ = new Subject();

  @Input()
  orderId!: number;

  constructor(private orderService: OrderService, private golfOrderService: GolfOrderService, private productOrderService: ProductOrderService, public route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.order$ = this.orderService.get(this.orderId)
      .pipe(
        takeUntil(this.destroyed$)
      );

    this.order$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((order: OrderDetails) => {
        this.golforder$ = this.golfOrderService.get(order.golforders[0].id)
          .pipe(
            takeUntil(this.destroyed$)
          );

        const pagedResult = this.productOrderService.query({
          order: order.id
        });
        this.productorders$ = pagedResult.records$;
        this.productTotal$ = pagedResult.records$
          .pipe(
            map((records) => records.reduce((total, pp) => {
              const productTotal = (!pp.product.taxable) ? 0 : (pp?.extprice || 0);
              return total + productTotal;
            }, 0)),
            takeUntil(this.destroyed$)
          );
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
