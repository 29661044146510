<gcl-lib-two-pane-horizontal-resizable [width]="'50%'" class="d-none d-lg-block">
    <div slot="left" class="h-100 d-flex flex-column">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div slot="right">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</gcl-lib-two-pane-horizontal-resizable>

<!-- Mobile -->
<div class="row d-block d-lg-none">
    <div  class="col-12">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div class="col-12 py-4">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</div>

<!-- Templates -->
<ng-template #left>
    <h3 class="text-primary text-center">Course</h3>
    <div class="row" *ngIf="state.course$ | async as course">
        <div class="col-12 col-lg-4 text-center text-lg-left py-4 py-lg-0">
            <img class="img-fluid w-100" [src]="apiUrl + course?.logo?.url" />
        </div> 
        <div class="col-12 col-lg-8">
            <h5>{{course?.full_name}}</h5>
            <p class="font-weight-light">{{course?.address}}</p>
            <p>{{course?.description}}</p>
        </div>
    </div>
</ng-template>

<ng-template #right>
        <gcl-lib-date-picker
            [date]="startDate"
            [large]="true"
            [bordered]="false"
            
            [maxDate]="state.maxDate"
            (selectDate)="onSelectDate($event)"></gcl-lib-date-picker>

        <h3 class="text-primary text-center py-4">Select a Tee Time</h3>
        <div style="overflow: auto; max-height: 400px;">
            <div class="row bg-light d-flex justify-content-between align-items-center p-3 m-2" *ngFor="let round of rounds$ | async">
                <div>
                    <div>{{getDateTime(round.datestr, round.starttod) | date:"fullDate"}} {{getDateTime(round.datestr, round.starttod) | date:"shortTime"}}</div>
                    <div class="font-weight-light">
                        {{round.course.full_name}} <br/> 
                        <span *ngIf="round.front">{{round.front}}</span> <span *ngIf="round.back"> / {{round.back}}</span>
                        <span class="discount-price float-right" *ngIf="round.golfproduct && round.golfproduct.hasDiscount === true">{{round.golfproduct.discountPrice | currency}}</span>
                        <span class="float-right mx-2" [class.has-discount]="round.golfproduct && round.golfproduct.hasDiscount === true">{{getRoundPrice(round) | currency}}</span>    
                    </div>
                </div>
                <div class="d-sm-block d-none">
                    <button class="btn btn-primary rounded px-4 rounded-pill" type="button" (click)="onSelectRound(round)">Select Time</button>
                </div>
                <div class="d-block d-sm-none w-100">
                    <button class="btn btn-primary btn-block rounded px-4 rounded-pill" type="button" (click)="onSelectRound(round)">Select Time</button>
                </div>
            </div>
            <div *ngIf="(total$ | async) == 0">
                <p>There are no available tee times.</p>
            </div>
        </div>
</ng-template>