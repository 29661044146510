<div *ngIf="step$ | async as step" class="py-4">
    <gcl-user-tee-time-breadcrumb class="py-4"></gcl-user-tee-time-breadcrumb>
    <gcl-user-add-tee-time *ngIf="step == AddTeeTimeState.AddTeeTime"></gcl-user-add-tee-time>
    <gcl-user-select-by-course *ngIf="step == AddTeeTimeState.SelectByCourse"></gcl-user-select-by-course>
    <gcl-user-select-tee-time *ngIf="step == AddTeeTimeState.SelectTeeTime"></gcl-user-select-tee-time>
    <gcl-user-select-by-time *ngIf="step == AddTeeTimeState.SelectByTime"></gcl-user-select-by-time>
    <gcl-user-add-to-cart *ngIf="step == AddTeeTimeState.AddToCart"></gcl-user-add-to-cart>
    <gcl-user-add-product *ngIf="step == AddTeeTimeState.AddProduct"></gcl-user-add-product>
    <gcl-user-checkout *ngIf="step == AddTeeTimeState.Checkout"></gcl-user-checkout>
    <gcl-user-order-confirmation *ngIf="step == AddTeeTimeState.OrderConfirmation"></gcl-user-order-confirmation>
</div>