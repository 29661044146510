import { StripeCardComponent } from "./stripe-card/stripe-card.component";
import { StripeCreateCardComponent } from "./stripe-create-card/stripe-create-card.component";
import { StripeEditCardComponent } from "./stripe-edit-card/stripe-edit-card.component";
import { StripePaymentMethodsComponent } from "./stripe-payment-methods/stripe-payment-methods.component";

export let StripeComponents = [
    StripePaymentMethodsComponent,
    StripeCreateCardComponent,
    StripeEditCardComponent,
    StripeCardComponent
]