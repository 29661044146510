import { Component, EventEmitter, OnInit, Output, Input, forwardRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { switchMap, shareReplay, takeUntil } from 'rxjs/operators';

import { GolfProductDetails } from '../../models/golfproduct';
import { AuthService } from '../../services/auth.service';
import { GolfProductService } from '../../services/golf-product.service';
import { ReservationTypes } from '../../enumerations/reservation-type';
import { GolfProductReservationTypes } from '../../enumerations/golf-product-reservation-type';

@Component({
  selector: 'gcl-lib-search-golf-products',
  templateUrl: './search-golf-products.component.html',
  styleUrls: ['./search-golf-products.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchGolfProductsComponent),
    multi: true
  }],
})
export class SearchGolfProductsComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  public records$!: Observable<Array<GolfProductDetails>>;

  public selectedItem?: GolfProductDetails;
  public id?: number;
  public disabled!: boolean;

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };

  private destroy$: Subject<boolean> = new Subject();

  @Input()
  reservationType?: number | null | undefined;
  @Input()
  cart?: boolean;
  @Input()
  holes?: number;
  @Input()
  multiselect: boolean = false;

  @Output()
  addItem: EventEmitter<GolfProductDetails> = new EventEmitter<GolfProductDetails>();

  constructor(private fb: FormBuilder, private authService: AuthService, private golfProductService: GolfProductService) { }

  ngOnInit(): void {
    this.getPagedResults();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["cart"] || changes["holes"] || changes["reservationType"]) {
      this.getPagedResults();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getPagedResults() {
    const course$ = this.authService.course$
      .pipe(
        shareReplay(1),
        takeUntil(this.destroy$)
      );

    this.records$ = course$.pipe(
      switchMap(course => {
        let query: any = {
          course: course?.id,
          holes: this.holes,
          // reservationType: this.reservationType ? GolfProductReservationTypes[ReservationTypes[this.reservationType] as keyof typeof GolfProductReservationTypes] : undefined
        };

        if(this.reservationType) {
          query['reservationType'] = this.reservationType;
        }

        if(this.cart === true) {
          query.cart = true;
        }

        const pagedResult = this.golfProductService.query(query);
        return pagedResult.records$;
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
  }

  public setRecord(id: string) {
    // TODO: clean up model binding.
    this.records$.subscribe((records) => {
      const record = records.find(r => r.id == parseInt(id));
      if (record) {
        this.selectedItem = record;

        this.propagateChange(this.selectedItem);
        this.propagateTouched();
      } else {
        this.propagateChange(undefined);
        this.propagateTouched();
      }
    });
  }

  public addRecord() {
    if (this.multiselect) {
      this.records$.subscribe((records) => {
        if (this.id) {
          const record = records.find(r => r.id == this.id);
          if (record) {
            this.selectedItem = record;
            this.addItem.emit(this.selectedItem);

            this.propagateChange(this.selectedItem);
            this.propagateTouched();
          } else {
            this.propagateChange(undefined);
            this.propagateTouched();
          }
        } else {
          this.propagateChange(undefined);
          this.propagateTouched();
        }
      });
    }
  }

  writeValue(val?: GolfProductDetails): void {
    this.selectedItem = val;
    this.id = val?.id;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
