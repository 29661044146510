import { Observable } from "rxjs";

export class PagedResult<TDetails> {
    records$!: Observable<Array<TDetails>>;
    filteredCount$!: Observable<number>;
    totalCount$!: Observable<number>;

    constructor(records$: Observable<Array<TDetails>>, filteredCount$: Observable<number>, totalCount$: Observable<number>) {
        this.records$ = records$;
        this.filteredCount$ = filteredCount$;
        this.totalCount$ = totalCount$;
    }
}