<div gclLibDragAndDrop (fileDropped)="onFileDropped($event)" (dragLeave)="hideDropzone()" (dragOver)="showDropzone()">
    <ng-content *ngIf="!isDragOver || autoUpload"></ng-content>
  
    <input class="hidden-input" *ngIf="isDragOver && autoUpload" type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
  
    <!-- Drag & Drop Zone -->
    <div *ngIf="(isDragOver || showDragAndDrop) && !autoUpload">
      <div class="container text-center">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
  
        <h3>Drag and drop file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>
  
      <div class="container-fluid">
        <div class="files-list">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <fa-icon [icon]="faFile" class="pr-2" size="lg" alt="file"></fa-icon>
            <div class="info">
              <h4 class="name">{{ file.name }}</h4>
              <p class="size">{{ getSize(file.size) }}</p>
            </div>
            <fa-icon (click)="deleteFile(i)" class="ml-2 cursor-pointer" size="lg" [icon]="deleteIcon"></fa-icon>
          </div>
        </div>
  
        <button *ngIf="!showDragAndDrop" type="button" class="btn btn-primary btn-block mb-4" (click)="uploadFilesAsync()">Upload</button>
      </div>
    </div>
    
  </div>