import { FormControl, ValidationErrors } from "@angular/forms";

export class NotEqualToValidator {
    static NotEqualTo(value: any): ValidationErrors | null {
        return (formControl: FormControl) => {
            const formControlValue = formControl.value;
            if (formControlValue !== value) {
                return null;
            }
            return {
                equalTo: value
            };
        }
    }

    static NotEqualToOtherFormField(controlField: string): ValidationErrors | null {
        return (formControl: FormControl) => {
            const formControlValue = formControl.value;
            const formGroup = formControl.parent;
            const parent = formGroup?.parent;
            const otherControls = parent?.controls || [];

            let indexes: Array<number> = [];
            Object.values(otherControls).filter(oc => (oc?.value?.index != formGroup?.value?.index)).forEach((other, index) => {
                const otherControlValue = other.value[controlField];
                if ((formControlValue == otherControlValue) && ((formControlValue != "" && formControlValue != null) && (otherControlValue != "" && otherControlValue != null))) {
                    indexes.push(index);
                }
            });

            if (indexes.length == 0) {
                return null;
            }
            return {
                equalToFields: indexes
            };
        }
    }
}