import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { combineLatest, concat, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, toArray } from 'rxjs/operators';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons';

import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { Order, OrderDetails } from 'projects/shared/src/lib/models/order';
import { GolfOrder, UserDetails } from 'projects/shared/src/public-api';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { NotEqualToValidator } from 'projects/shared/src/lib/validators/not-equal-validator';

@Component({
  selector: 'gcl-user-add-tee-time',
  templateUrl: './add-tee-time.component.html',
  styleUrls: ['./add-tee-time.component.scss']
})
export class AddTeeTimeComponent implements OnInit, OnDestroy {
  public faChevronRight = faChevronRight;
  public faTrash = faTrash;
  public getDateTime = getDateTime;

  public user$!: Observable<UserDetails | null>;

  get numOfGolfers() { return this.form.get("numOfGolfers") as FormControl }
  get isEightteenHoles() { return this.form.get("isEightteenHoles") as FormControl }
  get addCart() { return this.form.get("addCart") as FormControl }
  get players() { return this.form.controls["players"] as FormArray }

  public orderChanged$!: Observable<Order>;
  public showGolfPlayerForm: boolean = false;

  public form = this.fb.group({
    numOfGolfers: [1, [Validators.required, Validators.min(1), Validators.max(4)]],
    isEightteenHoles: [false, [Validators.required]],
    addCart: [true, [Validators.required]],
    players: this.fb.array([]),
  });

  @ViewChild('deletePlayerModal', { static: false }) removePlayerModal?: ModalDirective;
  public theOpenRemovePlayerModal!: Function;

  private destroy$ = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    public state: TeeTimeManager,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.theOpenRemovePlayerModal = this.openRemovePlayerModal.bind(this);

    this.user$ = this.authService.user$.pipe(catchError(err => of(null)));

    let order$ = this.state.order$.pipe(catchError(err => of({cart: this.state.addTeeTime.addCart, holes: this.state.addTeeTime.numOfHoles, golforders: []})))

    combineLatest([order$, this.user$])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(([order, user]) => {
        let numOfGolfers = (!!user ? order?.golforders?.length : this.state.addTeeTime.golfers.length + 1) || 1;

        this.form.patchValue({
          addCart: order.cart || false,
          isEightteenHoles: ((order.holes != undefined) && (order.holes == 18)),
          numOfGolfers: Math.max(numOfGolfers, 1)  // Defaults to 1, the reserving player.
        });

        this.state.setTeeTimeBaseInfo(this.numOfGolfers.value, this.isEightteenHoles.value, this.addCart.value, !!user ? this.players.value : this.state.addTeeTime.golfers);

        const guests = !!user ? order.golforders.filter(go => go.player != (user?.id)) : this.state.addTeeTime.golfers.map((g, i) => ({email: g.email, golforderid: null, index: i})) as any[];
        this.initPlayerForms(guests, user);
      });

      let user$ = this.authService.user$.pipe(
        catchError(err => of(null))
      );

    this.form.controls.numOfGolfers.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(newNumOfGolfers => {
        const currentNumOfGolfers = this.state.addTeeTime.numOfGolfers;

        if ((newNumOfGolfers > currentNumOfGolfers)) {
          this.addPlayer();
        }
        else if ((newNumOfGolfers < currentNumOfGolfers)) {
          this.openRemovePlayerModal();
        }
      });    

    combineLatest([this.form.controls.addCart.valueChanges, user$])
      .pipe(
        switchMap(([value, user]) => !!user ? this.state.setCart(value) : of({cart: this.form.controls.addCart.value})),
        takeUntil(this.destroy$)
      )
      .subscribe(
        order => {
          this.state.addTeeTime.addCart = order.cart;
        },
        error => {
          this.state.addTeeTime.addCart = this.form.controls.addCart.value;
        },
        () => {          
        }
      );

    combineLatest([this.form.controls.isEightteenHoles.valueChanges, user$])
      .pipe(
        switchMap(([isEightteenHoles, user]) => !!user ? this.state.setHoles(isEightteenHoles ? 18 : 9) : of({cart: this.form.controls.addCart.value, holes: this.form.controls.isEightteenHoles.value ? 18 : 9})),
        takeUntil(this.destroy$)
      )
      .subscribe(
        order => {
          this.state.addTeeTime.addCart = order.cart;
          this.state.addTeeTime.numOfHoles = order.holes;
        },
        error => {
          this.state.addTeeTime.numOfHoles = this.form.controls.isEightteenHoles.value ? 18 : 9;
        }
      );
  }

  public openRemovePlayerModal(): void {
    this.removePlayerModal?.show();
  }

  public getPlayerEmail(control: AbstractControl): string {
    return (control as FormControl)?.value?.email || "Enter Guest Email";
  }

  public hideRemovePlayerModal(): void {
    this.removePlayerModal?.hide();
  }

  private initPlayerForms(guests: Array<GolfOrder>, user: UserDetails | null): void {
    let players = this.fb.array([]);

    guests.forEach((go, index) => players.push(this.fb.group({
      email: [go.email, !user ? [Validators.email] : [Validators.email, NotEqualToValidator.NotEqualTo(user.email), NotEqualToValidator.NotEqualToOtherFormField("email")]],
      golforderid: [go.id],
      index: index
    })));

    this.form.setControl("players", players);
  }

  private addPlayer(): void {
    this.user$
      .subscribe(
        (user) => {
          this.players.push(this.fb.group({
            email: [null, !user ? [Validators.email] : [Validators.email, NotEqualToValidator.NotEqualTo(user.email), NotEqualToValidator.NotEqualToOtherFormField("email")]],
            golforderid: [null],
            index: this.players.length
          }));
        },
        (error) => {
          this.players.push(this.fb.group({
            email: [null, [Validators.email]],
            golforderid: [null],
            index: this.players.length
          }));
        },
        () => {
          this.state.addTeeTime.numOfGolfers = this.players.length + 1;
        }
      );
  }

  public removePlayer(index: number): void {
    this.players.removeAt(index);
    this.form.patchValue({
      numOfGolfers: this.players.length + 1
    });
    this.state.addTeeTime.numOfGolfers = this.players.length + 1;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getNumOfPlayersArray(): Array<number> {
    const players = this.state.addTeeTime.numOfGolfers - 1;
    return new Array(players);
  }

  public getPlayerEmailFormControl(index: number): FormControl {
    return this.players.controls[index].get("email") as FormControl;
  }

  public clearPlayerEmail(index: number): void {
    const player = (this.players.at(index) as FormGroup);
    player.patchValue({
      email: null,
      golforderid: null
    });
  }

  public showGolfPlayerSelection(): void {
    this.showGolfPlayerForm = true;
  }

  public goToSelectCourse(): void {
    if (this.form.valid) {
      this.next(AddTeeTimeState.SelectByCourse);
    }
  }

  public goToSelectDateTime(): void {
    if (this.form.valid) {
      this.next(AddTeeTimeState.SelectByTime);
    }
  }

  private next(step: AddTeeTimeState): void {
    combineLatest([this.state.order$, this.user$])
      .pipe(
        catchError(err => of([{golforders: []}, {id: -1}] as unknown as [OrderDetails, UserDetails])),
        switchMap(([order, user]) => {
          if(user && user.id !== -1) {
            let tasks: Array<any> = [];

            const players = this.players.controls;
  
            const guests = order.golforders.filter(go => go.player != (user?.id));
            guests.forEach(guestOrder => {
              const player = players.filter(go => guestOrder.id == go.value.golforderid)[0];
              if ((guestOrder != null) && (player != null)) {
                tasks.push(this.state.updatePlayer(guestOrder.id, player.value.email));
              } else if ((guestOrder != null) && (player == null)) {
                tasks.push(this.state.removePlayer(guestOrder.id));
              }
            });
  
            players.filter(pl => pl.value.golforderid == null).forEach(player => {
              tasks.push(this.state.addPlayer(player?.value?.email));
            });
  
            // if (tasks.length == 0) {
            //   // Forces the observable to complete if no tasks.
            //   return combineLatest([of(order), of(user)]);
            // }
  
            // Run all tasks in sequential order.
            return concat(...tasks).pipe(toArray());
          }
          else {
            return of({});
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => this.state.step$.next(step),
        (response) => {
          this.toastr.error(response.error.message);
          this.state.getOrder();
        },
        () => {
          this.state.addTeeTime.golfers = this.players.value;
        }
      );
  }

}
