<div class="py-4">
    <gcl-user-user-breadcrumb class="py-4">
        <a class="btn btn-link text-dark pr-1 pl-1" routerLinkActive="active-link" [routerLink]="['/account']">View</a>
        <span> / </span>
        <a class="btn btn-link text-dark pr-1 pl-1" routerLinkActive="active-link" [routerLink]="['/account', 'old-orders']">Old Orders</a>
        <span> / </span>
        <span class="text-info">Order Summary</span>
    </gcl-user-user-breadcrumb>
    
    <div class="container py-4"*ngIf="orderId$ | async as orderId">
        <div class="card">
            <div class="card-body">
                <gcl-lib-user-order-summary [orderId]="orderId"></gcl-lib-user-order-summary>
            </div>
        </div>
    </div>
</div>