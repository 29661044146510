import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDetails } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { PasswordValidators } from '../../validators/password-validators';

@Component({
  selector: 'gcl-lib-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  get password() { return this.changeForm.get('password') as FormControl; }
  get confirmPassword() { return this.changeForm.get('confirmPassword') as FormControl; }

  changeForm = this.fb.group({
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  }, { validators: [PasswordValidators.MatchPassword] });

  @Output()
  onSubmit = new EventEmitter();

  constructor(private fb: FormBuilder, private authService: AuthService, private userService: UserService) { }

  ngOnInit(): void {
  }

  submit() {
    this.authService.changePassword(this.password?.value)
      .subscribe(result => {
        this.changeForm.setErrors({});
        this.onSubmit.emit();
      }, err => {
        this.changeForm.setErrors({
          invalidEmail: true
        })
      });
  }
}
