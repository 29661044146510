import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig, BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'gcl-lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  public faChevronLeft = faChevronLeft;
  public faChevronRight = faChevronRight;

  public bsConfig!: Partial<BsDatepickerConfig>;

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: any) => { };

  @Input()
  readonly: boolean = false;
  @Input()
  date!: Date;
  @Input()
  large: boolean = false;
  @Input()
  minDate?: Date | null = null;
  @Input()
  maxDate?: Date | null = null;
  @Input()
  bordered: boolean = true;
  @Input()
  placement: "top" | "bottom" | "left" | "right" = "bottom";
  @Input()
  format: string = "MM/DD/YYYY";
  @Input()
  placeholder: string = "Datepicker";
  @Input()
  viewMode: BsDatepickerViewMode = "day";
  @Input()
  incrementBy: string = "day";

  @Output()
  selectDate: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() { }

  ngOnInit() {
    if (!this.isIncrementByValid(this.incrementBy)) {
      this.incrementBy = "day";
    }

    this.bsConfig = Object.assign({}, {
      minMode: this.viewMode
    });

  }

  private isIncrementByValid(incrementBy: string): boolean {
    switch (incrementBy.toLocaleLowerCase()) {
      case "year":
      case "month":
      case "day":
        return true;
      default:
        return false;
    }
  }

  private incrementDate(date: Date, increment: number): Date {
    switch (this.incrementBy.toLocaleLowerCase()) {
      case "year":
        return date.addYears(increment);
      case "month":
        return date.addMonths(increment);
      case "day":
      default:
        return date.addDays(increment);
    }
  }

  public decrement(): void {
    const date = this.incrementDate(this.date, -1);
    if ((!this.minDate || (date >= this.minDate))) {
      this.date = date;
      this.date.setHours(0, 0, 0, 0);

      this.propagateChange(this.date);
      this.selectDate.emit(this.date);
    }
  }

  public onSelectDate(date: Date): void {
    if (date != null) {
      this.date = date;
      this.date.setHours(0, 0, 0, 0);

      this.propagateChange(this.date);
      this.selectDate.emit(this.date);
    }
  }

  public increment(): void {
    const date = this.incrementDate(this.date, 1);
    if ((!this.maxDate || (date <= this.maxDate))) {
      this.date = date;
      this.date.setHours(0, 0, 0, 0);
      
      this.propagateChange(this.date);
      this.selectDate.emit(this.date);
    }
  }

  writeValue(val: Date): void {
    this.date = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
}
