import { CartService } from './cart.service';
import { CategoryService } from './category.service';
import { CourseService } from './course.service';
import { CustomerService } from './customer.service';
import { DeviceService } from './device.service';
import { MediaService } from './media.service';
import { ProductService } from './product.service';
import { TeetimeService } from './teetime.service';
import { WeatherService } from './weather.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { ScheduleService } from './schedule.service';
import { RoundService } from './round.service';
import { GolfProductService } from './golf-product.service';
import { GolfOrderService } from './golf-order.service';
import { ProductOrderService } from './product-order.service';
import { LoadingScreenService } from './loading-screen.service';
import { StripeService } from './stripe.service';
import { OrderPaymentService } from './order-payment.service';
import { ConfirmationModalService } from './confirmation-modal.service';
import { InputModalService } from './input-modal.service';
import { ReportService } from './report.service';
import { AddressService } from './address.service';
import { CacheService } from './cache.service';
import { GolfPassService } from './golf-pass.service';
import { GolfPassOrderService } from './golf-pass-order.service';
import { PromoCodeService } from './promo-code.service';
import { SocketService } from './socket.service';
import { EpsonPrintService } from './epson-print.service'; 

export let Services = [
    CartService,
    CategoryService,
    CourseService,
    CustomerService,
    DeviceService,
    MediaService,
    ProductService,
    ProductOrderService,
    TeetimeService,
    WeatherService,
    AuthService,
    UserService,
    ScheduleService,
    RoundService,
    GolfProductService,
    GolfOrderService,
    LoadingScreenService,
    StripeService,
    OrderPaymentService,
    ConfirmationModalService,
    InputModalService,
    ReportService,
    AddressService,
    CacheService,
    GolfPassService,
    GolfPassOrderService,
    PromoCodeService,
    SocketService,
    EpsonPrintService,
]