

<ng-container>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Create Payment Method</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span class="modal-close" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="paymentForm">
            <ngx-stripe-card-group ngxStripeCardGroup
                [stripe]="stripe">
                    <div class="form-group">
                        <label for="name" class="form-label">Name</label>
                        <input class="form-control" formControlName="name" />
                    </div>
                    <div class="form-group">
                        <label for="cardNumber" class="form-label">Card Number</label>
                        <ngx-stripe-card-number name="cardNumber" class="form-control"></ngx-stripe-card-number>
                    </div>
                    <div class="form-group row">
                        <div class="col-8">
                            <label for="expDate" class="form-label">Exp. Date</label>
                            <ngx-stripe-card-expiry name="expDate" class="form-control"></ngx-stripe-card-expiry>
                        </div>
                        <div class="col-4">
                            <label for="cvc" class="form-label">CVC</label>
                            <ngx-stripe-card-cvc name="cvc" class="form-control"></ngx-stripe-card-cvc>
                        </div>
                    </div>
                    <div class="d-flex" *ngIf="!forceSave && userId">
                        <div class="form-check">
                            <input id="save" formControlName="save" type="checkbox" class="form-check-input" />
                            <label class="form-check-label" for="save">
                                Save
                            </label>
                        </div>
                    </div>
            </ngx-stripe-card-group>
            <div>
                <div class="form-group">
                    <label for="addrLine1" class="form-label">Address Line 1</label>
                    <input class="form-control" formControlName="addrLine1" />
                </div>
                <div class="form-group">
                    <label for="addrLine2" class="form-label">Address Line 2</label>
                    <input class="form-control" formControlName="addrLine2" />
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label for="addrCity" class="form-label">City</label>
                            <input class="form-control" formControlName="addrCity" />
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label for="addrState" class="form-label">State</label>
                            <input class="form-control" formControlName="addrState" />
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label for="addrZip" class="form-label">ZipCode</label>
                            <input class="form-control" formControlName="addrZip" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="d-none d-sm-block">
            <button type="button" class="btn btn-secondary mr-2" (click)="bsModalRef.hide()">Cancel</button>
            <button type="button" class="btn btn-primary" disabled *ngIf="submitted">Submit</button>
            <button type="button" class="btn btn-primary" *ngIf="!submitted" (click)="onSubmit()">Submit</button>
        </div>
        <div class="d-block d-sm-none w-100">
            <button type="button" class="btn btn-block btn-primary" disabled *ngIf="submitted">Submit</button>
            <button type="button" class="btn btn-block btn-primary" *ngIf="!submitted" (click)="onSubmit()">Submit</button>
            <button type="button" class="btn btn-block btn-secondary" (click)="bsModalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-container>