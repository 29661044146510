<gcl-lib-two-pane-horizontal-resizable [width]="'50%'">
    <div slot="left" class="h-100 d-flex flex-column">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div slot="right">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</gcl-lib-two-pane-horizontal-resizable>

<ng-template #left>
    <ng-container *ngIf="state.getCurrentUser$() | async as user">
        <h3 class="card-title text-center text-primary">Payment Methods</h3>
        <gcl-lib-stripe-payment-methods
            [selectedPaymentMethodId]="selectedPaymentMethodId"
            [userId]="user.id"
            (select)="onSelectPaymentMethod($event)"
            (newPayment)="onShowNewPayment()"></gcl-lib-stripe-payment-methods>
    </ng-container>
</ng-template>

<ng-template #right>
    <h3 class="card-title text-center text-primary">Order Summary</h3>
    <h5 *ngIf="state.round$ | async as round">
        {{getDateTime(round?.datestr, round.starttod) | date:"fullDate"}} {{getDateTime(round.datestr, round.starttod) | date:"shortTime"}}
    </h5>
    <gcl-user-course-details [course]="state.course$?.value"></gcl-user-course-details>
    <div class="row">
        <div class="col-12">
            <h6>Particulars:</h6>
            <ng-container *ngIf="state.addTeeTime">
                <p>People in Party: {{state.addTeeTime.numOfGolfers}}</p>
                <p>Course: {{state.addTeeTime.numOfHoles}} Holes <span *ngIf="state.getGolfProduct()">| {{state.getGolfProduct()?.price | currency}}</span></p>
                <p>Include Cart: {{state.addTeeTime.addCart ? 'Yes' : 'No'}}</p>
            </ng-container>
        </div>
    </div>

    <div class="text-primary">
        <div class="row">
            <div class="col-12" *ngIf="state.order$ | async as order">
                <h5 class="card-title text-center text-primary">Players</h5>
                <ul class="pb-2 list-group rounded-0" *ngIf="state.golfproduct$ | async as golfproduct">
                    <li class="list-group-item border-0" *ngFor="let item of order.golforders; let index = index">
                        <div class="d-flex justify-content-between">
                            <div class="flex-grow-1 align-self-center px-2">
                                <span *ngIf="index == 0">Reserving Player - </span>
                                <span *ngIf="index != 0">Guest Player {{index}} - </span>
                                {{golfproduct.name}}
                            </div>
                            <div class="px-2 align-self-center">{{item?.extprice | currency}}</div>
                        </div>
                    </li>
                </ul>

                <h5 class="card-title text-center text-primary">Item List</h5>
                <ul class="list-group" *ngIf="products">
                    <li class="border-0" *ngFor="let item of products"
                        class="list-group-item">
                        <h6>{{state.getProductName(item.product)}} ({{item.quantity}}) <span class="float-right">{{item.extprice | currency}}</span></h6>
                    </li>
                    <li class="list-group-item border-0" *ngIf="(products.length) == 0">No items in cart.</li>
                </ul>
            </div>
        </div>

        <div class="row py-2">
            <div class="col-12">
                <ng-container  *ngIf="state.order$ | async as order">
                    <h5 class="card-title text-center text-primary">Order Summary</h5>
                    <div class="card border-0">
                        <div class="card-body text-primary">
                            <h6>Course Total: <span class="float-right"*ngIf="state.getOrderCourseTotal() | async as courseTotal">{{courseTotal | currency}}</span></h6>
                            <h6>Product Total: <span class="float-right" *ngIf="state.getOrderProductTotal() | async as productTotal">{{productTotal | currency}}</span></h6>
                            <ng-container class="" *ngIf="order.promo_code">
                                <h6 class="text-danger">Promo Code ({{state.getPromoCode(order.promo_code)}}): <span class="float-right" *ngIf="state.getPromoCodeDiscount() | async as promoAmount">({{promoAmount | currency}})</span></h6>
                            </ng-container>
                            <h6>Tax: <span class="float-right">{{order.tax | currency}}</span></h6>
                        </div>
                        <div class="card-footer bg-primary text-white">
                            <h6>Total: <span class="float-right">{{order.total | currency}}</span></h6>
                        </div>
                    </div>

                    <hr/>

                    <ng-container *ngIf="creditBalance$ | async as creditBalance">
                        <form [formGroup]="form" class="pb-2">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" formControlName="applyCreditBalance" id="applyCreditBalance" name="applyCreditBalance">
                                <label class="custom-control-label text-dark h6" for="applyCreditBalance" *ngIf="!order.useCreditBalance">Apply <strong>[{{creditBalance | currency}}]</strong> Credit Balance</label>
                                <label class="custom-control-label text-dark h6" for="applyCreditBalance" *ngIf="order.useCreditBalance">Apply <strong>[{{order.creditBalanceTotal | currency}}]</strong> Credit Balance</label>
                            </div>
                        </form>
                    </ng-container>

                    <ng-container>
                        <h5>
                            <span>Payment: </span>
                            <span class="float-right" *ngIf="!isPaymentSelectedOrCreated()">{{0 | currency}}</span>
                            <span class="float-right" *ngIf="isPaymentSelectedOrCreated()">({{(order.finaltotal) | currency}})</span>
                        </h5>
                        <h5 *ngIf="order.useCreditBalance">
                            <span>Credits Applied: </span>
                            <span class="float-right">({{(order.creditBalanceTotal) | currency}})</span>
                        </h5>
                        <h5>
                            <span>Amount due: </span>
                            <span class="float-right" *ngIf="!isPaymentSelectedOrCreated()">{{order.finaltotal | currency}}</span>
                            <span class="float-right" *ngIf="isPaymentSelectedOrCreated()">{{0 | currency}}</span>
                        </h5>
                    </ng-container>
                </ng-container>

                <div class="d-none d-sm-block">
                    <div class="py-2 d-flex justify-content-between">
                        <button class="btn btn-secondary rounded-pill btn-lg mr-2" type="button" (click)="cancelOrder()">Cancel Outing</button>
                        <button class="btn btn-primary rounded-pill btn-lg" [class.disabled]="isSubmitted" [disabled]="isSubmitted" type="button" (click)="confirmSubmission()">Submit Order</button>
                    </div>
                </div>
                <div class="d-block d-sm-none">
                    <button class="btn btn-primary btn-block rounded-pill" [class.disabled]="isSubmitted" [disabled]="isSubmitted" type="button" (click)="confirmSubmission()">Submit Order</button>
                    <button class="btn btn-secondary btn-block rounded-pill mr-2" type="button" (click)="cancelOrder()">Cancel Outing</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">
    <div class="row">
        <div class="col-12 col-md-10 col-lg-8 col-xl-7 mx-auto">
        </div>
    </div>
</div>