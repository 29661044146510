<gcl-lib-two-pane-horizontal-resizable [width]="'35%'" class="d-none d-md-block">
    <div slot="left" class="h-100 d-flex flex-column">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div slot="right">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</gcl-lib-two-pane-horizontal-resizable>

<!-- Mobile -->
<div class="row d-block d-md-none">
    <div  class="col-12">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div class="col-12 py-4">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</div>

<!-- Templates -->
<ng-template #left>
    <div class="row">
        <div class="mx-auto text-center">
            <label class="text-primary" for="startDate">Select Date</label>
            <gcl-lib-date-picker
                [minDate]="state.minDate"
                [maxDate]="state.maxDate"
                [large]="true"
                [date]="startDate" 
                [bordered]="false"
                (selectDate)="onSelectDate($event)"></gcl-lib-date-picker>
        </div>
    </div>
    <div class="row pt-4">
        <div class="mx-auto text-center">
            <label class="text-primary" for="startTime">Select Time</label>
            <gcl-lib-time-picker 
                name="startTime"
                [time]="startTime" 
                [bordered]="false"
                (selectTime)="onSelectTime($event)"></gcl-lib-time-picker>
        </div>
    </div>
</ng-template>

<ng-template #right>
    <h3 class="text-primary text-center py-4">Select a Tee Time</h3>
    <div style="overflow: auto; max-height: 400px;">
        <div class="row bg-light d-flex justify-content-between align-items-center p-3 m-2" *ngFor="let round of rounds$ | async">
            <div>
                <div>{{getDateTime(round.datestr, round.starttod) | date:"fullDate"}} {{getDateTime(round.datestr, round.starttod) | date:"shortTime"}}</div>
                <div class="font-weight-light">
                    {{round.course.full_name}} <br/> 
                    <span *ngIf="round.front">{{round.front}}</span> <span *ngIf="round.back"> / {{round.back}}</span>
                    <span class="discount-price float-right" *ngIf="round.golfproduct && round.golfproduct.hasDiscount === true">{{round.golfproduct.discountPrice | currency}}</span>
                    <span class="float-right mx-2" [class.has-discount]="round.golfproduct && round.golfproduct.hasDiscount === true">{{getRoundPrice(round) | currency}}</span>
                </div>
            </div>
            <div class="d-sm-block d-none">
                <button class="btn btn-primary rounded px-4 rounded-pill" type="button" (click)="onSelectRound(round, round.course)">Select Time</button>
            </div>
            <div class="d-block d-sm-none w-100">
                <button class="btn btn-primary btn-block rounded px-4 rounded-pill" type="button" (click)="onSelectRound(round, round.course)">Select Time</button>
            </div>
        </div>
        <div *ngIf="(total$ | async) == 0">
            <p>There are no available tee times.</p>
        </div>
    </div>
</ng-template>