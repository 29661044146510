import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { StripeService } from 'projects/shared/src/lib/services/stripe.service';
import { OnPageEvent, OrderDetails, UserDetails } from 'projects/shared/src/public-api';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';

@Component({
  selector: 'gcl-user-account-old-orders',
  templateUrl: './account-old-orders.component.html',
  styleUrls: ['./account-old-orders.component.scss']
})
export class AccountOldOrdersComponent implements OnInit {
  public getDateTime = getDateTime;

  public total$!: Observable<number>;
  public orders$!: Observable<OrderDetails[]>;
  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 5,
    page: 1,
    sortBy: 'round.datestr',
    sortDescending: true
  });

  private destroy$: Subject<boolean> = new Subject();

  constructor(private authService: AuthService, private courseService: CourseService, private stripeService: StripeService, private orderService: OrderService, private modalService: BsModalService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getPagedResult(1);
  }

  public getPagedResult(page: number) {
    this.authService.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((user: UserDetails) => {
        const pagedResult$ = this.orderService.query({
          skip: (page - 1) * this.pagination$.value.itemsPerPage,
          take: this.pagination$.value.itemsPerPage,
          sortColumns: [{
            column: this.pagination$.value.sortBy,
            direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
          }],

          users_permissions_user: user.id
        });

        this.total$ = pagedResult$.filteredCount$
          .pipe(
            shareReplay(1),
            takeUntil(this.destroy$)
          );;

        this.orders$ = pagedResult$.records$
          .pipe(
            shareReplay(1),
            takeUntil(this.destroy$)
          );
      });
  }

}
