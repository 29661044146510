import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Subject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AuthService } from "projects/shared/src/lib/services/auth.service";
import { UserDetails } from "projects/shared/src/public-api";

@Injectable()
export class AccountManager implements OnInit, OnDestroy {
    public user$: ReplaySubject<UserDetails> = new ReplaySubject(1);
    public reloadCreditBalance$: Subject<void> = new Subject<void>();

    private destroy$ = new Subject<boolean>();

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public getUser(): void {
        this.authService.getMe()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(user => this.user$.next(user));
    }

    public reloadCreditBalance(): void {
        this.reloadCreditBalance$.next();
    }

}