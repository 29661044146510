import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { GolfPassService } from 'projects/shared/src/lib/services/golf-pass.service';
import { GolfPass } from 'projects/shared/src/lib/models/golfpass';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-user-golf-pass-login',
  templateUrl: './golf-pass-login.component.html',
  styleUrls: ['./golf-pass-login.component.scss']
})
export class GolfPassLoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();

  public golfpassId$!: Observable<number>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.golfpassId$ = this.route.params.pipe(
      map(params => params.id),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    const auth$ = this.authService.isAuthenticated$;
    auth$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((auth) => {
        if (auth) {
          this.goRegisterPage();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public goRegisterPage(): void {
    this.golfpassId$
      .subscribe((id: number) => this.router.navigate(["/golf-pass", id, "checkout"]));
  }

}
