import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ConfirmationModalService } from 'projects/shared/src/lib/services/confirmation-modal.service';
import { OrderDetails } from 'projects/shared/src/public-api';

import { GolfPassManager, GolfPassStates } from '../../golf-pass.manager';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';

@Component({
  selector: 'gcl-user-golf-pass-order-summary',
  templateUrl: './golf-pass-order-summary.component.html',
  styleUrls: ['./golf-pass-order-summary.component.scss']
})
export class GolfPassOrderSummaryComponent implements OnInit, OnDestroy {

  private destory$: Subject<boolean> = new Subject<boolean>();

  public order$!: Observable<OrderDetails>;
  public golfpass$!: Observable<GolfPassDetails>;

  public submitted: boolean = false;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private confirmationModalService: ConfirmationModalService,
    public authService: AuthService,
    public orderService: OrderService,
    public golfpassManager: GolfPassManager,
  ) { }

  ngOnInit(): void {
    this.order$ = this.orderService.get(this.golfpassManager.orderId$.value as number)
      .pipe(
        takeUntil(this.destory$)
      );
    this.golfpass$ = this.golfpassManager.golfpass$;
  }

  ngOnDestroy(): void {
    this.destory$.complete();
  }

  public cancelOrder(): void {
    this.golfpassManager.cancelOrder(this.golfpassManager.orderId$.value as number)
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => this.golfpassManager.state$.next(GolfPassStates.Payment));
  }

  public confirmSubmission(): void {
    if (this.golfpassManager.stripe) {
      this.submitted = true;

      this.confirmationModalService.showConfirmationModal({
        title: 'Confirm Submission',
        message: 'You are about to submit your order? This action cannot be undone.'
      })
        .pipe(
          takeUntil(this.destory$)
        )
        .subscribe(
          (accept) => {
            if (accept) {
              this.golfpassManager.confirmCardPayment()
                .pipe(
                  takeUntil(this.destory$)
                )
                .subscribe(
                  (paymentIntent: {
                    paymentIntent?: PaymentIntent | undefined;
                    error?: StripeError | undefined;
                  }) => {
                    if (!paymentIntent.error) {
                      this.toastrService.success("Order successfully submitted.");
                      this.router.navigate(["/account"]);
                    } else {
                      this.displayError("An error occurred processing your payment.")
                    }
                  },
                  (error) => this.displayError(error)
                );
            } else {
              this.submitted = false;
            }
          },
          (error) => {
            this.displayError(error);
            this.golfpassManager.state$.next(GolfPassStates.Payment);
          }
        )
    }
  }

  private displayError(message: string) {
    this.submitted = false;
    this.toastrService.error(message);
  }

}
