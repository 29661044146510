import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

interface CacheContent {
  expiry: number;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  readonly DEFAULT_MAX_AGE_SEC: number = 1000 * 60 * 60 * 24;

  constructor() {
    // Refresh, rebuild cache.
    const cache = localStorage.getItem("cache");
    if (cache) {
      this.cache = JSON.parse(cache, this.reviver);
    }
  }

  private reviver(key: string, value: any) {
    if (typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  }

  public get(key: string): Observable<string> {
    if (this.hasValidCachedValue(key)) {
      const value = this.cache.get(key);
      if (value) {
        return of(value.value);
      }
    }
    return of("");
  }

  public set(key: string, value: string, maxAge: number = this.DEFAULT_MAX_AGE_SEC): void {
    this.cache.set(key, { value: value, expiry: Date.now() + maxAge });
    localStorage.setItem("cache", JSON.stringify(this.cache, this.replacer));
  }

  private replacer(key: string, value: any) {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()), // or with spread: value: [...value]
      };
    }
    return value;
  }

  public has(key: string): boolean {
    return this.cache.has(key);
  }

  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      const value = this.cache.get(key);
      if (value && (value.expiry > Date.now())) {
        return true;
      }
      this.remove(key);
    }
    return false;
  }

  public remove(key: string): void {
    this.cache.delete(key);
    localStorage.setItem("cache", JSON.stringify(this.cache, this.replacer));
  }
}
