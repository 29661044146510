import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { CourseDetails } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';

@Component({
  selector: 'gcl-user-select-by-course',
  templateUrl: './select-by-course.component.html',
  styleUrls: ['./select-by-course.component.scss']
})
export class SelectByCourseComponent implements OnInit {
  public getDateTime = getDateTime;
  public apiUrl: string = environment.apiUrl;

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 10,
    page: 1,
    sortBy: 'id',
    sortDescending: true
  });

  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  records$: ReplaySubject<CourseDetails[]> = new ReplaySubject(1);

  constructor(private state: TeeTimeManager, private courseService: CourseService) { }

  ngOnInit(): void {
    this.getPagedResult();
  }

  public getPagedResult() {
    const pagedResult = this.courseService.query({
      skip: (this.pagination$.value.page - 1) * this.pagination$.value.itemsPerPage,
      take: this.pagination$.value.itemsPerPage,
      sortColumns: [{
        column: this.pagination$.value.sortBy,
        direction: this.pagination$.value.sortDescending ? SortDirections.Descending : SortDirections.Ascending,
      }],
      search: this.pagination$.value.search,
      mobileActive: true
    });

    pagedResult.filteredCount$.subscribe((total) => this.total$.next(total));
    pagedResult.records$.subscribe((records) => this.records$.next(records));
  }

  public onSelectCourse(course: CourseDetails): void {
    this.state.setCourse(course);
    this.state.step$.next(AddTeeTimeState.SelectTeeTime);
  }
}
