<div class="py-4">
    <gcl-lib-two-pane-horizontal-resizable [width]="'50%'" class="">
        <div slot="left" class="p-3 h-100 d-flex flex-column">
            <ng-container *ngTemplateOutlet="left"></ng-container>
        </div>
        <div  class="p-3" slot="right">
            <ng-container *ngTemplateOutlet="right"></ng-container>
        </div>
    </gcl-lib-two-pane-horizontal-resizable>
    
    <!-- Templates -->
    <ng-template #left>
        <ng-container *ngIf="golfpassManager.state$ | async as state">
            <gcl-user-golf-pass-form *ngIf="state == GolfPassStates.Payment"></gcl-user-golf-pass-form>
            <gcl-user-golf-pass-order-summary *ngIf="state == GolfPassStates.Summary"></gcl-user-golf-pass-order-summary>
        </ng-container>
    </ng-template>
    
    <ng-template #right>
        <ng-container *ngIf="golfpassId$ | async as golfpassId">
            <gcl-user-golf-pass-guidlines [golfpassId]="golfpassId"></gcl-user-golf-pass-guidlines>
        </ng-container>
    </ng-template>
</div>