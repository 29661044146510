<ng-container [formGroup]="uploadFileForm">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add File</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span class="modal-close" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <gcl-lib-dropzone #dropzoneList 
            [course]="course" 
            [showDragAndDrop]="true"
            (hide)="closeModal()"
            (success)="uploadDone()"
            (error)="uploadError($event)"></gcl-lib-dropzone>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="uploadAsync()">Upload</button>
    </div>
</ng-container>