import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingScreenService {
  private loadingStatus: Subject<boolean> = new Subject<boolean>();
  public loading$ = this.loadingStatus.asObservable();

  public startLoading(): void {
    this.loadingStatus.next(true);
  }

  public stopLoading(): void {
    this.loadingStatus.next(false);
  }
}
