<div class="container-fluid">
    <div class="row vh-100 justify-content-center align-items-center">
        <div class="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-3">
            <h1 class="text-center my-4"><img src="/assets/logo.svg" class="img-fluid"></h1>
            <div class="border rounded bg-light p-3 mb-3">
                <gcl-lib-reset-password (onSubmit)="goHome()"></gcl-lib-reset-password>
            </div>
            <div class="d-flex justify-content-center">
                <a [routerLink]="['/login']">Ready to sign in?</a>
            </div>
        </div>
    </div>
</div>