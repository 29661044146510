import { Injectable, OnInit, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { StripeInstance } from 'ngx-stripe';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';

import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { NewOrderStorage, Order, OrderDetails } from 'projects/shared/src/public-api';
import { GolfPassDetails } from 'projects/shared/src/lib/models/golfpass';

export enum GolfPassStates {
	Payment = 1,
	Summary = 2
}

@Injectable()
export class GolfPassManager implements OnInit, OnDestroy {
	private destroy$ = new Subject<boolean>();

	public golfpass$: ReplaySubject<GolfPassDetails> = new ReplaySubject<GolfPassDetails>(1);
	public orderId$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
	public state$: BehaviorSubject<GolfPassStates> = new BehaviorSubject<GolfPassStates>(GolfPassStates.Payment);

	constructor(
		private orderService: OrderService
	) { }

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	public submitOrder(order: Partial<NewOrderStorage>): Observable<OrderDetails> {
		return this.orderService.submit({
			status: order.status,
			subtotal: order.total,
			tax: order.tax,
			total: order.total,
			tip: order.tip,
			finaltotal: order.finaltotal,
			users_permissions_user: order.users_permissions_user as number,
			course: order.course,
			golfpassorders: order.golfpassorders?.map(gp => ({
				quantity: gp.quantity,
				golfpass: gp.golfpass
			})),
		});
	}

	public cancelOrder(orderId: number): Observable<Order> {
		return this.orderService.cancel(orderId);
	}

	public stripe?: StripeInstance;
	private clientSecret?: string;
	private payment?: any;

	public clearPayment(): void {
		this.stripe = undefined;
		this.clientSecret = undefined;
		this.payment = undefined;
	}

	public setPayment(stripeInstance: StripeInstance, clientSecret: string, payment: any): void {
		this.stripe = stripeInstance;
		this.clientSecret = clientSecret;
		this.payment = payment;
	}

	public confirmCardPayment(): Observable<{
		paymentIntent?: PaymentIntent;
		error?: StripeError;
	}> {
		if (this.stripe && this.clientSecret && this.payment) {
			return this.stripe.confirmCardPayment(this.clientSecret, this.payment);
		}
		return of({});
	}
}
