import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { GolfProduct, GolfProductDetails } from '../models/golfproduct';
import { GolfProductQuery } from '../queries/golf-product-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class GolfProductService extends StrapiQueryableService<GolfProduct, GolfProductDetails, GolfProductQuery> {
  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'golfproducts' }, httpClient, config);
  }

  protected buildQuery(query?: GolfProductQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query != null) {
      if (query.search != null) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            name_contains: query.search
          }, {
            notes_contains: query.search
          }]
        });
      }

      if (query.id != null) {
        queryObj._where.push({ id: query.id });
      }
      if (query.course != null) {
        queryObj._where.push({ course: query.course });
      }
      if (query.cart != null) {
        queryObj._where.push({ cart: query.cart });
      }
      if (query.holes != null) {
        queryObj._where.push({ holes: query.holes });
      }
      if (query.reservationType != null) {
        queryObj._where.push({ reservation_types_in: query.reservationType });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: GolfProductQuery): PagedResult<GolfProductDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<GolfProductDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: GolfProductQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

}
