<div class="d-flex justify-content-between bar-chart">
    <ng-container *ngFor="let time of teetimes | keyvalue:keyNumOrder">
        <div class="bg-dark h-100" style="width: 1px;"></div>
        <div class="d-flex justify-content-center h-100">
            <small class="text-primary time">{{getTime(time.key)}}</small>
            <div class="bg-light-green h-100 mx-1">
                <div class="bar-chart-bar bg-light" [style.height]="time.value"></div>
            </div>
        </div>
    </ng-container>
    <div class="bg-dark h-100" style="width: 1px;"></div>
</div>