import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil, tap, map } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { environment } from '../../../../environments/environment';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';

import { Category, OrderDetails, Product, ProductDetails, ProductOrder, ProductOrderDetails } from "projects/shared/src/public-api";
import { PagedResult } from 'projects/shared/src/lib/queries/paged-result';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PromoCode } from 'projects/shared/src/lib/models/promo-code';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { ProductService } from 'projects/shared/src/lib/services/product.service';


@Component({
  selector: 'gcl-user-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss']
})
export class AddToCartComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public getDateTime = getDateTime;
  public faPlusCircle = faPlusCircle;

  public productOrders$!: Observable<Array<ProductOrderDetails>>;
  public products$!: Observable<Array<ProductDetails>>;

  public form: FormGroup = this.fb.group({
    promoCode: [null]
  });
  get promoCode() { return this.form.get("promoCode") as FormControl }

  private destory$: Subject<boolean> = new Subject<boolean>();

  constructor(public state: TeeTimeManager, private productservice: ProductService, private categoryService: CategoryService, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.productOrders$ = this.state.getOrderProducts$()
      .pipe(
        takeUntil(this.destory$),
        shareReplay(1)
      );

      this.products$ = this.productOrders$.pipe(
        map(pos => pos.map(po => po.product as ProductDetails)),
        takeUntil(this.destory$),
        shareReplay(1)
      );
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }

  public getProductCategory$(product: Product): Observable<Category | undefined> {
    return this.products$.pipe(
      map(products => {
        const pp = products.filter(p => p.id == product.id)[0];
        return pp.category;
      }),
      shareReplay(1)
    );
  }

  public applyPromoCode(): void {
    const promoCode = this.promoCode.value;
    this.state.applyPromoCode(promoCode)
      .pipe(
      )
      .subscribe(
        (() => {
          this.form.patchValue({
            promoCode: null
          });
          this.toastr.success("Promo Code Applied!");
          this.state.getOrder();
        }),
        ((response) => {
          this.toastr.error(response.error.message);
          this.state.getOrder();
        })
      );
  }

  public removePromoCode(): void {
    this.state.removePromoCode()
      .pipe(
      )
      .subscribe(
        (() => {
          this.toastr.success("Promo Code Removed!");
          this.state.getOrder();
        }),
        ((response) => {
          this.toastr.error(response.error.message);
          this.state.getOrder();
        })
      );
  }

  public addProduct(): void {
    this.state.step$.next(AddTeeTimeState.AddProduct);
  }

  public removeProduct(product: Product | number): void {
    this.state.removeProductFromOrder((product as Product).id)
      .pipe(
        takeUntil(this.destory$)
      ).subscribe(products => {
        this.state.refreshOrder();
        this.toastr.success("Product removed from order.");
      });
  }

  public cancelOrder(): void {
    this.state.cancelOrder()
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(() => this.state.step$.next(AddTeeTimeState.AddTeeTime));
  }

  public checkout(): void {
    this.state.step$.next(AddTeeTimeState.Checkout);
  }

}
