import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PaymentMethod } from '@stripe/stripe-js';

import { StripeService } from '../../services/stripe.service';
import { CreditBalanceTransactionService } from '../../services/credit-balance-transaction.service';

@Component({
  selector: 'gcl-lib-stripe-payment-methods',
  templateUrl: './stripe-payment-methods.component.html',
  styleUrls: ['./stripe-payment-methods.component.css']
})
export class StripePaymentMethodsComponent implements OnInit, OnDestroy {
  @Input()
  userId?: number;

  @Input()
  selectedPaymentMethodId?: string;

  @Input()
  showNewCardEntry: boolean = true;

  @Input()
  showUserCreditBalance: boolean = false;

  @Output()
  select: EventEmitter<PaymentMethod> = new EventEmitter();

  @Output()
  newPayment: EventEmitter<any> = new EventEmitter();

  public paymentMethods$!: Observable<Array<PaymentMethod>>;
  public userCreditBalance$!: Observable<number>;
  private destroy$: Subject<any> = new Subject();

  constructor(private stripeService: StripeService, private creditBalanceTransactionService: CreditBalanceTransactionService) { }

  ngOnInit(): void {
    this.getPaymentMethods(this.userId);

    this.stripeService.reloadPaymentList$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((userId) => this.getPaymentMethods(userId));

    if(this.userId) {
      this.userCreditBalance$ = this.creditBalanceTransactionService.getBalance(this.userId).pipe(takeUntil(this.destroy$))
    }
    else {
      this.userCreditBalance$ = of(0);
    }

  }

  private getPaymentMethods(userId?: number): void {
    if (userId) {
      this.paymentMethods$ = this.stripeService.queryPaymentMethods(userId)
        .pipe(
          takeUntil(this.destroy$)
        );
      this.userCreditBalance$ = this.creditBalanceTransactionService.getBalance(userId).pipe(takeUntil(this.destroy$));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSelect(method: PaymentMethod): void {
    this.selectedPaymentMethodId = method.id;
    this.select.emit(method);
  }

  public onSelectNewPayment(): void {
    this.newPayment.emit();
  }

  public getPaymentLogo(brand: string | undefined): string {
    switch (brand) {
      case "visa":
        return "logo_visa.svg";
      case "discover":
        return "logo_discover.png";
      case "amex":
        return "logo_amex.svg";
      case "mastercard":
        return "logo_master_card.svg";
      default:
        return "";
    }
  }
}
