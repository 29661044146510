<gcl-lib-two-pane-horizontal-resizable [width]="'50%'">
    <div slot="left" class="h-100 d-flex flex-column">
        <ng-container *ngTemplateOutlet="left"></ng-container>
    </div>
    <div slot="right">
        <ng-container *ngTemplateOutlet="right"></ng-container>
	</div>
</gcl-lib-two-pane-horizontal-resizable>

<!-- Templates -->
<ng-template #left>

    <div class="py-2" *ngIf="state.order$ | async as order">
        <h3 class="card-title text-center text-primary">Players</h3>
        <ul class="list-group rounded-0" *ngIf="state.golfproduct$ | async as golfproduct">
            <li class="list-group-item p-0 border-0">
                <div class="d-flex align-content-center">
                    <div class="flex-fill d-flex justify-content-between">
                        <div class="flex-grow-1 align-self-center p-2">
                            <span>{{golfproduct.name}}</span>
                        </div>
                        <div class="px-2 align-self-center">
                            <span>({{order.golforders.length}})</span>
                            <span class="ml-4" *ngIf="state.getOrderCourseTotal() | async as courseTotal">{{courseTotal | currency}}</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="py-2" *ngIf="state.order$ | async as order">
        <h3 class="card-title text-center text-primary">Item List</h3>
        <ul class="list-group rounded-0">
            <ng-container *ngIf="(productOrders$ | async) && (products$ | async)">
                <li class="list-group-item p-0 mt-1 border-0" *ngFor="let item of productOrders$ | async">
                    <div class="d-flex align-content-center">
                        <div class="flex-fill d-flex justify-content-between">
                            <div class="flex-grow-1 align-self-center p-2">{{state.getProductName(item.product)}}</div>
                            <div class="px-2 align-self-center">
                                <span>( {{item?.quantity}} )</span>
                                <span class="ml-4">{{item?.extprice | currency}}</span>
                            </div>
                            <ng-container *ngIf="getProductCategory$(item.product) | async as category">
                                <div *ngIf="category">
                                    <img *ngIf="category.mobileActive" alt="remove" class="img-fluid float-right p-0" (click)="removeProduct(item.product)" src="assets/trash.svg"/>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </li>
            </ng-container>
            <li class="list-group-item p-0 mt-1 border-0" *ngIf="order.promo_code">
                <div class="d-flex align-content-center">
                    <div class="flex-fill d-flex justify-content-between">
                        <div class="flex-grow-1 align-self-center p-2">Promo Code ({{state.getPromoCode(order.promo_code)}})</div>
                        <div class="px-2 align-self-center">
                            <span class="float-right" *ngIf="state.getPromoCodeDiscount() | async as promoAmount">({{promoAmount | currency}})</span>
                        </div>
                        <div>
                            <img alt="remove promo code" class="img-fluid float-right p-0" (click)="removePromoCode()" src="assets/trash.svg"/>
                        </div>
                    </div>
                </div>
            </li>
            <ng-container   *ngIf="(productOrders$ | async) && (products$ | async)">
                <li class="list-group-item border-0 p-0" *ngIf="(productOrders$ | async)?.length == 0">No items in cart.</li>
            </ng-container>
        </ul>
        <button class="btn btn-primary btn-block mt-2 rounded-0" type="button" (click)="addProduct()">
            <span class="float-left">Add An Item</span> 
            <fa-icon class="float-right" [icon]="faPlusCircle"></fa-icon>
        </button>
    </div>

</ng-template>

<ng-template #right>
    <h3 class="card-title text-center text-primary">Summary</h3>
    <h5 *ngIf="state.round$ | async as round">
        {{getDateTime(round?.datestr, round.starttod) | date:"fullDate"}} {{getDateTime(round.datestr, round.starttod) | date:"shortTime"}}
    </h5>
    <gcl-user-course-details [course]="state.course$?.value"></gcl-user-course-details>
    <div class="row">
        <div class="col-12">
            <h6>Particulars:</h6>
            <ng-container *ngIf="state.addTeeTime">
                <p>People in Party: {{state.addTeeTime.numOfGolfers}}</p>
                <p>Course: {{state.addTeeTime.numOfHoles}} Holes <span *ngIf="state.getGolfProduct()">| {{state.getGolfProduct()?.price | currency}}</span></p>
                <p>Include Cart: {{state.addTeeTime.addCart ? 'Yes' : 'No'}}</p>
            </ng-container>
        </div>
    </div>

    <div class="" *ngIf="this.state.order$ | async as order">
        <div class="row py-2">
            <div class="col-12">
                <div class="card border-0">
                    <div class="card-body bg-light text-black">
                        <div class="row py-2">
                            <div class="col-12 col-md-8 col-lg-6 mx-auto">
                                <h6>Course Total: <span class="float-right" *ngIf="state.getOrderCourseTotal() | async as courseTotal">{{courseTotal | currency}}</span></h6>
                                <h6>Product Total: <span class="float-right" *ngIf="state.getOrderProductTotal() | async as productTotal">{{productTotal | currency}}</span></h6>
                                <ng-container class="" *ngIf="order.promo_code">
                                    <h6 class="text-danger">Promo Code ({{state.getPromoCode(order.promo_code)}}): 
                                        <span class="float-right" *ngIf="state.getPromoCodeDiscount() | async as promoAmount">({{promoAmount | currency}})</span>
                                    </h6>
                                </ng-container>
                                <h6>Tax: <span class="float-right">{{order.tax | currency}}</span></h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer bg-primary text-white p-0">
                        <div class="row">
                            <div class="col-12">
                                <form [formGroup]="form" *ngIf="!order.promo_code">
                                    <div class="input-group promo-code-input-group">
                                        <input type="text" class="form-control bg-primary promo-code-input text-white rounded-0" formControlName="promoCode" placeholder="Enter Promo Code" aria-label="Promo Code" aria-describedby="Promo Code">
                                        <div class="input-group-append pointer" (click)="applyPromoCode()">
                                            <div class="input-group-text bg-primary apply-btn text-white rounded-0">Apply</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row row py-3 px-5">
                            <div class="col-12 col-md-8 col-lg-6 mx-auto">
                                <h6>Total: <span class="float-right">{{order.finaltotal | currency}}</span></h6>
                                <button class="btn btn-block btn-light rounded-pill btn-lg mx-auto" type="button" (click)="checkout()">Confirm Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row py-2">
            <div class="col-12 col-md-8 col-lg-6 mx-auto">
                <button class="btn btn-block text-primary rounded-pill cancel-btn mr-2 mx-auto" type="button" (click)="cancelOrder()">Cancel Outing</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderBtns>
</ng-template>