
<div class="modal-header bg-primary text-white">
    <h4 class="modal-title pull-left">Add Gift Certificate</h4>
</div>
<div class="modal-body">
    <form [formGroup]="giftCardForm" (ngSubmit)="onSubmit()">
        <p class="text-center">Enter the code found on the back of your Gift Certificate or in your confirmation email.</p>
        <div class="form-group code-input-group">
            <input type="text" class="bg-primary code-input text-white rounded-0 w-100" id="cardnumber" formControlName="cardnumber" aria-describedby="gift code" placeholder="Enter Code"
                [class.is-invalid]="cardnumber?.invalid && cardnumber?.touched">
            <div *ngIf="cardnumber?.invalid && cardnumber?.touched" class="invalid-feedback">
                <div *ngIf="cardnumber?.errors?.required">
                    Please enter a code.
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-outline-primary" (click)="onCancel()">Cancel</button>
        <button type="submit" class="btn btn-primary float-right">Submit</button>
    </form>
</div>