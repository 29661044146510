import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of, ReplaySubject, merge } from "rxjs";
import { share, shareReplay, switchMap, takeUntil, tap } from "rxjs/operators";

import { RoundDetails } from "projects/shared/src/lib/models/round";
import { GolfProduct, GolfProductDetails } from "projects/shared/src/lib/models/golfproduct";
import { Order, OrderDetails } from "projects/shared/src/lib/models/order";
import { RoundService } from "projects/shared/src/lib/services/round.service";
import { OrderService } from "projects/shared/src/lib/services/order.service";
import { Course, CourseDetails } from "projects/shared/src/lib/models/course";
import { Category, Product, ProductDetails, ProductOrderDetails, UserDetails } from "projects/shared/src/public-api";
import { ProductOrderService } from "projects/shared/src/lib/services/product-order.service";
import { PagedResult } from "projects/shared/src/lib/queries/paged-result";
import { CourseService } from "projects/shared/src/lib/services/course.service";
import { GolfProductService } from "projects/shared/src/lib/services/golf-product.service";
import { PromoCode } from "projects/shared/src/lib/models/promo-code";

export enum AddTeeTimeState {
    AddTeeTime = 1,
    SelectByCourse = 2,
    SelectByTime = 3,
    SelectTeeTime = 4,
    AddToCart = 5,
    AddProduct = 6,
    Checkout = 7,
    OrderConfirmation = 8,
}

export interface AddTeeTimeGolfer {
    email?: string;
}

export interface AddTeeTime {
    numOfGolfers: number,
    numOfHoles: number,
    addCart: boolean,
    golfers: AddTeeTimeGolfer[]
}

@Injectable()
export class TeeTimeManager implements OnInit, OnDestroy {

    private destroy$ = new Subject<boolean>();
    private _refreshOrder$ = new Subject<void>();

    public minDate!: Date;
    public maxDate!: Date;

    // Add a Tee Time Step
    public addTeeTime: AddTeeTime = {
        numOfGolfers: 1,
        numOfHoles: 18,
        addCart: true,
        golfers: []
    };

    public order$!: Observable<OrderDetails>;
    public course$: BehaviorSubject<CourseDetails | undefined> = new BehaviorSubject<CourseDetails | undefined>(undefined);
    public round$: BehaviorSubject<RoundDetails | undefined> = new BehaviorSubject<RoundDetails | undefined>(undefined);
    public golfproduct$: BehaviorSubject<GolfProductDetails | undefined> = new BehaviorSubject<GolfProductDetails | undefined>(undefined);

    public applyCreditBalance: boolean = false;
    public step$: BehaviorSubject<AddTeeTimeState> = new BehaviorSubject<AddTeeTimeState>(AddTeeTimeState.AddTeeTime);

    constructor(
        private roundService: RoundService,
        private orderService: OrderService,
        private productOrderService: ProductOrderService,
        private courseService: CourseService,
        private golfproductService: GolfProductService
    ) {
        this.minDate = new Date();
        this.minDate.setHours(0, 0, 0, 0);

        this.maxDate = new Date();
        this.maxDate.setHours(0, 0, 0, 0);

        // Can only book rounds up to 10 days ahead of the the current date.
        this.maxDate.setDate(this.maxDate.getDate() + 10);
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.step$.value != AddTeeTimeState.OrderConfirmation) {
            this.cancelOrder();
        }
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public getOrder(): Observable<OrderDetails> {
        let getOrder$ = this.orderService.getCurrentOrder()
            .pipe(
                tap(order => {
                    this.addTeeTime.numOfGolfers = order.golforders.length || 1;
                    this.addTeeTime.addCart = order.cart;
                    this.addTeeTime.numOfHoles = order.holes;

                    if (order.course) {
                        this.courseService.get(order.course.id)
                            .pipe(
                                shareReplay(1),
                                takeUntil(this.destroy$)
                            )
                            .subscribe(course => this.course$.next(course));
                    }
                    if (order.round) {
                        this.roundService.get(order.round.id)
                            .pipe(
                                shareReplay(1),
                                takeUntil(this.destroy$)
                            )
                            .subscribe(round => {
                                this.round$.next(round);
                                this.addTeeTime.numOfHoles = round.holes;
                            });
                        this.golfproductService.get(order.round.golfproduct as number)
                            .pipe(
                                shareReplay(1),
                                takeUntil(this.destroy$)
                            )
                            .subscribe(golfproduct => this.golfproduct$.next(golfproduct));
                    }
                    this.applyCreditBalance = order.useCreditBalance;
                }),
                shareReplay(1),
                takeUntil(this.destroy$)
            );

        let refreshOrder$ = this._refreshOrder$.pipe(switchMap(() => getOrder$));

        this.order$ = merge(getOrder$, refreshOrder$);

        return this.order$;
    }

    public cancelOrder(): Observable<void> {
        return this.orderService.cancelCurrentOrder()
            .pipe(
                switchMap((order) => of(this.clearRoundSelection()))
            );
    }

    public clearRoundSelection(): void {
        this.addTeeTime = {
            numOfGolfers: 1,
            numOfHoles: 9,
            addCart: true,
            golfers: []
        };
    }

    public setTeeTimeBaseInfo(numOfGolfers: number, isEightteenHoles: boolean, addCart: boolean, golfers: AddTeeTimeGolfer[]): void {
        this.addTeeTime = {
            numOfGolfers: numOfGolfers,
            numOfHoles: isEightteenHoles ? 18 : 9,
            addCart: addCart,
            golfers
        }
    }

    public setCart(cart: boolean): Observable<OrderDetails> {
        this.order$ = this.orderService.setCartOnCurrentOrder(cart);
        return this.order$;
    }

    public setHoles(holes: number): Observable<OrderDetails> {
        this.order$ = this.orderService.setHolesOnCurrentOrder(holes);
        return this.order$;
    }

    public addPlayer(email?: string): Observable<OrderDetails> {
        this.order$ = this.orderService.addPlayerToCurrentOrder(email);
        return this.order$;
    }

    public updatePlayer(golfOrderId: number, email: string | null): Observable<OrderDetails> {
        this.order$ = this.orderService.updatePlayerOnCurrentOrder(golfOrderId, email);
        return this.order$;
    }

    public removePlayer(golfOrderId: number): Observable<OrderDetails> {
        this.order$ = this.orderService.removePlayerFromCurrentOrder(golfOrderId);
        return this.order$;
    }

    public setCourse(course: CourseDetails): void {
        this.course$.next(course);
    }

    public bookTheRound(roundId: number): Observable<RoundDetails> {
        return this.roundService.bookRound(roundId)
            .pipe(
                switchMap(round => {
                    this.setRound(round);
                    this.golfproductService.get(round.golfproduct.id as number)
                        .subscribe(golfproduct => this.golfproduct$.next(golfproduct));
                    return of(round);
                })
            );
    }

    public setRound(round: RoundDetails): void {
        this.round$.next(round);
    }

    public setGolfProduct(golfproduct: GolfProductDetails): void {
        this.golfproduct$.next(golfproduct);
    }

    public getGolfProduct(): GolfProductDetails | undefined {
        return this.golfproduct$?.value;
    }

    public getCurrentUser$(): Observable<UserDetails> {
        return this.order$
            .pipe(
                switchMap(order => {
                    const currentUser = order.users_permissions_user as UserDetails;
                    return of(currentUser);
                }),
                shareReplay(1),
                takeUntil(this.destroy$)
            );
    }

    public getOrderProducts$(): Observable<Array<ProductOrderDetails>> {
        return this.orderService.getProductOrdersOnCurrentOrder();
    }

    public applyPromoCode(promoCode: string): Observable<OrderDetails> {
        return this.orderService.applyPromoCodeToCurrentOrder(promoCode);
    }

    public removePromoCode(): Observable<OrderDetails> {
        return this.orderService.removePromoCodeFromCurrentOrder();
    }

    public getProductName(product: Product | number): string {
        return (product as Product).name;
    }

    public setProductQtyOnOrder(productId: number, quantity: number): Observable<OrderDetails> {
        this.order$ = this.orderService.setProductQuantityOnCurrentOrder(productId, quantity);
        return this.order$;
    }

    public removeProductFromOrder(productId: number): Observable<OrderDetails> {
        this.order$ = this.orderService.removeProductFromCurrentOrder(productId);
        return this.order$;
    }

    public getCategory(category: number | Category): Category {
        return (category as Category);
    }

    public getOrderCourseTotal(): Observable<number> {
        return this.order$
            .pipe(
                switchMap((order: OrderDetails) => {
                    const total = order.golforders.reduce((previousTotal, golforder) => previousTotal + (golforder.extprice), 0);
                    return of(total);
                })
            );
    }

    public getOrderProductTotal(): Observable<number> {
        return this.order$
            .pipe(
                switchMap((order: OrderDetails) => {
                    const total = order.productorders.reduce((previousTotal, productorder) => previousTotal + (productorder.extprice), 0);
                    return of(total);
                })
            );
    }

    public getPromoCode(promo: number | PromoCode): string {
        return (promo as PromoCode).code;
    }

    public getPromoCodeDiscount(): Observable<number> {
        return this.order$
            .pipe(
                switchMap(order => {
                    const promo = order.promo_code as PromoCode;

                    let discount = 0;
                    if (promo.discountAmount) {
                        discount += promo.discountAmount;
                    }
                    if (promo.discountPercent) {
                        discount += Math.abs(order.subtotal * (promo.discountPercent / 100));
                    }
                    return of(discount);
                }),
                takeUntil(this.destroy$)
            );
    }

    public applyCredit(): Observable<OrderDetails> {
        this.applyCreditBalance = true;
        return this.orderService.setUseCreditBalanceOnCurrentOrder(true);
    }

    public removeCredit(): Observable<OrderDetails> {
        this.applyCreditBalance = false;
        return this.orderService.setUseCreditBalanceOnCurrentOrder(false);
    }

    public submitOrder(orderId: number): Observable<void> {
        return this.orderService.completeOrder(orderId);
    }

    public refreshOrder() {
        this._refreshOrder$.next();
    }
}