export enum OrderStatuses {
    Open = "Open",
    Submitted = "Submitted",
    Unsubmitted = "Unsubmitted",
    Processed = "Processed",
    Fulfilled = "Fulfilled",
    Closed = "Closed",
    Cancelled = "Cancelled",
    Paid = "Paid",
    Unpaid = "Unpaid"
}

export function GetOrderStatusesDisplayName(status: OrderStatuses): string {
    switch (status) {
        case OrderStatuses.Open:
            return "Open";
        case OrderStatuses.Submitted:
            return "Reserved";
        case OrderStatuses.Unsubmitted:
            return "Unsubmitted"
        case OrderStatuses.Processed:
            return "Processed";
        case OrderStatuses.Fulfilled:
            return "Fulfilled";
        case OrderStatuses.Closed:
            return "Closed";
        case OrderStatuses.Cancelled:
            return "Cancelled";
        case OrderStatuses.Paid:
            return "Paid";
        case OrderStatuses.Unpaid:
            return "Unpaid";
        default:
            return status;
    }
}