import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gcl-lib-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {

  title!: string;
  message!: string;
  isHTML: boolean = false;
  cancelBtnClass: string = "btn-default";
  acceptBtnClass: string = "btn-primary";

  public accept$: EventEmitter<boolean> = new EventEmitter();

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  accept() {
    this.bsModalRef.hide();
    this.accept$.emit(true);
  }

  cancel() {
    this.bsModalRef.hide();
    this.accept$.emit(false);
  }

}
