import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { ProductDetails } from 'projects/shared/src/public-api';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { CategoryService } from 'projects/shared/src/lib/services/category.service';
import { ProductService } from 'projects/shared/src/lib/services/product.service';
import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';
import { environment } from '../../../../environments/environment';

interface ProductCategory {
  categoryId: number;
  categoryName: string;
  productTotal$: Observable<number>;
  products$: Observable<Array<ProductDetails>>;
  displayedProducts$: Observable<Array<ProductDetails>>;  // What's displayed on the page.
  page: number;
}

@Component({
  selector: 'gcl-user-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public categories$: ReplaySubject<ProductCategory[]> = new ReplaySubject(1);
  public itemsPerPage: number = 10;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public state: TeeTimeManager, private productService: ProductService, private categoryService: CategoryService, private authService: AuthService, private toastr: ToastrService) { }

  ngOnInit(): void {
    const categories$ = this.categoryService.query({
      course: this.state.course$?.value?.id,
      mobileActive: true
    });
    categories$.records$.pipe(
      map((categories) => {
        return categories.map(cate => {
          const products$ = this.productService.query({
            course: this.state.course$?.value?.id,
            category: cate.id,
            available: true,
            sortColumns: [{
              column: "name",
              direction: SortDirections.Ascending,
            }],
          });

          return {
            categoryId: cate.id,
            categoryName: cate.name,
            productTotal$: products$.filteredCount$,
            products$: products$.records$,
            displayedProducts$: products$.records$.pipe(
              map(products => products.slice(0, this.itemsPerPage)),
              shareReplay(1),
              takeUntil(this.destroy$)
            ),
            page: 1
          } as ProductCategory;
        });
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    ).subscribe((categories) => this.categories$.next(categories));

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public getPagedResult(page: number, category: ProductCategory) {
    category.page = page;
    category.displayedProducts$ = category.products$.pipe(
      map(products => {
        const start = (page - 1) * this.itemsPerPage;
        const end = (start + this.itemsPerPage) > products.length ? products.length : (start + this.itemsPerPage);
        return products.slice(start, end);
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
  }

  public addProductToCart(product: ProductDetails, quantity: number): void {
    if (quantity > 0) {
      this.state.setProductQtyOnOrder(product.id, quantity)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(order => {
          this.toastr.success("Product added to order.");
          this.state.step$.next(AddTeeTimeState.AddToCart);
        });
    } else {
      this.toastr.error("Quantity needs be greater than 0.");
    }
  }

  public goBack() {
    this.state.step$.next(AddTeeTimeState.AddToCart);
  }
}