<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="p-5">
        <h3 class="text-primary text-center">Delete Kartygo Account</h3>
        <div class="form-group">
            <label for="accountDeletionReason">Account Deletion Reason(s)</label>
            <textarea rows="3" class="form-control" id="accountDeletionReason" formControlName="accountDeletionReason"></textarea>
        </div>
        <div class="d-flex w-100 justify-content-center">
            <button class="btn btn-danger">Delete Account</button>
        </div>
    </div>
</form>
