export enum CartStatuses {
    outofservice = "outofservice",
    available = "available",
    reserved = "reserved",
    assigned = "assigned"
}

export function GetCartStatusesDisplayName(status: CartStatuses): string {
	switch (status) {
        case CartStatuses.outofservice:
            return "Out Of Service";
        case CartStatuses.available:
            return "Available";
        case CartStatuses.reserved:
            return "Reserved";
        case CartStatuses.assigned:
            return "Assigned";
		default:
			return "N/A";
	}
}