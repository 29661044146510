import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { TeeTimeManager } from '../../tee-time.manager';
import { OrderService } from 'projects/shared/src/lib/services/order.service';
import { OrderDetails } from 'projects/shared/src/lib/models/order';
import { GolfOrderService } from 'projects/shared/src/lib/services/golf-order.service';
import { GolfOrderDetails } from 'projects/shared/src/lib/models/golforder';
import { ProductOrderDetails } from 'projects/shared/src/lib/models/productorder';
import { ProductOrderService } from 'projects/shared/src/lib/services/product-order.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@Component({
  selector: 'gcl-user-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {
  public apiUrl = environment.apiUrl;
  public getDateTime = getDateTime;

  public order$!: Observable<OrderDetails>;
  public golfOrders$!: Observable<Array<GolfOrderDetails>>;
  public productOrders$!: Observable<Array<ProductOrderDetails>>;

  private destory$: Subject<boolean> = new Subject<boolean>();

  constructor(public state: TeeTimeManager, private orderService: OrderService, private golforderService: GolfOrderService, private productOrderService: ProductOrderService, public authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.state.clearRoundSelection();
    
    this.state.order$
      .pipe(
        takeUntil(this.destory$)
      )
      .subscribe(order => {
        // Have to call get endpoint instead of the current order endpoint as the order has been submitted at this point.
        this.order$ = this.orderService.get(order.id)
          .pipe(
            shareReplay(1),
            takeUntil(this.destory$)
          );
    
        this.golfOrders$ = this.order$.pipe(
          switchMap((order: OrderDetails) => {
            const pagedResult = this.golforderService.query({
              order: order.id
            });
            return pagedResult.records$;
          }),
          shareReplay(1),
          takeUntil(this.destory$)
        );
    
        this.productOrders$ = this.order$.pipe(
          switchMap((order: OrderDetails) => {
            const pagedResult = this.productOrderService.query({
              order: order.id
            });
            return pagedResult.records$;
          }),
          shareReplay(1),
          takeUntil(this.destory$)
        );
      });
  }

  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.unsubscribe();
  }

  public continue(): void {
    this.router.navigate(["/"]);
  }

}
