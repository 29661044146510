import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gcl-user-account-order-summary',
  templateUrl: './account-order-summary.component.html',
  styleUrls: ['./account-order-summary.component.scss']
})
export class AccountOrderSummaryComponent implements OnInit {

  public orderId$!: Observable<number>;

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId$ = this.route.params.pipe(
      map(params => params.orderId)
    );
  }

}
