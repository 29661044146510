import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, concat, Observable, ReplaySubject, Subject } from 'rxjs';
import { shareReplay, take, takeUntil, toArray } from 'rxjs/operators';

import { AddTeeTimeState, TeeTimeManager } from '../../tee-time.manager';
import { environment } from '../../../../environments/environment';
import { getDateTime } from 'projects/shared/src/lib/utili/get-datetime';
import { RoundService } from 'projects/shared/src/lib/services/round.service';
import { RoundDetails } from 'projects/shared/src/lib/models/round';
import { RoundStatuses } from 'projects/shared/src/lib/enumerations/round-status';
import { OnPageEvent } from 'projects/shared/src/lib/models/on-page-event';
import { SortDirections } from 'projects/shared/src/lib/enumerations/sort-directions';
import { Course } from 'projects/shared/src/lib/models/course';
import { CourseService } from 'projects/shared/src/lib/services/course.service';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';
import { LoginModalComponent } from '../../../shared/components/login-modal/login-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gcl-user-select-by-time',
  templateUrl: './select-by-time.component.html',
  styleUrls: ['./select-by-time.component.scss']
})
export class SelectByTimeComponent implements OnInit, OnDestroy {
  public getDateTime = getDateTime;
  public apiUrl: string = environment.apiUrl;

  public startDate: Date = new Date();
  public startTime!: string;
  total$: BehaviorSubject<number> = new BehaviorSubject(1);
  rounds$: ReplaySubject<RoundDetails[]> = new ReplaySubject(1);

  pagination$ = new BehaviorSubject<OnPageEvent>({
    itemsPerPage: 25,
    page: 1,
    sortBy: 'starttod',
    sortDescending: false
  });
  onPageReset$: Subject<any> = new Subject<any>();

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(public state: TeeTimeManager, private roundService: RoundService, private courseService: CourseService, private authService: AuthService, private bsModalService: BsModalService) { }

  ngOnInit(): void {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    this.startDate = start;
    this.startTime = "12:00 AM";
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public onSelectDate(date: Date): void {
    this.startDate = date;
    // this.onSelectTime(this.startTime);
    this.getPagedResult(1);
  }

  public onSelectTime(start: string): void {
    this.startTime = start;
    // this.onPageReset$.next();
    this.getPagedResult(1);
  }

  public getPagedResult(page: number) {
    const courseId = this.state.course$?.value?.id as number;
    const holes = this.state.addTeeTime.numOfHoles;
    const date = this.startDate.toISODate();
    const cart = this.state.addTeeTime.addCart;

    const pagedResult = this.roundService.getRoundsByDate(date, this.startTime, undefined, holes, courseId, cart);
    pagedResult.subscribe((total) => this.total$.next(total.length));
    pagedResult.subscribe((records) => this.rounds$.next(records));
  }

  public getRoundPrice(round: RoundDetails): number {
    const golfProduct = round.golfproduct;
    if (golfProduct) {
      return golfProduct.price;
    }
    return 0;
  }

  public async onSelectRound(round: RoundDetails, course: Course) {
    let user = null;

    try {
      user = await this.authService.user$.pipe(take(1)).toPromise();
    }
    catch(err) {

    }

    if(!user) {
      let loginModalRef = this.bsModalService.show(LoginModalComponent, {
        class: 'modal-lg modal-dialog-centered',
        ignoreBackdropClick: true,         
      });

      await this.authService.onExternalLogin$.pipe(take(1)).toPromise();

      let tasks: Observable<any>[] = [];
      this.state.addTeeTime.golfers.filter(pl => !!pl.email).forEach(player => {
        tasks.push(this.state.addPlayer(player.email));
      });
  
      await concat(...tasks).pipe(toArray()).toPromise();

      await this.state.setCart(this.state.addTeeTime.addCart).pipe(take(1)).toPromise();
      await this.state.setHoles(this.state.addTeeTime.numOfHoles).pipe(take(1)).toPromise();  
    }

    
    const course$ = this.courseService.get(course.id)
      .pipe(
        shareReplay(1),
        takeUntil(this.destroyed$)
      );

    const round$ = this.state.bookTheRound(round.id)
      .pipe(
        takeUntil(this.destroyed$)
      )

    combineLatest([round$, course$])
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(([round, course]) => {
        this.state.setCourse(course);
        this.state.step$.next(AddTeeTimeState.AddToCart);
      });
  }
}
