import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GolfOrderDetails, ProductOrderDetails } from 'projects/shared/src/public-api';
import { RefundOptions } from '../../enumerations/refund-options';
import { OrderDetails } from '../../models/order';

@Component({
  selector: 'gcl-lib-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.css']
})
export class RefundModalComponent implements OnInit {
  public accept$: EventEmitter<boolean> = new EventEmitter();
  public order!: OrderDetails;
  
  public golfOrdersToRefund!: GolfOrderDetails[];
  public productOrdersToRefund!: ProductOrderDetails[];

  get golfOrdersTotal() {
    if(!this.golfOrdersToRefund || !this.golfOrdersToRefund.length) {
      return 0;
    }

    return this.golfOrdersToRefund.reduce((prev, acc) => prev + (acc.extprice + (acc.tax ?? 0)), 0);
  }

  get productOrdersTotal() {
    if(!this.productOrdersToRefund || !this.productOrdersToRefund.length) {
      return 0;
    }

    return this.productOrdersToRefund.reduce((prev, acc) => prev + (acc.extprice + (acc.tax ?? 0)), 0);
  }

  get refundTotal() {
    return this.golfOrdersTotal + this.productOrdersTotal;
  }

  get itemsCount() {
    return (this.golfOrdersToRefund?.length ?? 0) + (this.productOrdersToRefund?.length ?? 0);
  }

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  cancel() {
    this.bsModalRef.hide();
    this.accept$.emit(false);
  }

  refund() {
    this.bsModalRef.hide();
    this.accept$.emit(true);
  }
}
