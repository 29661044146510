import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { OnPageEvent } from '../../models/on-page-event';

@Component({
  selector: 'gcl-lib-pagination-content',
  templateUrl: './pagination-content.component.html',
  styleUrls: ['./pagination-content.component.css']
})
export class PaginationContentComponent implements OnInit, OnDestroy {

  @Input()
  totalItems?: number | null;

  @Input()
  page: number = 1;

  @Input()
  sortBy?: string;

  @Input()
  sortDescending?: boolean = false;

  @Input()
  itemsPerPage: number = 10;

  @Input()
  itemsPerPageOptions: number[] = [10, 25, 50, 100];

  @Input()
  position: "left" | "center" | "right" = "left";

  @Input()
  onPageReset$ = new Subject<any>();

  @Output()
  onPage = new EventEmitter<number>();

  form = this.fb.group({
    page: [this.page]
  });

  private destroyed$ = new Subject();

  constructor(private fb: FormBuilder, private el: ElementRef) { }

  ngOnInit(): void {
    this.form.patchValue({
      page: this.page,
    });

    this.onPageReset$
      .pipe(
        delay(500),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.form.patchValue({ page: 1 });
      });

    this.form.valueChanges
      .pipe(
        delay(500),
        takeUntil(this.destroyed$)
      )
      .subscribe(value => {
        this.onPage.emit(value.page);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
