import { Component, Input, OnDestroy, EventEmitter, ElementRef, Output, ViewChild } from '@angular/core';
import { faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ProductDetails } from 'projects/shared/src/public-api';

import { Subject } from 'rxjs';

import { CourseDetails } from '../../models/course';
import { CourseService } from '../../services/course.service';
import { MediaService } from '../../services/media.service';
import { ProductService } from '../../services/product.service';
import { getExtensionIcon } from '../../utili/get-extension-icon';
import { getFileNameWithoutExtension } from '../../utili/get-file-name-without-extension';
import { getSize } from '../../utili/get-size';

@Component({
  selector: 'gcl-lib-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.css']
})
export class DropzoneComponent implements OnDestroy {
  public faFile = faFile;
  public deleteIcon = faTrash;

  public getExtensionIcon = getExtensionIcon;
  public getSize = getSize;
  public getFileNameWithoutExtension = getFileNameWithoutExtension;

  @Input() product?: ProductDetails;
  @Input() course?: CourseDetails;

  @Input() maxFileSizeMB: number = 512;  // 512 MB
  @Input() showDragAndDrop: boolean = true;
  @Input() autoUpload: boolean = false;

  @Output() show: EventEmitter<number> = new EventEmitter<number>();  // Used for highlighting dropzone.
  @Output() hide: EventEmitter<number> = new EventEmitter<number>();  // Used for highlighting dropzone.
  @Output() success: EventEmitter<any> = new EventEmitter<any>();
  @Output() error: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("fileDropRef", { static: false }) fileDropEl?: ElementRef;

  public files: Array<File> = [];
  public isDragOver: boolean = false;

  private destroyed$ = new Subject<boolean>();

  constructor(private mediaService: MediaService, private toastr: ToastrService, private courseService: CourseService, private productService: ProductService) { }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  showDropzone() {
    this.isDragOver = true;
    if (this.product) {
      this.show.emit(this.product.id);
    }
    if (this.course) {
      this.show.emit(this.course.id);
    }
  }

  hideDropzone() {
    this.isDragOver = false;
    if (this.product) {
      this.hide.emit(this.product.id);
    }
    if (this.course) {
      this.hide.emit(this.course.id);
    }
  }

  /**
   * on file drop handler
   */
  async onFileDropped(files: any) {
    this.prepareFilesListAsync(files as Array<File>);

    // Auto Upload.
    if (this.files.length > 0 && this.autoUpload) {
      await this.uploadFilesAsync();
    }
  }

  /**
   * handle file from browsing
   */
  async fileBrowseHandler(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      this.prepareFilesListAsync(Array.from(target.files));

      // Auto Upload.
      if (this.files.length > 0 && this.autoUpload) {
        await this.uploadFilesAsync();
      }
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  private prepareFilesListAsync(files: Array<File>) {
    for (const file of files) {
      const fileSizeMB = (file.size / Math.pow(1024, 2));
      if (fileSizeMB <= this.maxFileSizeMB) {
        this.files.push(file);
      } else {
        this.toastr.error(`${file.name} is too large to upload. Max file size is ${this.maxFileSizeMB} MB.`, "Error");
      }
    }

    // Clears out element reference.
    if (this.fileDropEl) {
      this.fileDropEl.nativeElement.value = "";
    }
  }

  /**
   * Upload banner files to courses
   */
  public async uploadFilesAsync(): Promise<void> {
    if (this.files.length > 0 && (this.course || this.product)) {
      if (this.course) {
        for (const file of this.files) {
          await this.mediaService.uploadBannerToCourse(this.course?.id as number, file);
        }
      }

      if (this.product) {
        for (const file of this.files) {
          await this.mediaService.uploadFileToProduct(this.product?.id as number, file);
        }
      }

      this.toastr.success("File(s) uploaded successfully.", "Success");
      this.success.emit();
      this.hideDropzone();
    } else {
      this.error.emit('There are no files to upload.');
    }
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  public deleteFile(index: number) {
    this.files.splice(index, 1);
    if (this.files.length == 0) {
      this.hideDropzone();
    }
  }

}
