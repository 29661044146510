<div class="container py-2">
    <div class="row">
        <div class="col-12 col-lg-8 col-xl-6 mx-auto">
            <h1 class="text-center my-4"><img src="/assets/logo.svg" class="img-fluid"></h1>
            <div class="card my-2">
                <div class="card-body">
                    <h3 class="card-title text-center text-primary">Register</h3>
                    <gcl-lib-registeration (onRegister)="goToLogin()"></gcl-lib-registeration>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <a [routerLink]="['/account']">Go Back?</a>
            </div>
        </div>
    </div>
</div>