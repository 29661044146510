import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'projects/shared/src/public-api';
import { environment } from '../environments/environment';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutComponent } from './layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RegisterationComponent } from './registeration/registeration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { AccountModule } from './account/account.module';
import { TeeTimeModule } from './tee-time/tee-time.module';
import { GolfPassModule } from './golf-pass/golf-pass.module';
import { GolfPassLoginComponent } from './golf-pass-login/golf-pass-login.component';
import { UserSharedModule } from './shared/user-shared.module';
import { DeleteAccountComponent } from './account/account-view/components/delete-account/delete-account.component';
import { AuthService } from 'projects/shared/src/lib/services/auth.service';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    GolfPassLoginComponent,
    DashboardComponent,
    NavbarComponent,
    RegisterationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    FontAwesomeModule,
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),

    AccountModule,
    TeeTimeModule,
    GolfPassModule,
    UserSharedModule,

    SharedModule.forRoot({
      apiUrl: environment.apiUrl,
      googleMapsApiKey: environment.googleMapsApiKey,
      stripe: {
        publicKey: environment.stripeKey,
        version: "2020-08-27"
      },
      jwtLocalStorageKey: 'gcl-user-jwt',
      login: {
        commands: ['/login']
      }
    })
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
