import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripeTerminal, Reader, Terminal } from '@stripe/terminal-js';
import { environment } from 'projects/admin/src/environments/environment';
import { Observable, from, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StripeTerminalService {
  constructor(private http: HttpClient) {
  }

  async getClient(courseId: number) {
    const stripeTerminal = await loadStripeTerminal();
    if(stripeTerminal) {
      const terminal = await stripeTerminal.create({
        onFetchConnectionToken: () => this.http.get(`${environment.apiUrl}/stripe/readers/${courseId}/connection-token`, { responseType: 'text' }).toPromise(),
        onUnexpectedReaderDisconnect: () => alert('Card reader disconnected')
      });
      
      return terminal;
    }

    return null;
  }

  deleteTerminal(terminalId: string) {
    return this.http.delete<Terminal>(`${environment.apiUrl}/stripe/readers/${terminalId}`).toPromise();
  }

  renameTerminal(readerId: string, label: string) {
    return this.http.put<Terminal>(`${environment.apiUrl}/stripe/readers/${readerId}`, {
      label
    }).toPromise();
  }

  createTerminal(courseId: number, registrationCode: string, label?: string) {
    return this.http.post<Terminal>(`${environment.apiUrl}/stripe/readers`, {
      courseId,
      registrationCode,
      label
    }).toPromise();
  }
  
}
