import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { PasswordValidators } from '../../validators/password-validators';

@Component({
  selector: 'gcl-lib-registeration-form',
  templateUrl: './registeration-form.component.html',
  styleUrls: ['./registeration-form.component.css']
})
export class RegisterationFormComponent implements OnInit {

  get username() { return this.registrationForm.get('username') as FormControl; }
  get email() { return this.registrationForm.get('email') as FormControl; }
  get password() { return this.registrationForm.get('password') as FormControl; }
  get confirmPassword() { return this.registrationForm.get('confirmPassword') as FormControl; }
  get cellphone() { return this.registrationForm.get('cellphone') as FormControl; }
  get firstName() { return this.registrationForm.get('firstName') as FormControl; }
  get lastName() { return this.registrationForm.get('lastName') as FormControl; }
  get dateOfBirth() { return this.registrationForm.get('dateOfBirth') as FormControl; }

  public registrationForm = this.fb.group({
    username: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]],
    cellphone: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    dateOfBirth: ['', [Validators.required]],
  }, { validators: [PasswordValidators.MatchPassword] });

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
  }

}
