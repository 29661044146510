<div class="modal-header">
    <h4 class="modal-title pull-left">Add Reader</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span class="modal-close" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <label for="label">Registration Code (required)</label>
            <input type="text" class="form-control" id="label" formControlName="registrationCode">
            <small class="form-text text-muted">Enter the key sequence <strong>0-7-1-3-9</strong> on the Verifone reader you want to register to display the unique registration code.</small>
        </div>
        <div class="form-group">
            <label for="label">Name (optional)</label>
            <input type="text" class="form-control" id="label" formControlName="label">
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary" (click)="save()">Save</button>
</div>