import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'gcl-lib-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.css']
})
export class InputModalComponent implements OnInit {

  title!: string;
  message!: string;

  public value: number = 0;

  public submit$: EventEmitter<number> = new EventEmitter();

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  submit() {
    this.bsModalRef.hide();
    this.submit$.emit(this.value);
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
