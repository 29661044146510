import { AbstractControl } from "@angular/forms";

export class PasswordValidators {
    static MatchPassword(AC: AbstractControl) {
        let password = AC.get('password');
        let confirmPassword = AC.get('confirmPassword');

        if (password != null && confirmPassword != null) {
            if (password.value === confirmPassword.value) { return null; }
        }
        return { 'invalidPasswordMatch': {
            Valid: false,
            Fields: ["Password", "Confirm Password"]
        }};
    }
}