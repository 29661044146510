import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { GolfPassManager, GolfPassStates } from '../golf-pass.manager';

@Component({
  selector: 'gcl-user-golf-pass-checkout',
  templateUrl: './golf-pass-checkout.component.html',
  styleUrls: ['./golf-pass-checkout.component.scss'],
  providers: [GolfPassManager]
})
export class GolfPassCheckoutComponent implements OnInit, OnDestroy {

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public GolfPassStates = GolfPassStates;
  public golfpassId$!: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    public golfpassManager: GolfPassManager
  ) { }

  ngOnInit(): void {
    this.golfpassManager.clearPayment();
    this.golfpassManager.state$.next(GolfPassStates.Payment);

    this.golfpassId$ = this.route.params.pipe(
      map(params => params.id),
    );
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
