import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { StrapiQueryableService } from './strapi-queryable.service';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';

import { GiftCertificate, GiftCertificateDetails } from '../models/gift-certificate';
import { PagedResult } from '../queries/paged-result';
import { GiftCertificateQuery } from '../queries/gift-certificate-query';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class GiftCertificateService extends StrapiQueryableService<GiftCertificate, GiftCertificateDetails, GiftCertificateQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig) {
    super({ path: 'gift-certificates' }, httpClient, config);
  }

  protected buildQuery(query?: GiftCertificateQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }, {
            code_contains: query.search
          }, {
            type_contains: query.search
          }, {
            status_contains: query.search
          }, {
            'purchased_user.email_contains': query.search
          }, {
            'redeemed_user.email_contains': query.search
          }]
        });
      }
      if (query.id) {
        queryObj._where.push({ id: query.id });
      }
      if (query.code) {
        queryObj._where.push({ code: query.code });
      }
      if (query.type) {
        queryObj._where.push({ type: query.type });
      }
      if (query.status) {
        queryObj._where.push({ status: query.status });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: GiftCertificateQuery): PagedResult<GiftCertificateDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<GiftCertificateDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: GiftCertificateQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public adminDebit(userId: number, code: string): Observable<GiftCertificateDetails> {
    let body = { code };

    return this.httpClient.put<GiftCertificateDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/admin-debit/${userId}`, body);
  }

  public generate(certificateCount: number) {
    return this.httpClient.post<GiftCertificateDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}/generate-physical`, {certificateCount});
  }

  public appDebit(code: string): Observable<GiftCertificateDetails> {
    let body = { code };
    return this.httpClient.post<GiftCertificateDetails>(`${this.config.apiUrl}/${this.pathConfig.path}/app-debit`, body);
  }

}