import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AddTeeTimeState, TeeTimeManager } from '../tee-time.manager';

@Component({
  selector: 'gcl-user-tee-time-breadcrumb',
  templateUrl: './tee-time-breadcrumb.component.html',
  styleUrls: ['./tee-time-breadcrumb.component.scss']
})
export class TeeTimeBreadcrumbComponent implements OnInit {
  public AddTeeTimeState = AddTeeTimeState;
  public step$!: Observable<AddTeeTimeState>;

  constructor(public state: TeeTimeManager) { }

  ngOnInit(): void {
    this.step$ = this.state.step$;
  }

  public goToStep(step: AddTeeTimeState) {
    this.state.step$.next(step);
  }
}
