import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountOldOrdersComponent } from './account-old-orders/account-old-orders.component';
import { AccountOrderSummaryComponent } from './account-order-summary/account-order-summary.component';
import { AccountViewComponent } from './account-view/account-view.component';

const routes: Routes = [
  {
    path: '',
    component: AccountViewComponent,
  },
  {
    path: 'old-orders',
    component: AccountOldOrdersComponent,
  },
  {
    path: ':orderId/summary',
    component: AccountOrderSummaryComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
