import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'projects/shared/src/lib/guards/auth.guard';
import { NonAuthGuard } from 'projects/shared/src/lib/guards/non-auth.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GolfPassLoginComponent } from './golf-pass-login/golf-pass-login.component';
import { LayoutComponent } from './layout.component';
import { LoginComponent } from './login/login.component';
import { RegisterationComponent } from './registeration/registeration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DeleteAccountComponent } from './account/account-view/components/delete-account/delete-account.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'tee-time',
        loadChildren: () => import('./tee-time/tee-time.module').then(m => m.TeeTimeModule)
      },
      {
        path: 'golf-passes',
        canActivate: [AuthGuard],
        loadChildren: () => import('./golf-passes/golf-passes.module').then(m => m.GolfPassesModule)
      },
      {
        path: 'golf-pass/:id/checkout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./golf-pass/golf-pass.module').then(m => m.GolfPassModule),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        component: DashboardComponent,
      },
    ]
  },
  {
    path: 'golf-pass/:id',
    component: GolfPassLoginComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'registeration',
    component: RegisterationComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false } // <-- debugging purposes only
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
