<div class="py-4">
    <div class="container py-4">
        <div class="row">
            <div class="col-12 col-md-8 col-lg-6 mx-auto">
                <div class="card my-2">
                    <div class="card-body">
                        <h3 class="card-title text-center text-primary">Change Password</h3>
                        <gcl-lib-change-password (onSubmit)="goToView()"></gcl-lib-change-password>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>