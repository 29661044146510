import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'projects/admin/src/environments/environment';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

import { Media, MediaDetails } from '../models/media';
import { MediaQuery } from '../queries/media-query';
import { PagedResult } from '../queries/paged-result';
import { CONFIG_TOKEN, SharedModuleConfig } from '../shared.config';
import { CourseService } from './course.service';
import { StrapiQueryableService } from './strapi-queryable.service';

declare var Qs: any;

@Injectable({
  providedIn: 'root'
})
export class MediaService extends StrapiQueryableService<Media, MediaDetails, MediaQuery> {

  constructor(protected httpClient: HttpClient, @Inject(CONFIG_TOKEN) protected config: SharedModuleConfig, private courseService: CourseService) {
    super({ path: 'upload/files' }, httpClient, config);
  }

  protected buildQuery(query?: MediaQuery): string {
    let queryObj = super.buildBaseQueryObj(query);

    // Specific Query filters.
    if (query) {
      if (query.search) {
        queryObj._where.push({
          _or: [{
            id_contains: query.search
          }]
        });
      }
    }

    return Qs.stringify(queryObj);
  }

  public query(query?: MediaQuery): PagedResult<MediaDetails> {
    const total$ = this.count();
    const filteredTotal$ = this.count(query);
    const queryStr = this.buildQuery(query);
    const records$ = this.httpClient.get<MediaDetails[]>(`${this.config.apiUrl}/${this.pathConfig.path}?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
    return new PagedResult(records$, filteredTotal$, total$);
  }

  public count(query?: MediaQuery): Observable<number> {
    const queryStr = this.buildQuery(query);
    return this.httpClient.get<number>(`${this.config.apiUrl}/${this.pathConfig.path}/count?${queryStr}`)
      .pipe(
        shareReplay(1)
      );
  }

  public uploadFileToProduct(productId: number, file: File): Promise<MediaDetails> {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('ref', 'product');
    formData.append('refId', productId.toString());
    formData.append('field', 'image');
    return this.httpClient.post<MediaDetails>(`${this.config.apiUrl}/upload`, formData).toPromise();
  }

  public async uploadLogoToCourse(courseId: number, file: File): Promise<MediaDetails> {
    const formData = new FormData();
    formData.append('ref', 'course');
    formData.append('files', file);
    formData.append('refId', courseId.toString());
    formData.append('field', 'logo');
    return await this.httpClient.post<MediaDetails>(`${this.config.apiUrl}/upload`, formData).toPromise();
  }

  public async uploadBannerToCourse(courseId: number, file: File): Promise<MediaDetails> {
    const formData = new FormData();
    formData.append('ref', 'course');
    formData.append('files', file);
    formData.append('refId', courseId.toString());
    formData.append('field', "banners");

    const course = await this.courseService.get(courseId).pipe(take(1)).toPromise();

    // Check for existing media with same filename, if so delete the old file and replace it with the new one
    const existingMedia = course.banners.find(banner => banner.name == file.name);
    if (existingMedia) {
      await this.delete(existingMedia.id).toPromise();
    }

    return await this.httpClient.post<Media>(`${environment.apiUrl}/upload`, formData).toPromise();
  }

  public removeMediaFile(mediaId: number) {
    return this.httpClient.delete<MediaDetails>(`${this.config.apiUrl}/upload/files/${mediaId}`);
  }

}
