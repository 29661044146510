import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'gcl-lib-date-picker-slider',
  templateUrl: './date-picker-slider.component.html',
  styleUrls: ['./date-picker-slider.component.css']
})
export class DatePickerSliderComponent implements OnInit, OnChanges {
  @Input()
  readonly: boolean = false;
  @Input()
  date!: Date;
  @Input()
  viewMode: BsDatepickerViewMode = "day";
  @Input()
  format: string = "MM/DD/YYYY";
  @Input()
  incrementBy: string = "day";

  @Output()
  dateSelect: EventEmitter<Date> = new EventEmitter();

  public currentDate!: Date;
  public range: number = 11;

  public dates: Array<Date> = [];

  constructor() { }

  ngOnInit(): void {
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
    this.initDates();

    // Sets the date to 12AM.
    this.date.setHours(0, 0, 0, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["date"].currentValue != undefined){
      this.initDates();

      // Sets the date to 12AM.
      this.date.setHours(0, 0, 0, 0);
    }
  }

  private initDates(): void {
    this.dates = Array.from({ length: this.date.getDaysInMonth() }, (_, i) => new Date(this.date.getUTCFullYear(), this.date.getUTCMonth(), i + 1, 0, 0, 0, 0));
  }

  onSelectDate(date: Date) {
    if (!this.readonly) {
      this.dateSelect.emit(date);
      this.date = date;
      this.initDates();
    }
  }

}
